import { ICommentRelateOptions } from './useCommentRelate.interface';

import { bonoboClient } from '../../apollo';
import { useCommentRelateMutation } from '../../bonobo/generated.graphql';

export const useCommentRelate = (options?: ICommentRelateOptions) => {
  const { onCompleted, onError } = options ?? ({} as ICommentRelateOptions);

  const [commentRelate, { loading: isCommentRelateLoading, error }] = useCommentRelateMutation({
    client: bonoboClient,
    onCompleted: () => {
      if (!onCompleted) {
        return;
      }
      void (async () => onCompleted())();
    },
    onError,
  });

  return { commentRelate, isCommentRelateLoading, error };
};
