import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { ANSWER_CUSTOM_FIELDS_MUTATION } from './useAnswerCustomFields.graphql';
import {
  IHandleAnswerCustomFieldsParams,
  IAnswerCustomFieldsMutationGraphQLResponse,
  IAnswerCustomFieldsMutationVariables,
  IUseAnswerCustomFields,
  IUseAnswerCustomFieldsOptions,
} from './useAnswerCustomFields.interfaces';

const useAnswerCustomFields = (options?: IUseAnswerCustomFieldsOptions): IUseAnswerCustomFields => {
  const { mutation, onCompleted, onError, product } = options ?? ({} as IUseAnswerCustomFieldsOptions);

  const [executeAnswerCustomFields, { loading: isLoading }] = useLoggedGeladaMutation<
    IAnswerCustomFieldsMutationGraphQLResponse,
    IAnswerCustomFieldsMutationVariables
  >({
    mutation: mutation ?? ANSWER_CUSTOM_FIELDS_MUTATION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          customFieldResponse: { answerCustomFields: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    product,
  });

  const handleAnswerCustomFields = async ({ projectId, responses, userId }: IHandleAnswerCustomFieldsParams) => {
    await executeAnswerCustomFields({
      variables: {
        projectId,
        responses,
        userId,
      },
    });
  };

  return {
    handleAnswerCustomFields,
    isLoading,
  };
};

export { useAnswerCustomFields };
