import { gql } from '@apollo/client';

export const GET_PAGINATED_GROUPS = gql`
  query SearchGroups($after: String, $first: Int, $projectId: String!, $filter: String, $status: [EGroupStatus]) {
    group {
      getPaginatedGroups(after: $after, first: $first, projectId: $projectId, filter: $filter, status: $status) {
        edges {
          node {
            id
            name
            userCount
            smartCodes {
              code
            }
          }
        }
        totalCount
      }
    }
  }
`;
