import { gql } from '@apollo/client';
import { USER_FRAGMENT } from '@netfront/gelada-identity-library';

const LOGIN_FRAGMENT = gql`
  fragment loginFragment on LoginGraphType {
    token
    loginToken
    refreshToken
    user {
      ...userFragment
    }
  }

  ${USER_FRAGMENT}
`;

const REGISTER_WITHOUT_ACTIVATION_MUTATION = gql`
  mutation registerWithoutActivation(
    $credential: CredentialInput!
    $customFields: [CustomFieldAnswerInputType]
    $user: RegisterUserInputType!
    $shouldIncludeOrganisationLogo: Boolean = false
    $shouldIncludeProjectLogo: Boolean = false
    $shouldIncludeProjectSettings: Boolean = false
    $shouldIncludeProjectTheme: Boolean = false
    $shouldIncludeUserAvatar: Boolean = false
    $shouldIncludeUserCredential: Boolean = true
    $shouldIncludeUserCustomFields: Boolean = false
    $shouldIncludeUserMembershipsGroup: Boolean = true
    $shouldIncludeUserMembershipsProject: Boolean = true
    $shouldIncludeUserMembershipsUnit: Boolean = false
    $shouldIncludeUserMembershipsUserType: Boolean = false
    $shouldIncludeProjectDomain: Boolean = false
  ) {
    registerWithoutActivation(credential: $credential, customFields: $customFields, user: $user) {
      ...loginFragment
    }
  }

  ${LOGIN_FRAGMENT}
`;

export { REGISTER_WITHOUT_ACTIVATION_MUTATION };
