import { Spinner } from '@netfront/ui-library';
import cx from 'classnames';
import { useIsMounted } from 'hooks';
import Head from 'next/head';

import { IMeta, PageProps } from './Page.interfaces';

import { PrivateFooter, PublicFooter } from '../../Footer';
import { PrivateHeader, PublicHeader } from '../../Header';

const Page = ({
  bodyClassName,
  children,
  className,
  footer,
  hasPrivateLayout = false,
  header,
  isPreloaderVisible = false,
  meta,
  shouldExcludeFooter,
  spotLightData,
  title,
}: PageProps) => {
  const { isMounted } = useIsMounted();

  const { seoDescription: seoDescriptionProp, seoTitle: seoTitleProp } = meta ?? ({} as IMeta);

  const seoTitle = `${seoTitleProp ? seoTitleProp : title}`;
  const seoDescription = seoDescriptionProp ? seoDescriptionProp : seoTitleProp ? seoTitleProp : title;

  return isMounted ? (
    <>
      <Head>
        <title>{seoTitle}</title>
        <meta content={seoDescription} name="description" />
        <meta content={seoTitle} property="og:title" />
        <meta content={seoDescription} property="og:description" />
        <meta content="/logo-icon.jpg" property="og:image" />
      </Head>
      <div className={cx('flex flex-col min-height-screen', bodyClassName)}>
        {header ? header : hasPrivateLayout ? <PrivateHeader spotLightData={spotLightData} /> : <PublicHeader />}
        <Spinner isLoading={isPreloaderVisible} />
        <main className={cx('flex-1', 'relative', className)}>{children}</main>
        {shouldExcludeFooter ? null : footer ? footer : hasPrivateLayout ? <PrivateFooter /> : <PublicFooter />}
      </div>
    </>
  ) : null;
};

export { Page };
