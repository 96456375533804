import { gql } from '@apollo/client';

const IDBUserGraphTypeFragment = gql`
  fragment user on UserGraphType {
    about
    activeCommunities {
      edges {
        node {
          id
          title
          userConnection {
            role
          }
        }
      }
      totalCount
    }
    connections {
      totalCount
    }
    coverImage {
      id: assetId
      presignedUrl
    }
    displayedName
    email
    firstName
    id
    isAnonymous
    isOnline
    isTermsAndConditionsAccepted
    key
    lastName
    profileImage {
      id: assetId
      presignedUrl
    }
    settings
    username
  }
`;

export { IDBUserGraphTypeFragment };
