import { gql } from '@apollo/client';

import { USER_FRAGMENT } from './user.fragment';

const LOGIN_FRAGMENT = gql`
  fragment loginFragment on LoginGraphType {
    accessToken: token
    loginToken
    refreshToken
    user {
      ...userFragment
    }
  }

  ${USER_FRAGMENT}
`;

export { LOGIN_FRAGMENT };
