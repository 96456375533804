
import { ApolloError } from '@apollo/client';
import { deleteTemporaryTokenCookie } from '@netfront/common-library';
import {
  IRegisterUserMutationCredentialInputVariables,
  IRegisterUserMutationUserInputVariables,
  IRegisterUserOnCompletedResponse,
  useDomain,
  useRegisterUser,
} from '@netfront/gelada-identity-library';
import { useRouter } from 'next/router';

import { DEFAULT_COMMUNITY } from 'pages/register/join-community';

import { BUTTON_CLASSES, PAGE_TITLE, RegistrationForm, SingleFormPage } from '../../../components';
import { useToast } from '../../../hooks';

const RegisterPage = () => {
  const { push, query: { communityKey } } = useRouter();
  const { handleToastError } = useToast();
  const { getDomain } = useDomain();


  const handleRegistrationCompleted = (data: IRegisterUserOnCompletedResponse) => {
    deleteTemporaryTokenCookie({ domain: getDomain() });
    const { accessToken } = data;
    const key = communityKey ?? DEFAULT_COMMUNITY?.code;

    push(`/register/join-community?accessToken=${String(accessToken)}&code=${String(key)}`);

  };

  const handleRegistrationError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleRegisterUser, isLoading = false } = useRegisterUser({
    onCompleted: handleRegistrationCompleted,
    onError: handleRegistrationError,
  });

  return (
    <SingleFormPage meta={{ seoDescription: 'Create an account today', seoTitle: 'Register' }} title={PAGE_TITLE}>
      <h1 className="color-primary h5 mb-2 text-uppercase">Register</h1>
      <h2 className="color-black mb-6 text-uppercase">
        CREATE AN ACCOUNT <br /> TODAY
      </h2>

      <RegistrationForm
        buttonClassName={BUTTON_CLASSES.default}
        forgotPasswordUrl="/forgot-password"
        isSubmitting={isLoading}
        loginUrl="/login"
        onRegister={(email, firstName, lastName, password, displayedName) => {
          const credential: IRegisterUserMutationCredentialInputVariables = {
            email,
            password,
          };

          const user: IRegisterUserMutationUserInputVariables = {
            displayedName,
            firstname: firstName,
            lastname: lastName,
          };

          handleRegisterUser({ credential, user });
        }}
      />
    </SingleFormPage>
  );
};

export { RegisterPage };
