import { IAdditionalClassNames } from '@netfront/ui-library';

const IconCommunity = ({ additionalClassNames }: IAdditionalClassNames) => {
  return (
    <svg className={additionalClassNames} height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <g id="icon-community-inactive" transform="translate(0.5 0.5)">
        <g data-name="Group 48770" id="Group_48770" transform="translate(-4.821 -4.821)">
          <path
            className="c-icon__outline"
            d="M6.053,12.689a5.452,5.452,0,0,1-3.2-3.055"
            data-name="Path 43496"
            fill="currentColor"
            id="Path_43496"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(4.432 4.751)"
          />
          <path
            className="c-icon__outline"
            d="M12.61,9.6a5.46,5.46,0,0,1-2.835,2.943"
            data-name="Path 43497"
            fill="none"
            id="Path_43497"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(4.757 4.749)"
          />
          <path
            className="c-icon__outline"
            d="M9.775,2.918A5.456,5.456,0,0,1,12.61,5.861"
            data-name="Path 43498"
            fill="none"
            id="Path_43498"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(4.757 4.435)"
          />
          <path
            className="c-icon__outline"
            d="M2.858,5.829a5.451,5.451,0,0,1,3.2-3.055"
            data-name="Path 43499"
            fill="none"
            id="Path_43499"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(4.432 4.429)"
          />
          <circle
            className="c-icon__outline"
            cx="2.064"
            cy="2.064"
            data-name="Ellipse 881"
            fill="none"
            id="Ellipse_881"
            r="2.064"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(15.694 10.258)"
          />
          <circle
            className="c-icon__outline"
            cx="2.064"
            cy="2.064"
            data-name="Ellipse 882"
            fill="none"
            id="Ellipse_882"
            r="2.064"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(4.821 10.258)"
          />
          <circle
            className="c-icon__outline"
            cx="2.064"
            cy="2.064"
            data-name="Ellipse 883"
            fill="none"
            id="Ellipse_883"
            r="2.064"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(10.46 15.694)"
          />
          <circle
            className="c-icon__outline"
            cx="2.064"
            cy="2.064"
            data-name="Ellipse 884"
            fill="none"
            id="Ellipse_884"
            r="2.064"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            transform="translate(10.46 4.821)"
          />
        </g>
      </g>
    </svg>
  );
};

export { IconCommunity };
