import toast from 'react-hot-toast';

import { getToastErrorMessages } from './useToast.helpers';
import { IHandleToastErrorParams, IHandleToastSuccessParams, IUseToast } from './useToast.interfaces';

const useToast = (): IUseToast => {
  const handleToastError = ({ error, shouldUseFriendlyErrorMessage = false }: IHandleToastErrorParams) => {
    getToastErrorMessages({
      error,
      shouldUseFriendlyErrorMessage,
    }).forEach((errorMessage) => toast.error(errorMessage));
  };

  const handleToastSuccess = ({ message }: IHandleToastSuccessParams) => {
    toast.success(message, {
      className: 'c-toast',
    });
  };

  const handleToastCustomError = ({ message }: IHandleToastSuccessParams) => {
    toast.error(message, {
      className: 'c-toast',
    });
  };

  return {
    handleToastError,
    handleToastSuccess,
    handleToastCustomError,
  };
};

export { useToast };
