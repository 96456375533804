export * from './Pages.constants';

export * from './ActivateAccountPage';
export * from './ContactPage';
export * from './ContentPage';
export * from './DocsContentPage';
export * from './ForgotPasswordPage';
export * from './ImportantNotePage';
export * from './LandingPage';
export * from './LoginPage';
export * from './LogoutPage';
export * from './Page';
export * from './RegisterPage';
export * from './ResetPasswordPage';
export * from './SingleFormPage';
