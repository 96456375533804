import { useEffect } from 'react';

import { CMSContentPage, useContentPageContext } from '@netfront/ekardo-content-library';
import { useRouter } from 'next/router';

import { AppBanner } from 'components/Social';

import { ContentPageProps } from './ContentPage.interfaces';

import { Page } from '../../../components';

const ContentPage = ({ contentPage }: ContentPageProps) => {
  const { dispatch } = useContentPageContext();

  const { seoDescription, seoTitle, title } = contentPage;

  const {
    query: { pageUrl },
  } = useRouter();

  useEffect(() => {
    if (!pageUrl) {
      return;
    }

    dispatch({
      payload: {
        contentPage,
      },
      type: 'loadContentPage',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUrl]);

  return (
    <Page meta={{ seoDescription, seoTitle }} title={title}>
      <section className="bg-gradient-blue pb-48 pt-24">
        <div className={`container container-sm`}>
          <span className="color-primary h5 weight-600 text-uppercase block">Breathing space community</span>
          <h1 className="text-uppercase mb-6">{title}</h1>

          <CMSContentPage />
        </div>
      </section>

      <AppBanner />
    </Page>
  );
};

export { ContentPage };
