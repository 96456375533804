import { UserDetailsHeader } from '../UserDetailsHeader';

import { OnlineModeratorsProps } from './OnlineModerators.interfaces';

export const OnlineModerators = ({ onlineModerators = [] }: OnlineModeratorsProps) => {
  return (
    <div aria-label="Online moderators" className="c-online-moderators">
      <div className="c-online-moderators__count">{onlineModerators.length === 0 ? 'No' : onlineModerators.length} moderators online</div>
      <ul className="c-online-moderators__list">
        {onlineModerators.map((userProps, key) => (
          <li key={key}>
            <UserDetailsHeader {...userProps} />
          </li>
        ))}
      </ul>
    </div>
  );
};
