import { gql } from '@apollo/client';

const IDBTopicGraphTypeFragment = gql`
  fragment topic on TopicGraphType {
    id
    title
    description
    postsCount
  }
`;

export { IDBTopicGraphTypeFragment };
