import { IMakeMessageReadByReceiverOptions } from './useMakeMessageReadByReceiver.interface';

import { bonoboClient } from '../../apollo';
import { useMakeMessageReadByReceiverMutation } from '../../bonobo/generated.graphql';

export const useMakeMessageReadByReceiver = (options?: IMakeMessageReadByReceiverOptions) => {
  const { onCompleted, onError } = options ?? ({} as IMakeMessageReadByReceiverOptions);

  const [makeMessageReadByReceiver, { loading: isLoading, error }] = useMakeMessageReadByReceiverMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      void (async () => onCompleted(data))();
    },
    onError,
  });

  return { makeMessageReadByReceiver, isLoading, error };
};
