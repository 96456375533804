import { gql } from '@apollo/client';

import { IDBPostPollGraphTypeFragment } from './postPoll.fragment';
import { IDBUserGraphTypeFragment } from './user.fragment';

const IDBPostShareGraphTypeFragment = gql`
  fragment postShareGraphType on PostShareGraphType {
    originalPost {
      id
      assets {
        postId
        assetId
        contentType
        fileName
        presignedUrl
        s3Key
      }
      author {
        ...user
      }
      commentCount
      createdDate
      createdDateTime
      feeling
      hasRelated
      message
      pinned
      relateCount
      shareCount
      shareOption
      status
      tags
      topic {
        id
        title
        description
        postsCount
      }
      postType: __typename
      updatedDate
      ...poll
    }
  }
  ${IDBUserGraphTypeFragment}
  ${IDBPostPollGraphTypeFragment}
`;

export { IDBPostShareGraphTypeFragment };
