import { OperationVariables } from '@apollo/client';
import { useApolloLazyQuery, useCookie } from '@netfront/common-library';

import { IUseBonoboLazyQueryInputVariables } from './useBonoboLazyQuery.interfaces';

import { getApolloLink } from '../../utils/apollo';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useBonoboLazyQuery = <TData = any, TVariables extends OperationVariables = OperationVariables>({
  apolloLink,
  options,
  product,
  query,
  token,
}: IUseBonoboLazyQueryInputVariables<TData, TVariables>) => {
  const { getAccessTokenCookie, getTemporaryTokenCookie } = useCookie();

  const accessToken = getAccessTokenCookie();
  const temporaryToken = getTemporaryTokenCookie();

  const authenticationLink = getApolloLink({
    apiName: 'bonobo',
    product,
    token: token ?? accessToken ?? temporaryToken,
  });

  return useApolloLazyQuery<TData, TVariables>({
    apiName: 'bonobo',
    apolloLink: apolloLink ?? authenticationLink,
    options,
    query,
  });
};

export { useBonoboLazyQuery };
