import { Skeleton } from '@netfront/ui-library';

import { WelcomeProps } from './Welcome.interfaces';

import { Button, HelpButton, UserInitials } from '../../components';

const Welcome = ({ greeting = 'Hello', fullName, isLoading, onSpotlightRun }: WelcomeProps) => {
  return (
    <div className="flex mb-12">
      <div className="flex-1 mr-4">
        <div className="flex w-full items-center flex-1">
          {isLoading ? (
            <div className="flex items-center">
              <Skeleton baseColor="#E5F7F9" height="5rem" highlightColor="#fff" width="5rem" circle />
              <Skeleton baseColor="#E5F7F9" height="5rem" highlightColor="#fff" width="100%" />
            </div>
          ) : (
            <>
              <UserInitials size="large" />
              <div className="ml-6">
                <h2 className="color-primary h2 mb-0">
                  <span className="h2 color-blue-700">{greeting}, </span>
                  {fullName}
                </h2>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="c-crisis-support-button flex items-center">
        <Button theme="green" onPress={onSpotlightRun}>
          Dashboard tour
        </Button>
        <HelpButton className="ml-4" />
      </div>
    </div>
  );
};

export { Welcome };
