import { gql } from '@apollo/client';

import { IDBPostPollGraphTypeFragment } from './postPoll.fragment';

const IDBPostInterfaceGraphTypeFragment = gql`
  fragment post on PostInterfaceGraphType {
    id
    assets {
      postId
      assetId
      contentType
      fileName
      presignedUrl
      s3Key
    }
    author {
      id
      displayedName
      firstName
      lastName
      isAnonymous
    }
    authorCommunityRole
    ... on PostGraphType {
      community {
        userConnection {
          role
        }
      }
    }
    commentCount
    createdDate
    createdDateTime
    feeling
    hasRelated
    message
    pinned
    relateCount
    shareCount
    shareOption
    status
    tags
    topic {
      id
      title
      description
      postsCount
    }
    postType: __typename
    updatedDate
    ...poll
  }
  ${IDBPostPollGraphTypeFragment}
`;

export { IDBPostInterfaceGraphTypeFragment };
