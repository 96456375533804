import { useState } from 'react';

import { Dialog } from '@netfront/ui-library';

import { ImageEditor } from '../ImageEditor';

import { ProfileAvatarDialogProps } from './ProfileAvatarDialog.interfaces';

export const ProfileAvatarDialog = ({ onConfirm, ...rest }: ProfileAvatarDialogProps) => {
  const [imageFile, setImageFile] = useState<File>();

  return (
    <Dialog
      {...rest}
      onConfirm={() => {
        if (imageFile) {
          onConfirm(imageFile);
        }
      }}
    >
      <ImageEditor onImageUpdate={setImageFile} />
    </Dialog>
  );
};
