import { useEffect, useState } from 'react';

import { Button } from '@netfront/ui-library';

import { ConversationsQueryResult, MessageListGraphType, useGetConversations } from '../../../../services';
import { BaseLayoutPage, EmptyMessage, ListCard, ListCardSkeleton, SearchInput, useSearchInput } from '../../../Social';

const MessagesPage = () => {
  const { isSearchActive, onSearchClear, onSearchSubmit, searchValue } = useSearchInput();

  const [userConversations, setUserConversations] = useState<MessageListGraphType[]>([]);

  const handleGetConversationsCompleted = (conversations: ConversationsQueryResult[]) => {
    setUserConversations(conversations.map(({ node }) => node));
  };

  const { getConversations, isLoading: isGetConversationsLoading } = useGetConversations({
    onCompleted: handleGetConversationsCompleted,
  });

  useEffect(() => {
    if (userConversations.length) {
      return;
    }

    getConversations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const conversationsWithFilter = userConversations.filter((conversation) =>
    searchValue && isSearchActive ? String(conversation.receiver?.displayedName).includes(searchValue) : conversation,
  );

  const hasConversations = !isGetConversationsLoading && userConversations.length > 0;
  const hasNoConversations = !isGetConversationsLoading && userConversations.length === 0;

  return (
    <BaseLayoutPage
      breadcrumbItems={[{ label: 'Messages' }]}
      meta={{ seoDescription: `View all your conversations`, seoTitle: `Conversations` }}
      title="Conversations"
    >
      <div className="c-messages-page__container">
        <div className="c-messages-page__search">
          <SearchInput
            id="search"
            isSearchActive={isSearchActive}
            labelText="Search messages"
            name="search"
            placeholder="Search your connections to send a message"
            type="text"
            isLabelHidden
            onClear={onSearchClear}
            onSearch={onSearchSubmit}
          />
          <Button iconId="id_plus_icon" linkButton={{ id: 'create', url: '/social/messages/new' }} text="Message" />
        </div>

        {isGetConversationsLoading && (
          <>
            <ListCardSkeleton />
            <ListCardSkeleton />
          </>
        )}

        {hasConversations &&
          conversationsWithFilter.map(({ lastMessage, receiver, unReadCount }, key) => (
            <ListCard
              key={key}
              avatarImage={receiver?.profileImage?.presignedUrl}
              avatarTitle={receiver?.displayedName}
              description={lastMessage?.text}
              displayName={receiver?.displayedName}
              href={`/social/messages/${String(receiver?.key)}`}
              hasArrow
            >
              <div className="c-list-card__message">
                {Boolean(unReadCount) && <div className="c-list-card__unread-count">{unReadCount}</div>}
              </div>
            </ListCard>
          ))}

        {hasNoConversations && <EmptyMessage message="No conversations found" />}
      </div>
    </BaseLayoutPage>
  );
};

export { MessagesPage };
