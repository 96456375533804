import { IGetPostOptions } from './useGetPost.interface';

import { DBPost } from '../../../interfaces';
import { bonoboClient } from '../../apollo';
import { useGetPostLazyQuery } from '../../bonobo/generated.graphql';

export const useGetPost = (options?: IGetPostOptions) => {
  const { onCompleted, onError } = options ?? ({} as IGetPostOptions);

  const [getPost, { loading: isLoading, error, refetch }] = useGetPostLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data.post?.getPost as DBPost);
    },
    onError,
  });

  return { getPost, isLoading, error, refetch };
};
