import { useEffect, useState } from 'react';

import { useUser } from '@netfront/gelada-identity-library';
import { Button, Dialog } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { DBCommunity } from '../../../../interfaces';
import {
  CommunityMembersQueryResult,
  EConnectionStatus,
  ESearchPrivacy,
  useGetCommunity,
  useGetCommunityUsers,
} from '../../../../services';
import {
  BaseLayoutPage,
  CommunityMenu,
  EmptyMessage,
  IconLock,
  ListCard,
  ListCardSkeleton,
  OnlineModerators,
  PageBanner,
  SearchInput,
  useSearchInput,
} from '../../../Social';

const CommunityMembersPage = () => {
  const {
    query: { communityKey },
  } = useRouter();

  const loggedUser = useUser().getUser();
  const { isSearchActive, onSearchClear, onSearchSubmit } = useSearchInput();

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState<boolean>(false);
  const [community, setCommunity] = useState<DBCommunity>();
  const [members, setMembers] = useState<CommunityMembersQueryResult[]>([]);

  const isLoggedUser = (id: number): boolean => id === Number(loggedUser?.id);

  const closeConnectDialog = () => {
    setIsConnectDialogOpen(false);
  };

  const onConnectRequest = () => {
    setIsConnected(true);
    closeConnectDialog();
  };

  const getCommunityCompleted = (returnedCommunity: DBCommunity) => {
    const { userConnection } = returnedCommunity;

    if (userConnection) {
      setIsConnected(userConnection.status === EConnectionStatus.Accepted);
    }

    setCommunity(returnedCommunity);
  };

  const { getCommunity } = useGetCommunity({
    onCompleted: getCommunityCompleted,
  });

  const { getCommunityUsers, isLoading: isGetCommunityUsersLoading } = useGetCommunityUsers({
    onCompleted: setMembers,
  });

  useEffect(() => {
    if (community || !communityKey) {
      return;
    }

    getCommunity({ variables: { key: String(communityKey), shouldIncludeUserConnection: true } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityKey, isConnected]);

  useEffect(() => {
    if (!community) return;

    if (isConnected) {
      getCommunityUsers({
        variables: {
          communityId: Number(community.id),
          shouldIncludeUser: true,
          status: EConnectionStatus.Accepted,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community, isConnected]);

  return (
    <BaseLayoutPage
      breadcrumbItems={[{ label: 'Communities', href: '/social/communities' }, { label: community?.title ?? '' }]}
      meta={{ seoDescription: `View members in ${String(community?.title)}`, seoTitle: `Members in ${community?.title ?? ''}` }}
      title="Members"
    >
      {community && (
        <>
          <PageBanner
            bannerImage={community.bannerImage?.presignedUrl}
            description={community.description}
            profileImage={community.profileImage?.presignedUrl}
            title={community.title}
          >
            {isConnected ? <CommunityMenu /> : <Button text="Connect" onClick={() => setIsConnectDialogOpen(true)} />}
          </PageBanner>

          <div className="c-community-page__container">
            {isConnected ? (
              <div>
                <OnlineModerators />
                <SearchInput
                  id="members"
                  isSearchActive={isSearchActive}
                  labelText="Search members"
                  name="members"
                  placeholder="Search members"
                  type="text"
                  isLabelHidden
                  onClear={() => {
                    getCommunityUsers({
                      variables: {
                        communityId: Number(community.id),
                        shouldIncludeUser: true,
                        status: EConnectionStatus.Accepted,
                      },
                    });

                    onSearchClear();
                  }}
                  onSearch={(value) => {
                    getCommunityUsers({
                      variables: {
                        communityId: Number(community.id),
                        shouldIncludeUser: true,
                        status: EConnectionStatus.Accepted,
                        filter: value,
                        searchPrivacy: ESearchPrivacy.IncludeDisplayedName,
                      },
                    });

                    onSearchSubmit(value);
                  }}
                />

                {isGetCommunityUsersLoading && [...Array(3)].map((_, key) => <ListCardSkeleton key={key} />)}

                {!isGetCommunityUsersLoading && Boolean(members.length) && (
                  <>
                    <h2>Members ({members.length})</h2>
                    {members.map(({ node: { id, user } }) => (
                      <ListCard
                        key={id}
                        avatarImage={user?.profileImage?.presignedUrl}
                        avatarTitle={user?.displayedName}
                        description="View profile"
                        displayName={user?.displayedName}
                        href={isLoggedUser(Number(user?.id)) ? '/social/profile' : `/social/profile/${String(user?.key)}`}
                        hasArrow
                      ></ListCard>
                    ))}
                  </>
                )}
                {!isGetCommunityUsersLoading && Boolean(!members.length) && <EmptyMessage message="No members found" />}
              </div>
            ) : (
              <EmptyMessage icon={() => <IconLock />} message={`Connect to view ${String(community.title)}'s feed`} />
            )}
          </div>

          <Dialog
            isOpen={isConnectDialogOpen}
            title={`Connect with ${community.title}`}
            onCancel={closeConnectDialog}
            onClose={closeConnectDialog}
            onConfirm={onConnectRequest}
          >
            <p>Are you sure you want to connect with {community.title}?</p>
          </Dialog>
        </>
      )}
    </BaseLayoutPage>
  );
};

export { CommunityMembersPage };
