import { ICreatePostReportOptions } from './useCreatePostReport.interface';

import { bonoboClient } from '../../apollo';
import { useCreatePostReportMutation } from '../../bonobo/generated.graphql';

export const useCreatePostReport = (options?: ICreatePostReportOptions) => {
  const { onCompleted, onError } = options ?? ({} as ICreatePostReportOptions);

  const [createSharePostMutation, { loading: isLoading, error }] = useCreatePostReportMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data);
    },
    onError,
  });

  return { createSharePostMutation, isLoading, error };
};
