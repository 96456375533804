import { ICreateUserConnectionOptions } from './useCreateUserConnection.interface';

import { bonoboClient } from '../../apollo';
import { useCreateUserConnectionMutation } from '../../bonobo/generated.graphql';

export const useCreateUserConnection = (options?: ICreateUserConnectionOptions) => {
  const { onCompleted, onError } = options ?? ({} as ICreateUserConnectionOptions);

  const [createUserConnection, { loading: isCreateConnectionLoading, error }] = useCreateUserConnectionMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data);
    },
    onError,
  });

  return { createUserConnection, isCreateConnectionLoading, error };
};
