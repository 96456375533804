import { useBonoboLazyQuery } from 'hooks';

import { GET_FEED_POST_QUERY } from './useGetFeedPost.graphql';
import {
  IHandleGetFeedPostParams,
  IGetFeedPostQueryGraphQLResponse,
  IGetFeedPostQueryVariables,
  IUseGetFeedPostOptions,
  IUseGetFeedPost,
} from './useGetFeedPost.interfaces';

const useGetFeedPost = (options?: IUseGetFeedPostOptions): IUseGetFeedPost => {
  const { fetchPolicy, onCompleted, onError, product, query, token } = options ?? ({} as IUseGetFeedPostOptions);

  const [executeGetFeedPost, { loading: isLoading }] = useBonoboLazyQuery<
  IGetFeedPostQueryGraphQLResponse,
  IGetFeedPostQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const { post: { getPost: post } } = data

        onCompleted({
          post,
        });
      },
      onError,
    },
    product,
    query: query ?? GET_FEED_POST_QUERY,
    token,
  });

  const handleGetFeedPost = async ({ postId }: IHandleGetFeedPostParams) => {
    await executeGetFeedPost({
      variables: {
        postId
      },
    });
  };

  return {
    handleGetFeedPost,
    isLoading,
  };
};

export { useGetFeedPost };
