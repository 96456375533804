import { IDeleteAssetOptions } from './useDeleteAsset.interface';

import { bonoboClient } from '../../apollo';
import { useDeleteAssetMutation } from '../../bonobo/generated.graphql';

export const useDeleteAsset = (options?: IDeleteAssetOptions) => {
  const { onCompleted, onError } = options ?? ({} as IDeleteAssetOptions);

  const [deleteAsset, { loading: isDeleteAssetLoading, error }] = useDeleteAssetMutation({
    client: bonoboClient,
    onCompleted: () => {
      if (!onCompleted) {
        return;
      }

      onCompleted(true);
    },
    onError,
  });

  return { deleteAsset, isDeleteAssetLoading, error };
};
