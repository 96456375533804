import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { SOCIAL_HEADER_MENU_ITEMS } from '../SocialHeader/SocialHeader.constants';

import { HeaderMenuDesktopProps } from './HeaderMenuDesktop.interfaces';

import { getValidClassNames } from '../../../utils';

const HeaderMenuDesktop = ({ connectionsCount, messageCount }: HeaderMenuDesktopProps) => {
  const { pathname } = useRouter();

  return (
    <nav aria-label="Main navigation">
      <ul className="c-header-menu__list">
        {SOCIAL_HEADER_MENU_ITEMS.map(({ href, id, label, icon }) => (
          <li key={id} className="c-header-menu__item">
            <NextLink href={href} legacyBehavior>
              <a
                className={getValidClassNames({
                  'c-header-menu__link': true,
                  'c-header-menu__link--active': pathname.includes(href),
                })}
              >
                {icon && icon('c-header-menu__icon')}
                <span className="c-header-menu__label">{label}</span>
              </a>
            </NextLink>
            {id === 'connections' && Boolean(connectionsCount) && <span className="c-header-menu__notification">{connectionsCount}</span>}
            {id === 'messages' && Boolean(messageCount) && <span className="c-header-menu__notification">{messageCount}</span>}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export { HeaderMenuDesktop };
