import { IconPollProps } from './IconPoll.interfaces';

const IconPoll = ({ isActive }: IconPollProps) => {
  return isActive ? (
    <svg height="35" id="poll-inactive" viewBox="0 0 35 35" width="35" xmlns="http://www.w3.org/2000/svg">
      <rect data-name="Rectangle 3542" fill="#48756f" height="35" id="Rectangle_3542" rx="8" width="35" />
      <path
        d="M23.008,4.5H6.813A2.32,2.32,0,0,0,4.5,6.813V23.008a2.32,2.32,0,0,0,2.313,2.313H23.008a2.32,2.32,0,0,0,2.313-2.313V6.813A2.32,2.32,0,0,0,23.008,4.5ZM11.44,20.694H9.127V12.6H11.44Zm4.627,0H13.754V9.127h2.313Zm4.627,0H18.381V16.067h2.313Z"
        data-name="Icon material-poll"
        fill="#fff"
        id="Icon_material-poll"
        opacity="0.5"
        transform="translate(2.59 2.589)"
      />
    </svg>
  ) : (
    <svg height="35" id="poll-inactive" viewBox="0 0 35 35" width="35" xmlns="http://www.w3.org/2000/svg">
      <rect data-name="Rectangle 3542" fill="#6DC7BE" height="35" id="Rectangle_3542" rx="8" width="35" />
      <path
        d="M23.008,4.5H6.813A2.32,2.32,0,0,0,4.5,6.813V23.008a2.32,2.32,0,0,0,2.313,2.313H23.008a2.32,2.32,0,0,0,2.313-2.313V6.813A2.32,2.32,0,0,0,23.008,4.5ZM11.44,20.694H9.127V12.6H11.44Zm4.627,0H13.754V9.127h2.313Zm4.627,0H18.381V16.067h2.313Z"
        data-name="Icon material-poll"
        fill="#fff"
        id="Icon_material-poll"
        transform="translate(2.59 2.589)"
      />
    </svg>
  );
};

export { IconPoll };
