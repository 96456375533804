export const WarningIcon = () => {
  return (
    <svg height="32.917" viewBox="0 0 36.736 32.917" width="36.736" xmlns="http://www.w3.org/2000/svg">
      <g data-name="Group 48419" transform="translate(694.322 -1068.051)">
        <path
          d="M-661.752 1100.968h-28.4a4.116 4.116 0 01-3.6-2.079 4.116 4.116 0 010-4.159l6.807-11.075a1.631 1.631 0 012.246-.536 1.632 1.632 0 01.537 2.246l-6.785 11.036a.859.859 0 00.023.855.877.877 0 00.773.447h28.4a.877.877 0 00.773-.447.875.875 0 000-.893l-14.2-24.6a.878.878 0 00-.773-.447.879.879 0 00-.774.447l-3.16 5.473a1.633 1.633 0 01-2.231.6 1.633 1.633 0 01-.6-2.231l3.16-5.473a4.117 4.117 0 013.6-2.08 4.115 4.115 0 013.6 2.08l14.2 24.6a4.116 4.116 0 010 4.159 4.118 4.118 0 01-3.596 2.077z"
          data-name="Path 39580"
          fill="#fff"
        ></path>
      </g>
      <g data-name="Group 48420" transform="translate(694.322 -1068.051)">
        <g data-name="Path 38465">
          <path
            d="M-675.952 1077.079a1.352 1.352 0 00-1.352 1.352v8.335a1.352 1.352 0 001.423 1.277 1.353 1.353 0 001.277-1.277v-8.335a1.352 1.352 0 00-1.348-1.352z"
            data-name="Path 39581"
            fill="#fff"
          ></path>
        </g>
        <g data-name="Path 38466">
          <path
            d="M-675.952 1090.656a1.351 1.351 0 00-1.352 1.35v.811a1.352 1.352 0 001.423 1.277 1.353 1.353 0 001.277-1.277v-.809a1.352 1.352 0 00-1.348-1.352z"
            data-name="Path 39582"
            fill="#fff"
          ></path>
        </g>
      </g>
    </svg>
  );
};
