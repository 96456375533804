import { useEffect, useRef, useState } from 'react';

import { useUser } from '@netfront/gelada-identity-library';
import { Button } from '@netfront/ui-library';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { DBConversation, DBUser } from '../../../../interfaces';
import {
  ConversationQueryResult,
  CreateConversationMutation,
  useCreateConversation,
  useGetConversation,
  useGetUserByKey,
  useMarkWholeConversationRead,
} from '../../../../services';
import { BaseLayoutPage, CommentBox, ConversationMessage, ConversationMessageSkeleton } from '../../../Social';

const ConversationPage = () => {
  const {
    query: { userKey },
  } = useRouter();

  const containerRef = useRef<HTMLDivElement>(null);
  const loggedUser = useUser().getUser();

  const [conversationMessages, setConversationMessages] = useState<DBConversation[]>([]);
  const [recipientUser, setRecipientUser] = useState<DBUser>();
  const [message, setMessage] = useState<string>('');

  const handleGetConversationCompleted = (conversations: ConversationQueryResult[]) => {
    if (conversationMessages.length) {
      return;
    }
    setConversationMessages(conversations.map(({ node }) => node));
  };

  const handleGetUserInformationCompleted = (user: DBUser) => {
    if (recipientUser) {
      return;
    }

    setRecipientUser(user);
  };

  const handleCreateConversationCompleted = (conversation: CreateConversationMutation) => {
    const newMessage = conversation.message?.createConversation as unknown as DBConversation;

    setConversationMessages((prevState) => [...prevState, newMessage]);
    setMessage('');

    setTimeout(() => {
      containerRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 200);
  };

  const { getConversation, isLoading: isConversationLoading } = useGetConversation({
    onCompleted: handleGetConversationCompleted,
  });

  const { getUserByKey, isLoading: isGetUserByKeyLoading } = useGetUserByKey({
    onCompleted: handleGetUserInformationCompleted,
  });

  const { createConversation, isLoading: isSubmittingConversation } = useCreateConversation({
    onCompleted: handleCreateConversationCompleted,
  });

  const { markWholeConversationReadMutation } = useMarkWholeConversationRead();

  useEffect(() => {
    if (!userKey || !loggedUser) {
      return;
    }

    if (!recipientUser) {
      getUserByKey({
        variables: {
          key: String(userKey),
        },
      });

      return;
    }

    if (conversationMessages.length === 0) {
      getConversation({
        variables: {
          otherUserId: Number(recipientUser.id),
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userKey, recipientUser]);

  useEffect(() => {
    const unreadMessages = conversationMessages.filter(({ isSeenByReceiver, sender }) => !isSeenByReceiver && sender.id !== loggedUser?.id);

    if (unreadMessages.length) {
      markWholeConversationReadMutation({
        variables: {
          senderId: Number(recipientUser?.id),
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationMessages]);

  const hasConversation =
    !isConversationLoading && !isGetUserByKeyLoading && loggedUser && recipientUser && conversationMessages.length > 0;

  const isNewConverstation =
    !isConversationLoading && !isGetUserByKeyLoading && loggedUser && recipientUser && conversationMessages.length === 0;

  return (
    <BaseLayoutPage
      breadcrumbItems={[{ label: 'Messages', href: '/social/messages' }, { label: `${recipientUser?.displayedName ?? ''}` }]}
      meta={{ seoDescription: `View your conversation history`, seoTitle: `Conversation` }}
      title="Conversation"
    >
      <div className="c-conversation-page__container">
        <NextLink href={`/social/profile/${String(recipientUser?.key)}`} legacyBehavior>
          <a className="c-conversation-page__title">
            <h1>{recipientUser?.displayedName}</h1>
          </a>
        </NextLink>

        {isGetUserByKeyLoading || (isConversationLoading && <ConversationMessageSkeleton />)}
        {hasConversation && (
          <div ref={containerRef} className="c-conversation-page__conversation">
            <div>
              {conversationMessages.map(({ sender, text, isSeenByReceiver }, key) => {
                const isFollowUpMessageFromSameUser = conversationMessages[key - 1]?.sender.id !== sender.id;

                const readStatus =
                  key === conversationMessages.length - 1 ? (sender.id === loggedUser.id ? (isSeenByReceiver ? 'Seen' : 'Sent') : '') : '';

                return (
                  <ConversationMessage
                    key={key}
                    avatarImage={sender.profileImage?.presignedUrl}
                    displayName={sender.displayedName}
                    isSentByUser={sender.id === loggedUser.id}
                    readStatus={readStatus}
                    shouldShowAvatar={isFollowUpMessageFromSameUser}
                    text={text}
                  />
                );
              })}
            </div>
          </div>
        )}

        {isNewConverstation && (
          <div className="c-conversation-page__empty-message">
            <p>
              Send your first message to <strong>{recipientUser.displayedName}</strong>
            </p>
          </div>
        )}

        {!isConversationLoading && !isGetUserByKeyLoading && (
          <form className="c-conversation-page__form">
            <CommentBox
              id="message"
              labelText="Message"
              name="message"
              placeholder="Your message..."
              value={message}
              isLabelHidden
              onChange={setMessage}
            />

            <Button
              isDisabled={Boolean(!message) || isSubmittingConversation}
              text="Send message"
              type="submit"
              onClick={() => {
                createConversation({
                  variables: {
                    message: String(message),
                    receiverId: Number(recipientUser?.id),
                  },
                });
              }}
            />
          </form>
        )}
      </div>
    </BaseLayoutPage>
  );
};

export { ConversationPage };
