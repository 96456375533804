import { useEffect, useState } from 'react';

import { IHasSeenSpotlightOnCompletedResponse, useHasSeenSpotlight } from '@netfront/gelada-identity-library';
import Cookies from 'js-cookie';
import Joyride, { CallBackProps, STATUS } from 'react-joyride';

import { spotLightConstants } from './Spotlight.constants';
import { ISpotlight, SpotlightProps } from './Spotlight.interfaces';

const Spotlight = ({
  disableOverlayClose,
  disableCloseOnEsc,
  hideCloseButton,
  spotlightItem,
  onSpotLightData,
  shouldRun,
  onClose,
}: SpotlightProps) => {
  const { spotLightDisplayTimeoutInMilliseconds, spotLightCookieRemoveTimeoutInMilliseconds } = spotLightConstants;

  const [spotlightTour, setSpotlightTour] = useState<ISpotlight>(spotlightItem);

  const handleHasSeenSpotlightCompleted = (data: IHasSeenSpotlightOnCompletedResponse) => {
    const { hasSeenSpotlight } = data;
    if (!hasSeenSpotlight) {
      setTimeout(() => {
        handleSpotlightStart();
      }, spotLightDisplayTimeoutInMilliseconds);
    }
  };

  const { handleHasSeenSpotlight } = useHasSeenSpotlight({
    onCompleted: handleHasSeenSpotlightCompleted,
  });

  const handleSpotlightCallback = (data: CallBackProps) => {
    const { status } = data;

    onSpotLightData(data);

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setSpotlightTour({
        ...spotlightTour,
        run: true,
      });
    }

    if (STATUS.READY === status) {
      setSpotlightTour({
        ...spotlightTour,
        run: false,
      });

      if (onClose) {
        onClose();
      }
    }
  };

  const handleSpotlightStart = () => {
    setSpotlightTour({
      ...spotlightTour,
      run: true,
    });
  };

  useEffect(() => {
    handleHasSeenSpotlight({ url: window.location.pathname });

    if (!Cookies.get('spotlightTour')) {
      return;
    }

    setTimeout(() => {
      handleSpotlightStart();
    }, spotLightDisplayTimeoutInMilliseconds);

    setTimeout(() => {
      Cookies.remove('spotlightTour');
    }, spotLightCookieRemoveTimeoutInMilliseconds);
  }, [handleHasSeenSpotlight, handleSpotlightStart, spotLightDisplayTimeoutInMilliseconds, spotLightCookieRemoveTimeoutInMilliseconds]);

  useEffect(() => {
    if (shouldRun) {
      handleSpotlightStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRun]);

  return (
    <div>
      <Joyride
        callback={handleSpotlightCallback}
        continuous={true}
        disableCloseOnEsc={disableCloseOnEsc}
        disableOverlayClose={disableOverlayClose}
        hideCloseButton={hideCloseButton}
        run={spotlightTour.run}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={spotlightTour.steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
    </div>
  );
};

export { Spotlight };
