import { gql } from '@apollo/client';

const GET_ANSWERS_QUERY = gql`
  query getAnswers($userFlowStepTrackId: Int!) {
    questionAnswer {
      getAnswers(trackingId: $userFlowStepTrackId) {
        id
        contentSnippetQuestionId
        ... on QuestionAnswerCalendarType {
          dateAnswered
        }
        ... on QuestionAnswerCheckboxType {
          selectedAnswers {
            questionResponseCheckboxId
          }
        }
        ... on QuestionAnswerDropDownListType {
          questionResponseDropDownListId
        }
        ... on QuestionAnswerMultilineTextType {
          text
        }
        ... on QuestionAnswerMultiResponseTextType {
          responses {
            value
          }
        }
        ... on QuestionAnswerNumberType {
          numberAnswered
        }
        ... on QuestionAnswerRadioType {
          questionResponseRadioId

          questionResponse {
            label
          }

          contentSnippetQuestion {
            question
          }
        }
        ... on QuestionAnswerSinglelineTextType {
          text
        }
        ... on QuestionAnswerSliderType {
          value
        }
      }
    }
  }
`;

export { GET_ANSWERS_QUERY };
