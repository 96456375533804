import { gql } from '@apollo/client';

import { IDBUserGraphTypeFragment } from './user.fragment';

const IDBUserConnectionGraphTypeFragment = gql`
  fragment userConnection on ConnectionGraphType {
    connectionStatus
    createdDate
    friendId
    id
    updatedDate
    user {
      ...user
    }
    userId
  }
  ${IDBUserGraphTypeFragment}
`;

export { IDBUserConnectionGraphTypeFragment };
