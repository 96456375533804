import { gql } from '@apollo/client';

import {
  IDBCommentGraphTypeFragment,
  IDBPostInterfaceGraphTypeFragment,
  IDBPostPollGraphTypeFragment,
  IDBPostShareGraphTypeFragment,
  IDBUserGraphTypeFragment,
} from '../../fragments';

export const GetFeedPosts = gql`
  query getFeedPosts(
    $after: String
    $communityId: Int
    $first: Int
    $message: String
    $tags: [String]
    $topics: [Int]
    $authorId: Int
    $from: Date
    $to: Date
    $sort: EPostSorting
    $shouldIncludeComments: Boolean = false
  ) {
    post {
      getFeedPosts(
        after: $after
        communityId: $communityId
        first: $first
        message: $message
        tags: $tags
        topics: $topics
        authorId: $authorId
        from: $from
        to: $to
        sort: $sort
      ) {
        totalCount
        edges {
          cursor
          node {
            id
            assets {
              postId
              assetId
              contentType
              fileName
              presignedUrl
              s3Key
            }
            author {
              ...user
            }
            authorCommunityRole
            comments @include(if: $shouldIncludeComments) {
              edges {
                cursor
                node {
                  ...commentGraphType
                }
              }
            }
            commentCount
            ... on PostGraphType {
              community {
                key
                title
                userConnection {
                  role
                }
              }
            }
            communityId
            createdDate
            createdDateTime
            feeling
            hasRelated
            message
            pinned
            relateCount
            shareCount
            shareOption
            status
            tags
            taggedUsers {
              placeholder
              user {
                id
                displayedName
                firstName
                lastName
                profileImage {
                  presignedUrl
                }
              }
            }
            topic {
              id
              title
              description
              postsCount
            }
            postType: __typename
            updatedDate
            ...poll
            ...postShareGraphType
          }
        }
      }
    }
  }

  ${IDBCommentGraphTypeFragment}
  ${IDBUserGraphTypeFragment}
  ${IDBPostPollGraphTypeFragment}
  ${IDBPostInterfaceGraphTypeFragment}
  ${IDBPostShareGraphTypeFragment}
`;
