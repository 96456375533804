import { gql } from '@apollo/client';
import { IDBCommentGraphTypeFragment, IDBPostInterfaceGraphTypeFragment, IDBPostPollGraphTypeFragment, IDBPostShareGraphTypeFragment, IDBUserGraphTypeFragment } from 'services';

const GET_FEED_POST_QUERY = gql`
  query getPost($postId: Int!) {
    post {
      getPost(postId: $postId) {
        id
            assets {
              postId
              assetId
              contentType
              fileName
              presignedUrl
              s3Key
            }
            author {
              ...user
            }
            comments {
              edges {
                cursor
                node {
                  ...commentGraphType
                }
              }
            }
            commentCount
            ... on PostGraphType {
              community {
                key
                title
                userConnection {
                  role
                }
              }
            }
            communityId
            createdDate
            createdDateTime
            feeling
            hasRelated
            message
            pinned
            relateCount
            shareCount
            shareOption
            status
            tags
            taggedUsers {
              placeholder
              user {
                id
                displayedName
                firstName
                lastName
                profileImage {
                  presignedUrl
                }
              }
            }
            topic {
              id
              title
              description
              postsCount
            }
            postType: __typename
            updatedDate
            ...poll
            ...postShareGraphType
      }
    }
  }

  ${IDBCommentGraphTypeFragment}
  ${IDBUserGraphTypeFragment}
  ${IDBPostPollGraphTypeFragment}
  ${IDBPostShareGraphTypeFragment}
`;

export { GET_FEED_POST_QUERY };
