import { useEffect } from 'react';

import { useProtectedRoute } from 'hooks';
import { useRouter } from 'next/router';

import { Page } from 'components/Pages';

import { BaseLayoutPageProps } from './BaseLayoutPage.interfaces';

import { PrivateFooter } from '../../../../components';
import { Breadcrumb, SocialHeader } from '../../../../components/Social';
import { getValidClassNames } from '../../../../utils';

const BaseLayoutPage = ({ breadcrumbItems, children, meta, title }: BaseLayoutPageProps) => {
  const { push } = useRouter();

  const { isAuthenticated } = useProtectedRoute();

  useEffect(() => {
    if (!isAuthenticated) {
      push('/login');
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <Page
      footer={<PrivateFooter />}
      header={
        <SocialHeader>
          <Breadcrumb breadcrumbItems={breadcrumbItems} />
        </SocialHeader>
      }
      meta={meta}
      title={title}
    >
      <div
        className={getValidClassNames({
          'c-page': true,
          'c-page--with-breadcrumbs': Boolean(breadcrumbItems),
        })}
      >
        {children}
      </div>
    </Page>
  );
};

export { BaseLayoutPage };
