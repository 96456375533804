import { saveAuthenticationData, usePublicGeladaMutation } from '@netfront/gelada-identity-library';

import { REGISTER_WITHOUT_ACTIVATION_MUTATION } from './useRegisterWithoutActivation.graphql';
import {
  IHandleRegisterUserParams,
  IRegisterWithoutActivationMutationGraphQLResponse,
  IRegisterUserMutationVariables,
  IUseRegisterUser,
  IUseRegisterUserOptions,
} from './useRegisterWithoutActivation.interfaces';

const useRegisterWithoutActivation = (options?: IUseRegisterUserOptions): IUseRegisterUser => {
  const { mutation, onCompleted, onError, product, projectId } = options ?? ({} as IUseRegisterUserOptions);

  const [executeRegisterWithoutActivation, { loading: isLoading }] = usePublicGeladaMutation<
    IRegisterWithoutActivationMutationGraphQLResponse,
    IRegisterUserMutationVariables
  >({
    mutation: mutation ?? REGISTER_WITHOUT_ACTIVATION_MUTATION,
    options: {
      onCompleted: (data) => {
        const {
          registerWithoutActivation: { loginToken, refreshToken, token, user },
        } = data;

        saveAuthenticationData({
          accessToken: token,
          refreshToken,
          user,
        });

        if (!onCompleted) {
          return;
        }

        onCompleted({
          token,
          loginToken,
          refreshToken,
          user,
        });
      },
      onError,
    },
    product,
    projectId,
  });

  const handleRegisterWithoutActivation = async ({ credential, customFields, user }: IHandleRegisterUserParams) => {
    await executeRegisterWithoutActivation({
      variables: {
        customFields,
        credential,
        user,
      },
    });
  };

  return {
    handleRegisterWithoutActivation,
    isLoading,
  };
};

export { useRegisterWithoutActivation };
