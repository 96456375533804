import { gql } from '@apollo/client';

import { IDBVotePollGraphTypeFragment } from './pollVote.fragment';

const IDBPollGraphTypeFragment = gql`
  fragment pollGraph on PollGraphType {
    end
    id
    start
    end
    items {
      id
      title
      votes {
        pollItemId
        userId
      }
      votesCount
    }
    postId
    title
    userVote {
      ...userVoteGraph
    }
  }

  ${IDBVotePollGraphTypeFragment}
`;

export { IDBPollGraphTypeFragment };
