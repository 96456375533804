import { gql } from '@apollo/client';

const IDBAssetGraphTypeFragment = gql`
  fragment asset on AssetGraphType {
    assetId
    # communityId
    contentType
    fileName
    fileSizeInBytes
    # messId
    # postId
    presignedUrl
    s3Key
    uploaded
  }
`;

export { IDBAssetGraphTypeFragment };
