import { ICreateSubCommentOptions } from './useCreateSubComment.interface';

import { bonoboClient } from '../../apollo';
import { useCreateSubCommentMutation } from '../../bonobo/generated.graphql';

export const useCreateSubComment = (options?: ICreateSubCommentOptions) => {
  const { onCompleted, onError } = options ?? ({} as ICreateSubCommentOptions);

  const [createSubComment, { loading: isCreateSubCommentLoading, error }] = useCreateSubCommentMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data);
    },
    onError,
  });

  return { createSubComment, isCreateSubCommentLoading, error };
};
