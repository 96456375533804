import uuid4 from 'uuid4';

export const BASE_POLL_ITEMS = [
  { id: uuid4(), title: '' },
  { id: uuid4(), title: '' },
];

export const POLL_TITLE_ERROR_MESSAGE = 'A poll title is required';

export const POLL_ITEM_MINIMUM_ERROR_MESSAGE = 'A poll requires at least 2 response options';
