import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: number;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: Date;
  DateTime: Date;
  DateTimeOffset: any;
  Decimal: number;
  Milliseconds: number;
  Seconds: number;
};

export type AddOrUpdateTemplateUsageInputType = {
  projectId: Scalars['String'];
  templateId: Scalars['Int'];
  usage: EEmailUsage;
};

export type AddOrUpdateTemplateUsageSmsInputType = {
  projectId: Scalars['String'];
  templateId: Scalars['Int'];
  usage: ESmsUsage;
};

export type AssetGraphType = {
  contentType?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
};

export type AttachmentInputType = {
  bucket: Scalars['String'];
  key: Scalars['String'];
};

export type DictionaryGraphType = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum EEmailUsage {
  ActivateAccount = 'ACTIVATE_ACCOUNT',
  CommentCommented = 'COMMENT_COMMENTED',
  CommentRelated = 'COMMENT_RELATED',
  Contact = 'CONTACT',
  ContactFormValidation = 'CONTACT_FORM_VALIDATION',
  Error = 'ERROR',
  ForgotPassword = 'FORGOT_PASSWORD',
  GroupCreationAccepted = 'GROUP_CREATION_ACCEPTED',
  GroupCreationDeclined = 'GROUP_CREATION_DECLINED',
  GroupCreationRequest = 'GROUP_CREATION_REQUEST',
  GroupInvitation = 'GROUP_INVITATION',
  InvitationAccepted = 'INVITATION_ACCEPTED',
  Invoice = 'INVOICE',
  NewCommentAdded = 'NEW_COMMENT_ADDED',
  NewPostAdded = 'NEW_POST_ADDED',
  OrganisationInvitation = 'ORGANISATION_INVITATION',
  PostCommented = 'POST_COMMENTED',
  PostRelated = 'POST_RELATED',
  ProjectInvitation = 'PROJECT_INVITATION',
  ReportRequested = 'REPORT_REQUESTED',
  SuccessfulRegistration = 'SUCCESSFUL_REGISTRATION',
  TierApproaching = 'TIER_APPROACHING',
  UpdateEmail = 'UPDATE_EMAIL',
}

export enum EInternalNotificationUsage {
  ActivityAssigned = 'ACTIVITY_ASSIGNED',
  CalenderEvent = 'CALENDER_EVENT',
  CommentedComment = 'COMMENTED_COMMENT',
  CommentedPost = 'COMMENTED_POST',
  CommunityConnectedRequested = 'COMMUNITY_CONNECTED_REQUESTED',
  ConnectionInactive = 'CONNECTION_INACTIVE',
  LikedComment = 'LIKED_COMMENT',
  MessageReceive = 'MESSAGE_RECEIVE',
  ModuleCompleted = 'MODULE_COMPLETED',
  ModuleLicensed = 'MODULE_LICENSED',
  ReceivedConnectionAccept = 'RECEIVED_CONNECTION_ACCEPT',
  ReceivedConnectionRequest = 'RECEIVED_CONNECTION_REQUEST',
  RelatePost = 'RELATE_POST',
  Reminder = 'REMINDER',
  ResetCommitmentClock = 'RESET_COMMITMENT_CLOCK',
  SharedRedFeeling = 'SHARED_RED_FEELING',
  Survey = 'SURVEY',
  UnitModeAssigned = 'UNIT_MODE_ASSIGNED',
  UserFlowStepTrackMarked = 'USER_FLOW_STEP_TRACK_MARKED',
  WallPost = 'WALL_POST',
}

export enum EMessageType {
  Application = 'APPLICATION',
  Marketing = 'MARKETING',
  Newsletter = 'NEWSLETTER',
  None = 'NONE',
  Reminder = 'REMINDER',
}

export enum ENotificationStatus {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Sent = 'SENT',
}

export enum ENotificationType {
  Email = 'EMAIL',
  InternalNotification = 'INTERNAL_NOTIFICATION',
  PushNotification = 'PUSH_NOTIFICATION',
  Sms = 'SMS',
  WebPush = 'WEB_PUSH',
}

export enum EProjectFeature {
  ContactFormValidationEmail = 'CONTACT_FORM_VALIDATION_EMAIL',
  Email = 'EMAIL',
  PushNotification = 'PUSH_NOTIFICATION',
  Sms = 'SMS',
}

export enum ESmsUsage {
  ActivateAccount = 'ACTIVATE_ACCOUNT',
  ForgotPassword = 'FORGOT_PASSWORD',
  UpdatePhoneNumber = 'UPDATE_PHONE_NUMBER',
}

export enum ETemplateType {
  Email = 'EMAIL',
  InternalNotification = 'INTERNAL_NOTIFICATION',
  None = 'NONE',
  PushNotification = 'PUSH_NOTIFICATION',
  Sms = 'SMS',
  WebPushNotification = 'WEB_PUSH_NOTIFICATION',
}

export enum EUserNotificationPreference {
  Email = 'EMAIL',
  None = 'NONE',
  PushNotification = 'PUSH_NOTIFICATION',
  Sms = 'SMS',
}

export type ErrorMessageGraphType = {
  /** Id */
  id?: Maybe<Scalars['Int']>;
  /** Message */
  message?: Maybe<Scalars['String']>;
};

export enum FileType {
  Csv = 'CSV',
  Excel = 'EXCEL',
}

export type InputNotificationGroup = {
  notifications: Array<InputMaybe<NotificationInputType>>;
  projectId: Scalars['String'];
  subject: Scalars['String'];
  templateId: Scalars['Int'];
};

export type InternalNotificationEventMutation = {
  /** Add a clicked event */
  addClickedEvent?: Maybe<Scalars['Boolean']>;
  /** Add seen event for a defined notification */
  addSeenEvent?: Maybe<Scalars['Boolean']>;
  /** Mark seen all unseen notification */
  markAllSeen?: Maybe<Scalars['Boolean']>;
};

export type InternalNotificationEventMutationAddClickedEventArgs = {
  ipAddress: Scalars['String'];
  notificationId: Scalars['Int'];
  userAgent: Scalars['String'];
};

export type InternalNotificationEventMutationAddSeenEventArgs = {
  ipAddress: Scalars['String'];
  notificationGuid: Scalars['String'];
  userAgent: Scalars['String'];
};

export type InternalNotificationEventMutationMarkAllSeenArgs = {
  ipAddress: Scalars['String'];
  userAgent: Scalars['String'];
};

export type Mutations = {
  /** Contains all the mutations about the Events for internal notification */
  internalNotificationEvent?: Maybe<InternalNotificationEventMutation>;
  /** Contains all the mutations about the Notifications */
  notification?: Maybe<Notification>;
  /** Contains all the mutations about the Projects */
  project?: Maybe<Project>;
  /** Contains all the mutations about the Templates */
  templates?: Maybe<TemplateMutation>;
  /** Contains all the mutations about the Users */
  user?: Maybe<UserMutation>;
};

export type Notification = {
  queueNotification?: Maybe<Scalars['Boolean']>;
  /** Resend notifications */
  resendNotifications?: Maybe<Scalars['Boolean']>;
  /** Send contact form */
  sendContactForm?: Maybe<Scalars['Boolean']>;
  /** Allow the connected user to send internal notifications */
  sendInternalNotifications?: Maybe<Scalars['Boolean']>;
  /** Send an email to the connected user */
  sendMailToCurrentUser?: Maybe<Scalars['Boolean']>;
  /** Allow the connected user to send emails */
  sendMails?: Maybe<NotificationGroupGraphType>;
  /** Send push notification to project users */
  sendPushNotifications?: Maybe<Scalars['Boolean']>;
};

export type NotificationQueueNotificationArgs = {
  date: Scalars['DateTime'];
  email: Scalars['String'];
  placeholders?: InputMaybe<Array<InputMaybe<PlaceholdersInputType>>>;
  projectId: Scalars['String'];
  subject: Scalars['String'];
  templateId: Scalars['Int'];
};

export type NotificationResendNotificationsArgs = {
  notificationIds: Array<InputMaybe<Scalars['Int']>>;
};

export type NotificationSendContactFormArgs = {
  confirmationEmailPlaceholders?: InputMaybe<Array<InputMaybe<PlaceholdersInputType>>>;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  placeholders?: InputMaybe<Array<InputMaybe<PlaceholdersInputType>>>;
  projectId: Scalars['String'];
  sender: Scalars['String'];
  templateId?: InputMaybe<Scalars['Int']>;
};

export type NotificationSendInternalNotificationsArgs = {
  notificationGroup: InputNotificationGroup;
};

export type NotificationSendMailToCurrentUserArgs = {
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInputType>>>;
  projectId: Scalars['String'];
  sender?: InputMaybe<Scalars['String']>;
  subject: Scalars['String'];
  templateId: Scalars['Int'];
};

export type NotificationSendMailsArgs = {
  notificationGroup: InputNotificationGroup;
  sender?: InputMaybe<Scalars['String']>;
};

export type NotificationSendPushNotificationsArgs = {
  content: Scalars['String'];
  projectId: Scalars['String'];
  title: Scalars['String'];
  topic: Scalars['String'];
};

/** A connection from an object to a list of objects of type `Notification`. */
export type NotificationConnection = {
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<NotificationEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<NotificationGraphType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `Notification`. */
export type NotificationEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<NotificationGraphType>;
};

export type NotificationGraphType = {
  content?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<ErrorMessageGraphType>>>;
  guid?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  metadata?: Maybe<Scalars['String']>;
  notificationGroup?: Maybe<NotificationGroupGraphType>;
  placeholders?: Maybe<Array<Maybe<Placeholder>>>;
  receiver: Scalars['String'];
  remoteId?: Maybe<Scalars['String']>;
  seen?: Maybe<Scalars['Boolean']>;
  sendOn?: Maybe<Scalars['DateTime']>;
  sendingDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<ENotificationStatus>;
  type?: Maybe<Scalars['String']>;
};

export type NotificationGroupGraphType = {
  content?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  internalUsage?: Maybe<Array<Maybe<EInternalNotificationUsage>>>;
  notifications?: Maybe<Array<Maybe<NotificationGraphType>>>;
  smsUsage?: Maybe<Array<Maybe<ESmsUsage>>>;
  subject: Scalars['String'];
  template?: Maybe<TemplateGraphType>;
  templateId?: Maybe<Scalars['Int']>;
  usage?: Maybe<Array<Maybe<EEmailUsage>>>;
};

export type NotificationGroupGraphTypeNotificationsArgs = {
  notificationId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ENotificationStatus>;
};

export type NotificationInputType = {
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInputType>>>;
  metadata?: InputMaybe<Scalars['String']>;
  placeholders?: InputMaybe<Array<InputMaybe<PlaceholdersInputType>>>;
  receiver?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type NotificationQueries = {
  /** Search the notifications based on the connected user */
  get?: Maybe<NotificationGraphType>;
  getForConnectedUser?: Maybe<NotificationConnection>;
  /** Check if the user has a pending notification with the defined parameters */
  getPendingNotification?: Maybe<NotificationGraphType>;
  /** Search the notifications */
  notifications?: Maybe<Array<Maybe<NotificationGraphType>>>;
  /** Get the notifications for the connected user */
  notificationsForConnectedUser?: Maybe<Array<Maybe<NotificationGraphType>>>;
};

export type NotificationQueriesGetArgs = {
  notificationId: Scalars['String'];
};

export type NotificationQueriesGetForConnectedUserArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isSeen?: InputMaybe<Scalars['Boolean']>;
  type: ENotificationType;
};

export type NotificationQueriesGetPendingNotificationArgs = {
  receiver: Scalars['String'];
  templateId: Scalars['Int'];
};

export type NotificationQueriesNotificationsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  notificationGroupId?: InputMaybe<Scalars['Int']>;
  notificationId?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['String'];
  receivers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<ENotificationStatus>;
  type?: InputMaybe<ENotificationType>;
};

export type NotificationQueriesNotificationsForConnectedUserArgs = {
  isSeen?: InputMaybe<Scalars['Boolean']>;
  type: ENotificationType;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Placeholder = {
  id: Scalars['Int'];
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type PlaceholdersInputType = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Project = {
  /** Remove placeholder at the project level */
  addProjectPlaceholder?: Maybe<ProjectPlaceholderGraphType>;
  /** Contains all the mutations about the Project Configurations */
  configurations?: Maybe<PushNotificationConfigurationMutation>;
  /** Define the contact email for the project */
  defineContactEmail?: Maybe<Scalars['Boolean']>;
  enable?: Maybe<ProjectGraphType>;
  /** Add placeholder at the project level */
  removeProjectPlaceholder?: Maybe<Scalars['Boolean']>;
  /** Set a project as active */
  setActive?: Maybe<Scalars['Boolean']>;
  /** Toggle a feature for the project */
  toggleFeature?: Maybe<Scalars['Boolean']>;
  /** Update a project */
  update?: Maybe<ProjectGraphType>;
  verifyEmail?: Maybe<Scalars['Boolean']>;
};

export type ProjectAddProjectPlaceholderArgs = {
  key: Scalars['String'];
  projectId: Scalars['String'];
  value: Scalars['String'];
};

export type ProjectDefineContactEmailArgs = {
  email: Scalars['String'];
  projectId: Scalars['String'];
};

export type ProjectEnableArgs = {
  name: Scalars['String'];
  projectId: Scalars['String'];
};

export type ProjectRemoveProjectPlaceholderArgs = {
  placeholderId: Scalars['Int'];
};

export type ProjectSetActiveArgs = {
  projectId: Scalars['String'];
};

export type ProjectToggleFeatureArgs = {
  feature: EProjectFeature;
  projectId: Scalars['String'];
  toggle: Scalars['Boolean'];
};

export type ProjectUpdateArgs = {
  displayedName?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  projectName?: InputMaybe<Scalars['String']>;
};

export type ProjectVerifyEmailArgs = {
  email: Scalars['String'];
  projectId: Scalars['String'];
};

export type ProjectConfiguration = {
  id: Scalars['Int'];
  projectId: Scalars['String'];
  pushNotificationConfiguration?: Maybe<PushNotificationConfigurationInterface>;
};

export type ProjectGraphType = {
  active: Scalars['Boolean'];
  configuration?: Maybe<ProjectConfiguration>;
  displayedName: Scalars['String'];
  features?: Maybe<Array<Maybe<DictionaryGraphType>>>;
  id: Scalars['String'];
  name: Scalars['String'];
  notificationsGroups?: Maybe<Array<Maybe<NotificationGroupGraphType>>>;
  serverKey: Scalars['String'];
  templates?: Maybe<Array<Maybe<TemplateGraphType>>>;
};

export type ProjectGraphTypeNotificationsGroupsArgs = {
  notificationGroupId?: InputMaybe<Scalars['Int']>;
};

export type ProjectPlaceholderGraphType = {
  id: Scalars['Int'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ProjectQuery = {
  getProject?: Maybe<ProjectGraphType>;
  getTemplate?: Maybe<TemplateGraphType>;
  getTemplates?: Maybe<Array<Maybe<TemplateGraphType>>>;
  /** Check if a project has a specific feature */
  hasFeature?: Maybe<Scalars['Boolean']>;
  search?: Maybe<TemplateConnection>;
};

export type ProjectQueryGetProjectArgs = {
  projectId: Scalars['String'];
};

export type ProjectQueryGetTemplateArgs = {
  id: Scalars['Int'];
};

export type ProjectQueryGetTemplatesArgs = {
  projectId: Scalars['String'];
};

export type ProjectQueryHasFeatureArgs = {
  feature: EProjectFeature;
  projectId: Scalars['String'];
};

export type ProjectQuerySearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['String'];
};

export type PushNotificationConfigurationExpoInputType = {
  default?: InputMaybe<Scalars['String']>;
};

export type PushNotificationConfigurationFirebaseGraphType = PushNotificationConfigurationInterface & {
  id: Scalars['Int'];
  projectConfigurationId: Scalars['Int'];
  senderId: Scalars['String'];
  serverKey: Scalars['String'];
};

export type PushNotificationConfigurationFirebaseInputType = {
  senderId: Scalars['String'];
  serverKey: Scalars['String'];
};

export type PushNotificationConfigurationInterface = {
  id: Scalars['Int'];
  projectConfigurationId: Scalars['Int'];
};

export type PushNotificationConfigurationMutation = {
  /** Create a push notification configuration */
  createPushNotificationConfiguration?: Maybe<PushNotificationConfigurationInterface>;
  /**
   * Create a project configuration
   * @deprecated Not usable
   */
  instanceConfiguration?: Maybe<ProjectConfiguration>;
  /** Update a push notification configuration */
  updatePushNotificationConfiguration?: Maybe<PushNotificationConfigurationInterface>;
};

export type PushNotificationConfigurationMutationCreatePushNotificationConfigurationArgs = {
  projectId: Scalars['String'];
  pushNotificationConfigurationExpo?: InputMaybe<PushNotificationConfigurationExpoInputType>;
  pushNotificationConfigurationFirebase?: InputMaybe<PushNotificationConfigurationFirebaseInputType>;
};

export type PushNotificationConfigurationMutationInstanceConfigurationArgs = {
  projectId: Scalars['String'];
};

export type PushNotificationConfigurationMutationUpdatePushNotificationConfigurationArgs = {
  projectId: Scalars['String'];
  pushNotificationConfigurationExpo?: InputMaybe<PushNotificationConfigurationExpoInputType>;
  pushNotificationConfigurationFirebase?: InputMaybe<PushNotificationConfigurationFirebaseInputType>;
};

export type PushNotificationConfigurationQuery = {
  pushNotificationConfigurationFirebase?: Maybe<PushNotificationConfigurationFirebaseGraphType>;
};

export type PushNotificationConfigurationQueryPushNotificationConfigurationFirebaseArgs = {
  id: Scalars['Int'];
};

export type Queries = {
  /** Contains all the queries about notifications */
  notification?: Maybe<NotificationQueries>;
  /** Contains all the queries about projects */
  project?: Maybe<ProjectQuery>;
  /** Contains all the queries about push notification configurations */
  pushNotificationConfigurations?: Maybe<PushNotificationConfigurationQuery>;
  /** Contains all the queries about reports */
  report?: Maybe<ReportQueries>;
  /** Contains all queries about users */
  user?: Maybe<UserQuery>;
};

export type ReportQueries = {
  /** Export the notification from a specific project */
  exportNotifications?: Maybe<AssetGraphType>;
};

export type ReportQueriesExportNotificationsArgs = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  fileType?: InputMaybe<FileType>;
  ianaTimeZone: Scalars['String'];
  projectId: Scalars['String'];
};

/** A connection from an object to a list of objects of type `Template`. */
export type TemplateConnection = {
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<TemplateEdge>>>;
  /** A list of all of the objects returned in the connection. This is a convenience field provided for quickly exploring the API; rather than querying for "{ edges { node } }" when no edge data is needed, this field can be used instead. Note that when clients like Relay need to fetch the "cursor" field on the edge to enable efficient pagination, this shortcut cannot be used, and the full "{ edges { node } } " version should be used instead. */
  items?: Maybe<Array<Maybe<TemplateGraphType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A count of the total number of objects in this connection, ignoring pagination. This allows a client to fetch the first five objects by passing "5" as the argument to `first`, then fetch the total count so it could display "5 of 83", for example. In cases where we employ infinite scrolling or don't have an exact count of entries, this field will return `null`. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection from an object to another object of type `Template`. */
export type TemplateEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<TemplateGraphType>;
};

export type TemplateGraphType = {
  content: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  smsUsage?: Maybe<Array<Maybe<ESmsUsage>>>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<ETemplateType>;
  usage?: Maybe<Array<Maybe<EEmailUsage>>>;
};

export type TemplateInput = {
  content: Scalars['String'];
  messageType: EMessageType;
  name: Scalars['String'];
  projectId: Scalars['String'];
  subject: Scalars['String'];
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: ETemplateType;
};

export type TemplateMutation = {
  /** Set an usage to an existing template */
  addOrUpdateSmsTemplateUsage?: Maybe<Scalars['Boolean']>;
  /** Set an usage to an existing template */
  addOrUpdateTemplateUsage?: Maybe<Scalars['Boolean']>;
  /** Add a template to the selected project */
  addTemplate?: Maybe<TemplateGraphType>;
  /** Delete a template */
  delete?: Maybe<Scalars['Boolean']>;
  /** Edit an existing template */
  editTemplate?: Maybe<Scalars['Boolean']>;
  removeUsage?: Maybe<Scalars['Boolean']>;
};

export type TemplateMutationAddOrUpdateSmsTemplateUsageArgs = {
  request: AddOrUpdateTemplateUsageSmsInputType;
};

export type TemplateMutationAddOrUpdateTemplateUsageArgs = {
  request: AddOrUpdateTemplateUsageInputType;
};

export type TemplateMutationAddTemplateArgs = {
  emailUsage?: InputMaybe<EEmailUsage>;
  smsUsage?: InputMaybe<ESmsUsage>;
  template: TemplateInput;
};

export type TemplateMutationDeleteArgs = {
  templateId: Scalars['Int'];
};

export type TemplateMutationEditTemplateArgs = {
  emailUsage?: InputMaybe<EEmailUsage>;
  smsUsage?: InputMaybe<ESmsUsage>;
  template?: InputMaybe<TemplateInput>;
  templateId: Scalars['Int'];
};

export type TemplateMutationRemoveUsageArgs = {
  templateId: Scalars['Int'];
  usage?: InputMaybe<EEmailUsage>;
};

export type UserGraphType = {
  email: Scalars['String'];
  firstname?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastname?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  settings?: Maybe<Array<Maybe<EUserNotificationPreference>>>;
};

export type UserMutation = {
  /** Add device */
  addDevice?: Maybe<Scalars['Boolean']>;
  /** Enable account of the user */
  enableAccount?: Maybe<Scalars['Boolean']>;
  /** Set black list */
  setBlacklist?: Maybe<Scalars['Boolean']>;
  /** Unsubscribe to certain type of notification */
  unsubscribe?: Maybe<Scalars['Boolean']>;
  /** Update settings */
  updateSettings?: Maybe<UserGraphType>;
};

export type UserMutationAddDeviceArgs = {
  token: Scalars['String'];
};

export type UserMutationEnableAccountArgs = {
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  phoneNumber: Scalars['String'];
  projectId: Scalars['String'];
};

export type UserMutationSetBlacklistArgs = {
  notificationGuid: Scalars['String'];
  projectName: Scalars['String'];
  receiver: Scalars['String'];
};

export type UserMutationUnsubscribeArgs = {
  messageTypes: Array<InputMaybe<EMessageType>>;
  notificationType: ENotificationType;
};

export type UserMutationUpdateSettingsArgs = {
  inputType: Array<InputMaybe<EUserNotificationPreference>>;
};

export type UserQuery = {
  /** get user info */
  getUser?: Maybe<UserGraphType>;
};

export type AddDeviceMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type AddDeviceMutation = { user?: { addDevice?: boolean } };

export type MarkANotificationSeenMutationVariables = Exact<{
  notificationGuid: Scalars['String'];
  ipAddress: Scalars['String'];
  userAgent: Scalars['String'];
}>;

export type MarkANotificationSeenMutation = { internalNotificationEvent?: { addSeenEvent?: boolean } };

export type MarkAllNotificationSeenMutationVariables = Exact<{
  ipAddress: Scalars['String'];
  userAgent: Scalars['String'];
}>;

export type MarkAllNotificationSeenMutation = { internalNotificationEvent?: { markAllSeen?: boolean } };

export type GetNotificationsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isSeen?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetNotificationsQuery = {
  notification?: {
    getForConnectedUser?: {
      totalCount?: number;
      edges?: Array<{
        cursor: string;
        node?: {
          guid?: string;
          id: number;
          content?: string;
          metadata?: string;
          seen?: boolean;
          sendingDate?: Date;
          sendOn?: Date;
          status?: ENotificationStatus;
          type?: string;
        };
      }>;
    };
  };
};

export const AddDeviceDocument = gql`
  mutation addDevice($token: String!) {
    user {
      addDevice(token: $token)
    }
  }
`;
export type AddDeviceMutationFn = Apollo.MutationFunction<AddDeviceMutation, AddDeviceMutationVariables>;

/**
 * __useAddDeviceMutation__
 *
 * To run a mutation, you first call `useAddDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDeviceMutation, { data, loading, error }] = useAddDeviceMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAddDeviceMutation(baseOptions?: Apollo.MutationHookOptions<AddDeviceMutation, AddDeviceMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddDeviceMutation, AddDeviceMutationVariables>(AddDeviceDocument, options);
}
export type AddDeviceMutationHookResult = ReturnType<typeof useAddDeviceMutation>;
export type AddDeviceMutationResult = Apollo.MutationResult<AddDeviceMutation>;
export type AddDeviceMutationOptions = Apollo.BaseMutationOptions<AddDeviceMutation, AddDeviceMutationVariables>;
export const MarkANotificationSeenDocument = gql`
  mutation markANotificationSeen($notificationGuid: String!, $ipAddress: String!, $userAgent: String!) {
    internalNotificationEvent {
      addSeenEvent(notificationGuid: $notificationGuid, ipAddress: $ipAddress, userAgent: $userAgent)
    }
  }
`;
export type MarkANotificationSeenMutationFn = Apollo.MutationFunction<
  MarkANotificationSeenMutation,
  MarkANotificationSeenMutationVariables
>;

/**
 * __useMarkANotificationSeenMutation__
 *
 * To run a mutation, you first call `useMarkANotificationSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkANotificationSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markANotificationSeenMutation, { data, loading, error }] = useMarkANotificationSeenMutation({
 *   variables: {
 *      notificationGuid: // value for 'notificationGuid'
 *      ipAddress: // value for 'ipAddress'
 *      userAgent: // value for 'userAgent'
 *   },
 * });
 */
export function useMarkANotificationSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkANotificationSeenMutation, MarkANotificationSeenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkANotificationSeenMutation, MarkANotificationSeenMutationVariables>(MarkANotificationSeenDocument, options);
}
export type MarkANotificationSeenMutationHookResult = ReturnType<typeof useMarkANotificationSeenMutation>;
export type MarkANotificationSeenMutationResult = Apollo.MutationResult<MarkANotificationSeenMutation>;
export type MarkANotificationSeenMutationOptions = Apollo.BaseMutationOptions<
  MarkANotificationSeenMutation,
  MarkANotificationSeenMutationVariables
>;
export const MarkAllNotificationSeenDocument = gql`
  mutation markAllNotificationSeen($ipAddress: String!, $userAgent: String!) {
    internalNotificationEvent {
      markAllSeen(ipAddress: $ipAddress, userAgent: $userAgent)
    }
  }
`;
export type MarkAllNotificationSeenMutationFn = Apollo.MutationFunction<
  MarkAllNotificationSeenMutation,
  MarkAllNotificationSeenMutationVariables
>;

/**
 * __useMarkAllNotificationSeenMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationSeenMutation, { data, loading, error }] = useMarkAllNotificationSeenMutation({
 *   variables: {
 *      ipAddress: // value for 'ipAddress'
 *      userAgent: // value for 'userAgent'
 *   },
 * });
 */
export function useMarkAllNotificationSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkAllNotificationSeenMutation, MarkAllNotificationSeenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkAllNotificationSeenMutation, MarkAllNotificationSeenMutationVariables>(
    MarkAllNotificationSeenDocument,
    options
  );
}
export type MarkAllNotificationSeenMutationHookResult = ReturnType<typeof useMarkAllNotificationSeenMutation>;
export type MarkAllNotificationSeenMutationResult = Apollo.MutationResult<MarkAllNotificationSeenMutation>;
export type MarkAllNotificationSeenMutationOptions = Apollo.BaseMutationOptions<
  MarkAllNotificationSeenMutation,
  MarkAllNotificationSeenMutationVariables
>;
export const GetNotificationsDocument = gql`
  query getNotifications($after: String, $first: Int, $isSeen: Boolean) {
    notification {
      getForConnectedUser(after: $after, first: $first, type: INTERNAL_NOTIFICATION, isSeen: $isSeen) {
        totalCount
        edges {
          cursor
          node {
            guid
            id
            content
            metadata
            seen
            sendingDate
            sendOn
            status
            type
          }
        }
      }
    }
  }
`;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      isSeen: // value for 'isSeen'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
}
export function useGetNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
}
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
