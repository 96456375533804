import { IJoinCommunityOptions } from './useJoinCommunity.interface';

import { bonoboClient } from '../../apollo';
import { useJoinCommunityMutation } from '../../bonobo/generated.graphql';

export const useJoinCommunity = (options?: IJoinCommunityOptions) => {
  const { onCompleted, onError } = options ?? ({} as IJoinCommunityOptions);

  const [joinCommunity, { loading: isLoading, error }] = useJoinCommunityMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { joinCommunity, isLoading, error };
};
