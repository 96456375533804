import { gql } from '@apollo/client';

import { IDBAssetGraphTypeFragment } from './asset.fragment';

const IDBUserDetailsGraphTypeFragment = gql`
  fragment userDetails on UserGraphType {
    about
    activeCommunities {
      totalCount
      edges {
        node {
          id
          title
        }
      }
    }
    connections {
      totalCount
    }
    coverImage {
      ...asset
    }
    displayedName
    email
    firstName
    id
    isAnonymous
    isOnline
    isTermsAndConditionsAccepted
    key
    lastName
    profileImage {
      ...asset
    }
    settings
    userConnection {
      connectionStatus
    }
    username
  }
  ${IDBAssetGraphTypeFragment}
`;

export { IDBUserDetailsGraphTypeFragment };
