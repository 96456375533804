import { IGetUserByKeyOption } from './useGetUserByKey.interface';

import { DBUser } from '../../../interfaces';
import { bonoboClient } from '../../apollo';
import { useGetUserByKeyLazyQuery } from '../../bonobo/generated.graphql';

export const useGetUserByKey = (options?: IGetUserByKeyOption) => {
  const { onCompleted, onError } = options ?? ({} as IGetUserByKeyOption);

  const [getUserByKey, { loading: isLoading, error, refetch }] = useGetUserByKeyLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data.user?.get as DBUser);
    },
    onError,
  });

  return { getUserByKey, isLoading, error, refetch };
};
