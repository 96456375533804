import { gql } from '@apollo/client';

const UPDATE_USER_FORUM_NAME_MUTATION = gql`
  mutation updateUserForumName($forumName: String!) {
    user {
      updateUserForumName(forumName: $forumName)
    }
  }
`;

export { UPDATE_USER_FORUM_NAME_MUTATION };
