import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { getValidClassNames } from 'utils';

const CommunityMenu = () => {
  const {
    query: { communityKey },
    asPath,
  } = useRouter();

  const BASE_URL = `/social/communities/${String(communityKey)}`;

  return (
    <nav aria-label="Community navigation" className="c-community-menu">
      <ul className="c-community-menu__list">
        <li className="c-community-menu__item">
          <NextLink href={`${BASE_URL}`} legacyBehavior>
            <a
              className={getValidClassNames({
                'c-community-menu__link': true,
                'c-community-menu__link--active': asPath === `${BASE_URL}`,
              })}
            >
              Community feed
            </a>
          </NextLink>
        </li>
        <li className="c-community-menu__item">
          <NextLink href={`${BASE_URL}/members`} legacyBehavior>
            <a
              className={getValidClassNames({
                'c-community-menu__link': true,
                'c-community-menu__link--active': asPath === `${BASE_URL}/members`,
              })}
            >
              Members
            </a>
          </NextLink>
        </li>
        <li className="c-community-menu__item">
          <NextLink href={`${BASE_URL}/hashtags`} legacyBehavior>
            <a
              className={getValidClassNames({
                'c-community-menu__link': true,
                'c-community-menu__link--active': asPath === `${BASE_URL}/hashtags`,
              })}
            >
              Hashtags
            </a>
          </NextLink>
        </li>
      </ul>
    </nav>
  );
};

export { CommunityMenu };
