import { gql } from '@apollo/client';

const UserGraphTypeFragment = gql`
  fragment user on UserGraphType {
    displayedName
    email
    firstName
    id
    isAnonymous
    isTermsAndConditionsAccepted
    lastName
    username
  }
`;

export { UserGraphTypeFragment };
