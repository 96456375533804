import { ChangeEvent, useEffect, useState } from 'react';

import { IUseSearchInput } from './SearchInput.types';

const useSearchInput = (): IUseSearchInput => {
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const [searchValue, setSearchvalue] = useState<string>('');

  const onSearchClear = () => {
    setIsSearchActive(false);
    setSearchvalue('');
  };

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSearchvalue(value);
    setIsSearchActive(false);
  };

  const onSearchSubmit = (value: string) => {
    setSearchvalue(value);
    setIsSearchActive(true);
  };

  useEffect(() => {
    if (!searchValue) {
      setIsSearchActive(false);
    }
  }, [searchValue]);

  return {
    isSearchActive,
    onSearchChange,
    onSearchClear,
    onSearchSubmit,
    searchValue,
  };
};

export { useSearchInput };
