import { useEffect } from 'react';

import { useToggle } from '@netfront/common-library';
import { useDomain, useLogout } from '@netfront/gelada-identity-library';
import cx from 'classnames';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { PrivateHeaderProps } from './PrivateHeader.interfaces';

import { Button, MenuDropdown, MobileMenuTrigger, PrivateMobileMenu, PRIVATE_MENU, UserInitials } from '../../../components';
import { useToast } from '../../../hooks';

const PrivateHeader = ({ spotLightData }: PrivateHeaderProps) => {
  const { pathname, push } = useRouter();
  const { handleToastError } = useToast();

  const { getDomain, isDomainReady } = useDomain();
  const { handleLogout } = useLogout({
    onCompleted: () => {
      push('/').catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );
    },
  });

  const { isToggled: isMobileMenuOpen, toggle: toggleMobileMenu } = useToggle();
  const { isToggled: isUserMenuOpen, toggle: toggleUserMenu } = useToggle();

  useEffect(() => {
    if (!spotLightData) {
      return;
    }

    const {
      action,
      step: { title: spotLightStepTitle },
    } = spotLightData;

    if (action === 'prev' && spotLightStepTitle === 'Resources') {
      toggleUserMenu();
    }

    if (
      (action === 'prev' && spotLightStepTitle === 'Profile menu') ||
      !(spotLightStepTitle === 'Profile menu' || spotLightStepTitle === 'Crisis support')
    ) {
      return;
    }

    if (!(spotLightStepTitle === 'Profile menu' || spotLightStepTitle === 'Crisis support')) {
      return;
    }

    toggleUserMenu();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spotLightData?.step.title]);

  return (
    <header className="index-2 relative shadow-lg no-print">
      <div className="bg-white index-2 py-4 relative xl:py-0">
        <div className="items-center container-xl flex">

          <MobileMenuTrigger isOpen={isMobileMenuOpen} onPress={toggleMobileMenu} />

          <nav className="none flex-1 xl:flex justify-center">
            <ul className="c-header-list items-center flex">
              {PRIVATE_MENU.map(({ href, id, label }) => {
                const buttonClasses = `block color-primary font-header border-y-4 hover:border-b-primary transition-100 pointer px-14 py-8 ${
                  pathname === href ? 'border-transparent border-b-primary' : 'border-transparent'
                }`;

                return (
                  <li key={id} className={cx('mb-0 ml-0', `c-header-list__item-${label.toLowerCase()}`)}>
                    <a className={buttonClasses} href={href}>
                      {label}
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>

          <div>
            <div className="c-profile-menu ml-4 relative">
              <Button aria-expanded={isUserMenuOpen} className="none xl:flex pointer p-0" aria-haspopup onPress={toggleUserMenu}>
                <UserInitials />
              </Button>

              {isUserMenuOpen && (
                <MenuDropdown
                  className="absolute bg-white border border-grey-100 overflow-hidden radius right-0 shadow-lg w-auto"
                  isSpotlightTourCompleted={spotLightData?.action === 'reset' || !spotLightData}
                  onOpenChange={toggleUserMenu}
                >
                  <ul className="c-profile-list ml-0">
                    <li className="c-profile-list-item__profile ml-0 mb-0">
                      <NextLink className="block color-primary hover:bg-grey-50 pointer p-4 px-8" href="/user/profile">
                        Profile
                      </NextLink>
                    </li>
                    <li className="c-profile-list-item__logout ml-0 mb-0">
                      <Button
                        className="color-primary hover:bg-grey-50 pointer p-4 px-8"
                        isDisabled={!isDomainReady}
                        onPress={() => handleLogout({ domain: getDomain(), path: '/' })}
                      >
                        Logout
                      </Button>
                    </li>
                  </ul>
                </MenuDropdown>
              )}
            </div>
          </div>
        </div>
      </div>

      {isMobileMenuOpen && <PrivateMobileMenu />}
    </header>
  );
};

export { PrivateHeader };
