import { gql } from '@apollo/client';

const GET_LOGGED_USER_QUERY = gql`
  query getLoggedUser {
    user {
      getLoggedUser {
        email
      }
    }
  }
`;

export { GET_LOGGED_USER_QUERY };
