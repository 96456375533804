import { PostFeelingProps } from './PostFeeling.interfaces';

import { ALL_POST_FEELINGS } from '../../../components/Social';
import { EPostFeeling } from '../../../services';

export const PostFeeling = ({ feeling }: PostFeelingProps) =>
  feeling && feeling !== EPostFeeling.None ? (
    <div className="c-post-feeling">
      <p>
        Feeling - {ALL_POST_FEELINGS.find(({ type }) => type === feeling)?.emoji} <strong>{feeling.toLowerCase()}</strong>
      </p>
    </div>
  ) : null;
