import { UserConnectionQueryResult, IGetUserConnectionsOption } from './useGetUserConnections.interface';

import { bonoboClient } from '../../apollo';
import { useGetUserConnectionsLazyQuery } from '../../bonobo/generated.graphql';

export const useGetUserConnections = (options?: IGetUserConnectionsOption) => {
  const { onCompleted, onError } = options ?? ({} as IGetUserConnectionsOption);

  const [getConnections, { loading: isLoading, error, refetch }] = useGetUserConnectionsLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      const connections = data.userConnection?.getUserConnections;
      onCompleted(connections?.edges as UserConnectionQueryResult[], connections?.totalCount ?? 0);
    },
    onError,
  });

  return { getConnections, isLoading, error, refetch };
};
