import { useState } from 'react';

import cx from 'classnames';

import { Button } from '../Button';
import { CaretDownIcon } from '../Icons';

import { HoverLinkProps } from './HoverLink.interfaces';

const HoverLink = ({ label, onClick }: HoverLinkProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Button
      className="items-center cursor flex focus:bg-grey-50 hover:bg-grey-50 p-4 radius text-left w-full"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onPress={onClick}
    >
      <span className="color-blue-900 h6 mb-0 mr-2 weight-700">{label}</span>
      <div
        className={cx(
          'items-center bg-accent color-white flex justify-center ml-auto rounded-full rotate-270 transition-100',
          isHovered ? 'opacity-1' : 'opacity-0',
        )}
      >
        <CaretDownIcon />
      </div>
    </Button>
  );
};

export { HoverLink };
