import { ISetIsOnlineOptions } from './useSetIsOnline.interface';

import { bonoboClient } from '../../apollo';
import { useSetIsOnlineMutation } from '../../bonobo/generated.graphql';

export const useSetIsOnline = (options?: ISetIsOnlineOptions) => {
  const { onCompleted, onError } = options ?? ({} as ISetIsOnlineOptions);

  const [updateIsOnline, { loading: isSetIsOnlineLoading, error }] = useSetIsOnlineMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { updateIsOnline, isSetIsOnlineLoading, error };
};
