import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { IApolloLinkOptions } from '@netfront/common-library';

export const getApolloLink = ({ apiName, isPublic = false, product, projectId, token = '' }: IApolloLinkOptions): ApolloLink => {
  return setContext((_, { headers }) => {
    const authorization = isPublic ? undefined : token;
    const actualProjectId = projectId ?? process.env.REACT_APP_PROJECT_ID;

    const updatedHeaders = {
      headers: {
        ...headers,
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Origin': '*',
        custom_build_guid: apiName === 'gelada' && actualProjectId ? actualProjectId : '',
        product: product ?? '',
        project_id: apiName === 'gelada' || apiName === 'bonobo' ? actualProjectId : '',
      },
    };

    if (authorization) {
      return {
        ...updatedHeaders,
        headers: {
          ...updatedHeaders.headers,
          authorization,
        },
      };
    }

    return updatedHeaders;
  });
};
