import { IDeleteCommentRelateOptions } from './useDeleteCommentRelate.interface';

import { bonoboClient } from '../../apollo';
import { useDeleteCommentRelateMutation } from '../../bonobo/generated.graphql';

export const useDeleteCommentRelate = (options?: IDeleteCommentRelateOptions) => {
  const { onCompleted, onError } = options ?? ({} as IDeleteCommentRelateOptions);

  const [deleteCommentRelate, { loading: isDeleteCommentRelateLoading, error }] = useDeleteCommentRelateMutation({
    client: bonoboClient,
    onCompleted: () => {
      if (!onCompleted) {
        return;
      }
      void (async () => onCompleted())();
    },
    onError,
  });

  return { deleteCommentRelate, isDeleteCommentRelateLoading, error };
};
