import { useState } from 'react';

import { Avatar, Button } from '@netfront/ui-library';

import { CommentBox } from '../CommentBox';
import { IconLike } from '../Icons';
import { IUpdateRelateParams } from '../Post';
import { ReactionToolbar, useReactionToolbar } from '../ReactionToolbar';

import { CommentProps } from './Comment.interfaces';

import { CommentConnection, CommentGraphType, ERelateType } from '../../../services';
import { formatDate, formatTime, getValidClassNames } from '../../../utils';

const Comment = ({
  additionalClassNames,
  avatar,
  shouldHideReplyButton,
  comments,
  date,
  displayName,
  hasRelated: hasRelatedProp = false,
  message,
  id,
  onCommentRelate,
  onCommentReply,
}: CommentProps) => {
  const { isReactionToolbarOpen, closeReactToolBar, openReactToolBar } = useReactionToolbar();

  const [hasRelated, setHasRelated] = useState<boolean>(hasRelatedProp);
  const [isRelpyVisible, setIsReplyVisible] = useState<boolean>(false);
  const [reply, setReply] = useState<string>('');

  const onRelateClick = ({ action, hasRelated: hasRelatedToComment, id: commentId, relateType }: IUpdateRelateParams) => {
    if (onCommentRelate) {
      onCommentRelate({
        action,
        hasRelated: hasRelatedToComment,
        id: commentId,
        relateType,
      });
    }
    setHasRelated(hasRelatedToComment);
  };

  const onReplyChange = (value: string) => {
    setReply(value);
  };

  const onReplyClick = () => {
    if (onCommentReply) {
      onCommentReply(id, reply);
    }

    setIsReplyVisible(false);
    setReply('');
  };

  const toggleReply = () => setIsReplyVisible(!isRelpyVisible);

  return (
    <div
      className={getValidClassNames({
        'c-comment': true,
        [String(additionalClassNames)]: Boolean(additionalClassNames),
      })}
    >
      <div className="h-flex">
        <div className="c-comment__avatar">
          {avatar ? (
            <Avatar image={<img alt={displayName} src={avatar} />} size="small" title={displayName} />
          ) : (
            <Avatar size="small" title={displayName} />
          )}
        </div>

        <div className="c-comment__content">
          <span className="c-comment__display-name">{displayName}</span>

          <span className="c-comment__date">{date && ` - ${formatDate(date)} ${formatTime(date)}`}</span>

          <div className="c-comment__message">
            <p>{message}</p>
          </div>

          <div className="h-flex">
            <ReactionToolbar
              isReactionToolbarOpen={isReactionToolbarOpen}
              onMouseEnter={openReactToolBar}
              onMouseLeave={closeReactToolBar}
              onRelateClick={(relateType) => {
                onRelateClick({
                  action: hasRelated ? 'CHANGE' : 'ADD',
                  hasRelated: true,
                  id,
                  relateType,
                });

                closeReactToolBar();
              }}
            >
              <button
                className="c-comment__button"
                onClick={() => {
                  onRelateClick({
                    action: hasRelated ? 'REMOVE' : 'ADD',
                    hasRelated: !hasRelated,
                    id,
                    relateType: ERelateType.Like,
                  });
                }}
              >
                <IconLike additionalClassNames="c-comment__icon" isActive={hasRelated} />
                {hasRelated ? 'Related' : 'Relate'}
              </button>
            </ReactionToolbar>

            {!shouldHideReplyButton && (
              <button className="c-comment__button" onClick={toggleReply}>
                Reply {Boolean(comments?.length) && `(${Number(comments?.length)})`}
              </button>
            )}
          </div>

          {isRelpyVisible && (
            <form className="c-comment__reply">
              <CommentBox
                id="reply"
                labelText="Reply"
                name="reply"
                placeholder={`Reply to ${displayName}...`}
                value={reply}
                isLabelHidden
                onChange={onReplyChange}
              />

              <Button isDisabled={Boolean(!reply)} size="xs" text="Reply" onClick={onReplyClick} />
            </form>
          )}
        </div>
      </div>
      {comments &&
        comments.map(
          (
            {
              author,
              comments: subComments,
              createdDateTime,
              hasRelated: hasCommentRelated,
              message: subMessage,
              id: commentId,
            }: CommentGraphType,
            key,
          ) => {
            const { edges } = subComments ?? ({} as CommentConnection);
            const replies = edges?.map(({ node }) => node);

            return (
              <Comment
                key={key}
                additionalClassNames="c-comment--sub"
                avatar={author?.profileImage?.presignedUrl}
                comments={replies}
                date={createdDateTime}
                displayName={String(author?.displayedName)}
                hasRelated={hasCommentRelated}
                id={commentId}
                message={subMessage}
                shouldHideReplyButton
                onCommentRelate={onCommentRelate}
                onCommentReply={onCommentReply}
              />
            );
          },
        )}
    </div>
  );
};

export { Comment };
