import { ICreatePostOptions } from './useCreatePost.interface';

import { bonoboClient } from '../../apollo';
import { useCreatePostMutation } from '../../bonobo/generated.graphql';

export const useCreatePost = (options?: ICreatePostOptions) => {
  const { onCompleted, onError } = options ?? ({} as ICreatePostOptions);

  const [createPostMutation, { loading: isLoading, error }] = useCreatePostMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { createPostMutation, isLoading, error };
};
