import { Skeleton } from '@netfront/ui-library';

const ListCardSkeleton = () => {
  return (
    <div className="c-list-card">
      <Skeleton additionalClassNames="c-list-card__avatar--skeleton" />
      <div>
        <span className="c-list-card__display-name">
          <Skeleton additionalClassNames="c-list-card__display-name--skeleton" />
        </span>
        <span className="c-list-card__description">
          <Skeleton additionalClassNames="c-list-card__description--skeleton" />
        </span>
      </div>
    </div>
  );
};

export { ListCardSkeleton };
