import { IGetCommunityConnectionStatusOptions } from './useGetCommunityConnectionStatus.interface';

import { DBCommunityConnection } from '../../../interfaces';
import { bonoboClient } from '../../apollo';
import { useGetCommunityConnectionStatusLazyQuery } from '../../bonobo';

export const useGetCommunityConnectionStatus = (options?: IGetCommunityConnectionStatusOptions) => {
  const { onCompleted, onError } = options ?? ({} as IGetCommunityConnectionStatusOptions);

  const [getCommunityConnectionStatus, { loading: isLoading, refetch }] = useGetCommunityConnectionStatusLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data.communityConnection?.connectionStatus as DBCommunityConnection);
    },
    onError,
  });

  return { getCommunityConnectionStatus, isLoading, refetch };
};
