import { DialogProps } from '@netfront/ui-library';
import { EPostFeeling } from 'services';

export interface FeelingsItemType {
  emoji: string;
  name: string;
  type: EPostFeeling;
}

export enum EFeelingType {
  All = 'All',
  Positive = 'Positive',
  Neutral = 'Neutral',
  Negative = 'Negative',
}

export interface PostFeelingsDialogProps {
  isOpen: DialogProps['isOpen'];
  onClose: DialogProps['onClose'];
  onFeelingChange: (feeling: EPostFeeling) => void;
  selectedFeeling?: EPostFeeling;
}
