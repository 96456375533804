import { IMarkWholeConversationReadOptions } from './useMarkWholeConversationRead.interface';

import { bonoboClient } from '../../apollo';
import { useMarkWholeConversationReadMutation } from '../../bonobo/generated.graphql';

export const useMarkWholeConversationRead = (options?: IMarkWholeConversationReadOptions) => {
  const { onCompleted, onError } = options ?? ({} as IMarkWholeConversationReadOptions);

  const [markWholeConversationReadMutation, { loading: isLoading, error }] = useMarkWholeConversationReadMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      void (async () => onCompleted(data))();
    },
    onError,
  });

  return { markWholeConversationReadMutation, isLoading, error };
};
