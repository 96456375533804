import { useEffect, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { ToggleSwitch } from '@netfront/ui-library';
import { INotification } from 'contexts';
import { format, getYear } from 'date-fns';
import { DBMetadata } from 'interfaces';
import { useInView } from 'react-intersection-observer';
import { ERelateType, useGetNotifications } from 'services';

import {
  EmptyMessage,
  getNotificationLink,
  ListCardSkeleton,
  LoadMore,
  QUERY_FIRST_NOTIFICATIONS_COUNT,
  NotificationItem,
  SearchInput,
  useSearchInput,
} from 'components/Social';

import { BaseLayoutPage } from '../BaseLayoutPage';
import { NotificationConnection, NotificationGraphType } from 'services/howler';

const NotificationsPage = () => {
  const { isSearchActive, onSearchClear, onSearchSubmit } = useSearchInput();
  const { isToggled: isShowUnreadChecked, toggle: toggleIsShowUnreadChecked } = useToggle();
  const { ref: loadMoreRef, inView: shouldFetchMore } = useInView();

  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [totalNotificationsCount, setTotalNotificationsCount] = useState<number>(0);

  const handleGetNotificationsCompleted = (returnedNotifications: NotificationConnection) => {
    setNotifications(
      returnedNotifications.edges?.map(({ cursor, node }) => ({ cursor, ...(node as NotificationGraphType) })) as INotification[],
    );
    setTotalNotificationsCount(Number(returnedNotifications.totalCount));
  };

  const {
    getNotifications,
    isLoading: isLoadingNotifications,
    fetchMore: fetchMoreNotifications,
  } = useGetNotifications({
    onCompleted: handleGetNotificationsCompleted,
  });

  useEffect(() => {
    getNotifications({
      variables: {
        first: QUERY_FIRST_NOTIFICATIONS_COUNT,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!shouldFetchMore || totalNotificationsCount === notifications.length) {
      return;
    }

    fetchMoreNotifications({
      variables: {
        after: notifications[notifications.length - 1].cursor,
      },
    }).then(({ data }) => {
      setNotifications([
        ...notifications,
        ...(data.notification?.getForConnectedUser?.edges?.map(({ cursor, node }) => ({ cursor, ...node })) as INotification[]),
      ]);
    });
  }, [fetchMoreNotifications, notifications, shouldFetchMore, totalNotificationsCount]);

  const notificationsToRender = isShowUnreadChecked ? notifications.filter(({ seen }) => !seen) : notifications;

  const hasNoNotifications = notifications.length === 0 && !isLoadingNotifications;

  const groupedNotifications = notificationsToRender.reduce((group: INotification, notification) => {
    const year = String(getYear(new Date(notification.sendingDate as Date)));
    const month = format(new Date(notification.sendingDate as Date), 'LLLL');
    const day = `${new Date(notification.sendingDate as Date).toDateString().split(' ')[2]} ${new Date(
      notification.sendingDate as Date,
    ).toLocaleDateString('en-us', {
      weekday: 'long',
    })} ${month}`;

    if (!group[year]) {
      group[year] = [];
    }

    if (!group[year][month]) {
      group[year][month] = [];
    }

    if (!group[year][month][day]) {
      group[year][month][day] = [];
    }

    const dayItems = group[year][month][day] as INotification[];
    dayItems.push(notification);

    return group;
  }, {} as INotification);

  return (
    <BaseLayoutPage
      breadcrumbItems={[{ label: 'Notifications' }]}
      meta={{ seoDescription: `View all your notifications`, seoTitle: `Notifications` }}
      title="Notifications"
    >
      <div className="c-notifications-page">
        <div className="c-container c-container--sm">
          <div className="c-notifications-page__title">
            <h1>Notifications</h1>

            <ToggleSwitch
              additionalClassNames="c-notifications-page__toggle"
              id="unread"
              isChecked={isShowUnreadChecked}
              labelText="Show unread"
              onChange={() => toggleIsShowUnreadChecked()}
            />
          </div>

          <SearchInput
            id="search"
            isSearchActive={isSearchActive}
            labelText="Search notifications"
            name="search"
            placeholder="Search notifications"
            type="text"
            isLabelHidden
            onClear={onSearchClear}
            onSearch={onSearchSubmit}
          />

          {Object.keys(groupedNotifications).reverse().map((year) => {
            const months = groupedNotifications[year];

            return (
              <div key={year}>
                <h2>{year}</h2>
                {Object.keys(months).map((month) => {
                  const days = groupedNotifications[year][month];

                  return (
                    <div key={month} className="c-notifications-page__section">
                      <h3>{month}</h3>
                      {Object.keys(days).map((day) => {
                        const items = days[day] as INotification[];
                        return (
                          <>
                            <h4>{day}</h4>

                            {items.map(({ id, seen, metadata, sendingDate, guid, content }) => {
                              let relateType;
                              let parsedMetaData;

                              if (metadata) {
                                parsedMetaData = JSON.parse(String(metadata)) as DBMetadata;
                                relateType = parsedMetaData.relateType && ERelateType[parsedMetaData.relateType];
                              }

                              const regex = /Hi,\s+\w+\s+/;
                              const description = content?.replace(regex, '')

                              return (
                                <NotificationItem
                                  key={id}
                                  date={new Date(sendingDate as Date).toDateString()}
                                  description={description}
                                  displayName={parsedMetaData ? parsedMetaData.displayedName : ''}
                                  href={parsedMetaData ? getNotificationLink(parsedMetaData.type, parsedMetaData.postId, guid) : ''}
                                  isRead={seen}
                                  relateType={relateType}
                                />
                              );
                            })}
                          </>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}

          {!isLoadingNotifications &&
            Boolean(notificationsToRender.length) &&
            notificationsToRender.length >= QUERY_FIRST_NOTIFICATIONS_COUNT && (
            <LoadMore
              ref={loadMoreRef}
              fetchText="Loading more"
              finishedText="You're all caught up!"
              hasReachedTotal={totalNotificationsCount === notifications.length}
            />
          )}

          {isLoadingNotifications && (
            <>
              <ListCardSkeleton />
              <ListCardSkeleton />
            </>
          )}

          {hasNoNotifications && <EmptyMessage message="No notifications found" />}
        </div>
      </div>
    </BaseLayoutPage>
  );
};

export { NotificationsPage };
