import { RadioButtonGroup } from '@netfront/ui-library';

import { RadioTabsProps } from './RadioTabs.interfaces';

import { getValidClassNames } from '../../../utils';

const RadioTabs = ({ additionalClassNames, ...rest }: RadioTabsProps) => {
  return (
    <RadioButtonGroup
      additionalClassNames={getValidClassNames({
        'c-radio-tabs': true,
        [String(additionalClassNames)]: Boolean(additionalClassNames),
      })}
      {...rest}
    />
  );
};

export { RadioTabs };
