import React, { useEffect, useState } from 'react';

import { useUser } from '@netfront/gelada-identity-library';
import { Dialog } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { IConnection } from './ConnectionsPage.interfaces';

import { INotification, useSocialContext } from '../../../../contexts';
import { DBUserConnection } from '../../../../interfaces';
import {
  EConnectionStatus,
  useAcceptOrDeclineUserConnection,
  AcceptOrDeclineUserConnectionMutation,
  useGetUserConnections,
  UserConnectionQueryResult,
  useMarkANotificationSeen,
} from '../../../../services';
import {
  BaseLayoutPage,
  ListCard,
  EmptyMessage,
  SearchInput,
  AcceptOrDecline,
  useSearchInput,
  ListCardSkeleton,
  CONNECTIONS_REQUEST_AMOUNT,
  LoadMore,
} from '../../../Social';

const ConnectionsPage = () => {
  const {
    query: { notification },
  } = useRouter();
  const { isSearchActive, onSearchClear, onSearchSubmit, searchValue } = useSearchInput();

  const { notifications, updateNotifications } = useSocialContext();

  const user = useUser().getUser();

  const [connections, setConnections] = useState<IConnection[]>([]);
  const [isAcceptDialogOpen, setIsAcceptDialogOpen] = useState<boolean>(false);
  const [isDeclineDialogOpen, setIsDeclineDialogOpen] = useState<boolean>(false);
  const [totalConnectionsCount, setTotalConnectionsCount] = useState<number>(0);

  const openAcceptDialog = () => setIsAcceptDialogOpen(true);
  const closeAcceptDialog = () => setIsAcceptDialogOpen(false);

  const openDeclineDialog = () => setIsDeclineDialogOpen(true);
  const closeDeclineDialog = () => setIsDeclineDialogOpen(false);

  const handleGetConnectionsCompleted = (returnedConnections: UserConnectionQueryResult[], totalCount: number) => {
    setConnections(returnedConnections.map(({ cursor, node }) => ({ cursor, ...node })) as IConnection[]);
    setTotalConnectionsCount(totalCount);
  };

  const handleAcceptOrDeclineUserConnectionCompleted = (connection: AcceptOrDeclineUserConnectionMutation) => {
    const returnedConnection = connection.userConnection?.acceptOrDecline as DBUserConnection;

    if (returnedConnection.connectionStatus === EConnectionStatus.Declined) {
      setConnections(connections.filter(({ id }) => id !== returnedConnection.id));
      closeAcceptDialog();
      return;
    }

    setConnections([{ ...returnedConnection, cursor: '' }, ...connections.filter(({ id }) => id !== returnedConnection.id)]);
    closeAcceptDialog();
  };

  const {
    getConnections,
    isLoading: isUserConnectionsLoading,
    refetch,
  } = useGetUserConnections({
    onCompleted: handleGetConnectionsCompleted,
  });

  const { acceptOrDeclineUserConnection } = useAcceptOrDeclineUserConnection({
    onCompleted: handleAcceptOrDeclineUserConnectionCompleted,
  });

  const { markANotificationSeen } = useMarkANotificationSeen({
    onCompleted: () => {
      updateNotifications(
        notifications.map((message) => (message.guid === notification ? ({ ...message, seen: true } as INotification) : message)),
      );
    },
  });

  useEffect(() => {
    if (!notification) return;

    markANotificationSeen({
      variables: {
        ipAddress: '',
        notificationGuid: String(notification),
        userAgent: '',
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  useEffect(() => {
    if (connections.length) {
      return;
    }

    getConnections({
      variables: {
        first: CONNECTIONS_REQUEST_AMOUNT,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSearchActive) {
      getConnections({
        variables: {
          first: CONNECTIONS_REQUEST_AMOUNT,
          filter: searchValue,
        },
      });

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchActive, searchValue]);

  const hasAcceptedConnections = !isUserConnectionsLoading && connections.length > 0;
  const hasNoConnections = !isUserConnectionsLoading && connections.length === 0;

  const acceptedConnections = connections.filter(({ connectionStatus }) => connectionStatus === EConnectionStatus.Accepted);

  const incomingRequests = connections.filter(
    ({ connectionStatus, friendId }) => connectionStatus === EConnectionStatus.Requested && friendId === user?.id,
  );

  const outgoingRequests = connections.filter(
    ({ connectionStatus, friendId }) => connectionStatus === EConnectionStatus.Requested && friendId !== user?.id,
  );

  return (
    <BaseLayoutPage
      breadcrumbItems={[{ label: 'Connections' }]}
      meta={{ seoDescription: 'View all your connections', seoTitle: 'Connections' }}
      title="Connections"
    >
      <div className="c-connections-page__container">
        <div className="c-connections-page__section">
          <SearchInput
            id="search"
            isSearchActive={isSearchActive}
            labelText="Search connections"
            name="search"
            placeholder="Search connections"
            type="text"
            isLabelHidden
            onClear={() => {
              onSearchClear();
              getConnections({
                variables: {
                  first: CONNECTIONS_REQUEST_AMOUNT,
                },
              });
            }}
            onSearch={onSearchSubmit}
          />
        </div>

        {Boolean(incomingRequests.length) && (
          <section className="c-connections-page__section">
            <h2>Received requests ({incomingRequests.length})</h2>
            {incomingRequests.map((connection) => (
              <React.Fragment key={connection.id}>
                <ListCard
                  avatarImage={connection.user?.profileImage?.presignedUrl}
                  avatarTitle={String(connection.user?.displayedName)}
                  displayName={String(connection.user?.displayedName)}
                >
                  <AcceptOrDecline onAccept={openAcceptDialog} onDecline={openDeclineDialog} />
                </ListCard>

                <Dialog
                  isOpen={isAcceptDialogOpen}
                  title="Confirm Connection"
                  onCancel={closeAcceptDialog}
                  onClose={closeAcceptDialog}
                  onConfirm={() =>
                    acceptOrDeclineUserConnection({
                      variables: {
                        connectionId: connection.id,
                        connectionStatus: EConnectionStatus.Accepted,
                      },
                    })
                  }
                >
                  <p>Are you sure you want to connect with {connection.user?.displayedName}?</p>
                </Dialog>

                <Dialog
                  isOpen={isDeclineDialogOpen}
                  title="Decline Connection"
                  onCancel={closeDeclineDialog}
                  onClose={closeDeclineDialog}
                  onConfirm={() =>
                    acceptOrDeclineUserConnection({
                      variables: {
                        connectionId: connection.id,
                        connectionStatus: EConnectionStatus.Declined,
                      },
                    })
                  }
                >
                  <p>Are you sure you want to decline {connection.user?.displayedName}?</p>
                </Dialog>
              </React.Fragment>
            ))}
          </section>
        )}

        {Boolean(outgoingRequests.length) && (
          <section className="c-connections-page__section">
            <h2>Sent requests ({outgoingRequests.length})</h2>
            {outgoingRequests.map(({ id, user: outgoingUser }) => (
              <ListCard
                key={id}
                avatarImage={outgoingUser?.profileImage?.presignedUrl}
                avatarTitle={String(outgoingUser?.displayedName)}
                displayName={String(outgoingUser?.displayedName)}
                href={`/social/profile/${String(outgoingUser?.key)}`}
              />
            ))}
          </section>
        )}

        <section className="c-connections-page__section">
          <h2>Connections</h2>
          {isUserConnectionsLoading && [...Array(3)].map((_, key) => <ListCardSkeleton key={key} />)}

          {hasAcceptedConnections && (
            <>
              {acceptedConnections.map(({ user: acceptedConnectionUser, id }) => (
                <ListCard
                  key={id}
                  avatarImage={acceptedConnectionUser?.profileImage?.presignedUrl}
                  avatarTitle={acceptedConnectionUser?.displayedName}
                  description="View profile"
                  displayName={acceptedConnectionUser?.displayedName}
                  href={`/social/profile/${String(acceptedConnectionUser?.key)}`}
                  hasArrow
                />
              ))}

              {connections.length >= CONNECTIONS_REQUEST_AMOUNT && (
                <LoadMore
                  fetchText="Load more connections"
                  finishedText="No more connections"
                  hasReachedTotal={totalConnectionsCount === connections.length}
                  onClick={() => {
                    refetch({
                      after: connections[connections.length - 1].cursor,
                    }).then(({ data }) => {
                      setConnections([
                        ...connections,
                        ...(data.userConnection?.getUserConnections?.edges?.map(({ cursor, node }) => ({
                          cursor,
                          ...node,
                        })) as IConnection[]),
                      ]);
                    });
                  }}
                />
              )}
            </>
          )}

          {hasNoConnections && <EmptyMessage message="No connections found" />}
        </section>
      </div>
    </BaseLayoutPage>
  );
};

export { ConnectionsPage };
