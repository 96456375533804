import { ApolloError } from '@apollo/client';
import { COMMON_LIBRARY_CONSTANTS, getApolloErrorMessages } from '@netfront/common-library';

const getToastErrorMessages = ({
  error,
  shouldUseFriendlyErrorMessage = false,
}: {
  error: ApolloError | Error | string;
  shouldUseFriendlyErrorMessage: boolean;
}): string[] => {
  const {
    MESSAGES: {
      ERROR: { UNEXPECTED: defaultErrorMessage },
    },
  } = COMMON_LIBRARY_CONSTANTS;

  let errorMessages: string[] = [defaultErrorMessage];

  if (!shouldUseFriendlyErrorMessage) {
    return errorMessages;
  }

  if (error instanceof ApolloError) {
    try {
      const { networkError } = error;
      const {
        result: { errors },
      } = networkError as unknown as { result: { errors } };
      const [err] = errors;
      const { message } = err;
      errorMessages = [message];
    } catch {
      errorMessages = getApolloErrorMessages(error);
    }
  } else if (error instanceof Error) {
    errorMessages = [error.message];
  }

  return errorMessages;
};

export { getToastErrorMessages };
