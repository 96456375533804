import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { UPDATE_USER_MUTATION } from './useUpdateUser.graphql';
import {
  IUpdateUserMutationGraphQLResponse,
  IUpdateUserMutationVariables,
  IHandleUpdateUserParams,
  IUseUpdateUserOptions,
  IUseUpdateUser,
} from './useUpdateUser.interfaces';

const useUpdateUser = (options?: IUseUpdateUserOptions): IUseUpdateUser => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseUpdateUserOptions);

  const [executeUpdateUser, { loading: isLoading }] = useLoggedGeladaMutation<
    IUpdateUserMutationGraphQLResponse,
    IUpdateUserMutationVariables
  >({
    mutation: mutation ?? UPDATE_USER_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          user: { updateEmail: isUpdateEmailCompleted, updateUser: isUpdateUserCompleted },
        } = data;

        void (async () =>
          onCompleted({
            isUpdateEmailCompleted,
            isUpdateUserCompleted,
          }))();
      },
      onError,
    },
    product,
    token,
  });

  const handleUpdateUser = async ({ email, firstName, lastName, phoneNumber = '' }: IHandleUpdateUserParams) => {
    await executeUpdateUser({
      variables: {
        email,
        firstName,
        lastName,
        phoneNumber,
      },
    });
  };

  return {
    handleUpdateUser,
    isLoading,
  };
};

export { useUpdateUser };
