import { IconCross, IconTick } from '../Icons';

import { AcceptOrDeclineProps } from './AcceptOrDecline.interfaces';

const AcceptOrDecline = ({ onAccept, onDecline }: AcceptOrDeclineProps) => {
  return (
    <div className="c-accept-decline">
      <button className="c-accept-decline__button c-accept-decline__button--decline" title="Decline connection" onClick={onDecline}>
        <IconCross />
      </button>
      <button className="c-accept-decline__button c-accept-decline__button--accept" title="Accept connection" onClick={onAccept}>
        <IconTick />
      </button>
    </div>
  );
};

export { AcceptOrDecline };
