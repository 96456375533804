import { useState } from 'react';

import { Dialog } from '@netfront/ui-library';

import { ImageEditor } from '../ImageEditor';

import { ProfileCoverDialogProps } from './ProfileCoverDialog.interfaces';

export const ProfileCoverDialog = ({ onConfirm, ...rest }: ProfileCoverDialogProps) => {
  const [imageFile, setImageFile] = useState<File>();

  return (
    <Dialog
      {...rest}
      onConfirm={() => {
        if (imageFile) {
          onConfirm(imageFile);
        }
      }}
    >
      <ImageEditor borderRadius={0} width={300} onImageUpdate={setImageFile} />
    </Dialog>
  );
};
