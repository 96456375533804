import { useEffect, useRef, useState } from 'react';

import { Skeleton } from '@netfront/ui-library';
import { MentionsInput, Mention } from 'react-mentions';
import { EConnectionStatus, useGetUserConnections, UserConnectionQueryResult } from 'services';

import { EmojiSelector } from '../EmojiSelector';
import { IConnection } from '../Pages/ConnectionsPage/ConnectionsPage.interfaces';
import { UserDetailsHeader } from '../UserDetailsHeader';

import { DEFAULT_MENTION_STYLE } from './PostMessageEditor.constants';
import { IMention, ISuggestionData, PostMessageEditorProps } from './PostMessageEditor.interfaces';

import { useIsMounted } from '../../../hooks';

export const PostMessageEditor = ({ value: message, onChange }: PostMessageEditorProps) => {
  const { isMounted } = useIsMounted();

  const [connections, setConnections] = useState<IConnection[]>([]);
  const [mentions, setMentions] = useState<IMention[]>([]);

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const onMessageChange = (value: string) => {
    onChange(value);
  };

  const handleGetConnectionsCompleted = (returnedConnections: UserConnectionQueryResult[]) => {
    setConnections(returnedConnections.map(({ cursor, node }) => ({ cursor, ...node })) as IConnection[]);
  };

  const { getConnections } = useGetUserConnections({
    onCompleted: handleGetConnectionsCompleted,
  });

  useEffect(() => {
    getConnections({
      variables: {
        status: EConnectionStatus.Accepted,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="c-post-message-editor">
      {isMounted ? (
        <>
          <MentionsInput
            className="c-post-message-editor__editor"
            inputRef={textareaRef}
            placeholder="Share your thoughts..."
            value={message}
            onChange={({ target: { value } }) => onMessageChange(value)}
          >
            <Mention
              data={connections.map(({ user }) => ({ id: String(user?.key), display: user?.displayedName ?? user?.key, user }))}
              displayTransform={(id, display) => `@${display}`}
              renderSuggestion={({ user, display }: ISuggestionData) => (
                <UserDetailsHeader avatar={user.profileImage?.presignedUrl} displayName={display} />
              )}
              style={DEFAULT_MENTION_STYLE}
              trigger="@"
              onAdd={(id, display) => setMentions([...mentions, { id, name: display }])}
            />
          </MentionsInput>
          <EmojiSelector
            onSelect={(emoji) => {
              if (textareaRef.current === null) return;
              const { selectionEnd, value } = textareaRef.current;
              const postValue = value.slice(0, selectionEnd) + emoji + value.slice(selectionEnd);
              onChange(postValue);
            }}
          />
        </>
      ) : (
        <Skeleton borderRadius="10px" height="7rem" width="100%" />
      )}
    </div>
  );
};

PostMessageEditor.displayName = 'PostMessageEditor';
