import React, { Component, ErrorInfo, ReactNode } from 'react';

import { Button } from '@netfront/ui-library';

import { SingleFormPage } from '../../Pages';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <SingleFormPage meta={{ seoDescription: 'Looks like an error occured', seoTitle: 'Error' }} title="Error">
          <h1 className="color-primary h5 mb-2 text-uppercase">Whoops</h1>
          <h2 className="color-black mb-6 text-uppercase">Looks like an error occured</h2>

          <p className="mb-8">
            Sorry, it looks like an error has occured. You can return to where you were before, or report an issue to us.
          </p>
        
          <Button
            linkButton={{ id: 'contact', url: '/contact' }}
            text='Report issue'
          />
        </SingleFormPage>
      );
    }

    return this.props.children;
  }
}

// eslint-disable-next-line import/no-default-export
export default ErrorBoundary;
