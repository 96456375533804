import { gql } from '@apollo/client';

const UPDATE_USER_MUTATION = gql`
  mutation updateUser($email: String!, $firstName: String!, $lastName: String!, $dateOfBirth: Date, $gender: EGender) {
    user {
      updateEmail(email: $email)
      updateUser(firstname: $firstName, lastname: $lastName, dateOfBirth: $dateOfBirth, gender: $gender)
    }
  }
`;

export { UPDATE_USER_MUTATION };
