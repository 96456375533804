import { FeelingsItemType } from './PostFeelingsDialog.interfaces';

import { EPostFeeling } from '../../../services';

export const POSITIVE_FEELINGS: FeelingsItemType[] = [
  { name: 'Calm', emoji: '😌', type: EPostFeeling.Calm },
  { name: 'Happy', emoji: '😊', type: EPostFeeling.Happy },
  { name: 'Hopeful', emoji: '😀', type: EPostFeeling.Hopeful },
  { name: 'Excited', emoji: '😄', type: EPostFeeling.Excited },
  { name: 'Determined', emoji: '😃', type: EPostFeeling.Determined },
  { name: 'Grateful', emoji: '🙏', type: EPostFeeling.Grateful },
  { name: 'Inspired', emoji: '🤩', type: EPostFeeling.Inspired },
  { name: 'Lovestruck', emoji: '😍', type: EPostFeeling.Lovestruct },
  { name: 'Strong', emoji: '😎', type: EPostFeeling.Strong },
  { name: 'Heroic', emoji: '😁', type: EPostFeeling.Heroic },
  { name: 'Snarcy', emoji: '😙', type: EPostFeeling.Snarky },
];

export const NEUTRAL_FEELINGS: FeelingsItemType[] = [
  { name: 'Tired', emoji: '😴', type: EPostFeeling.Tired },
  { name: 'Lonely', emoji: '😟', type: EPostFeeling.Lonely },
  { name: 'Guilty', emoji: '😞', type: EPostFeeling.Guilty },
  { name: 'Sad', emoji: '🙁', type: EPostFeeling.Sad },
  { name: 'Restless', emoji: '🙃', type: EPostFeeling.Restless },
  { name: 'Misunderstood', emoji: '😕', type: EPostFeeling.Misunderstood },
  { name: 'Spent', emoji: '😪', type: EPostFeeling.Spent },
  { name: 'Distressed', emoji: '😧', type: EPostFeeling.Distressed },
  { name: 'Afraid', emoji: '😨', type: EPostFeeling.Afraid },
  { name: 'Irritable', emoji: '🤨', type: EPostFeeling.Spent },
  { name: 'Needy', emoji: '😩', type: EPostFeeling.Needy },
  { name: 'Wonky', emoji: '🥴', type: EPostFeeling.Wonky },
  { name: 'All-or-nothing', emoji: '😤', type: EPostFeeling.AllOrNothing },
  { name: 'Catastrophising', emoji: '🤯', type: EPostFeeling.Catastrophising },
  { name: 'Neutral', emoji: '😐', type: EPostFeeling.Neutral },
  { name: 'Personalising', emoji: '😶', type: EPostFeeling.Personalising },
  { name: 'Sarcastic', emoji: '😝', type: EPostFeeling.Sarcastic },
  { name: 'Scattered', emoji: '🤪', type: EPostFeeling.Scattered },
  { name: 'Subversive', emoji: '😛', type: EPostFeeling.Subversive },
  { name: 'Thoughtful', emoji: '🤔', type: EPostFeeling.Thoughtful },
];

export const NEGATIVE_FEELINGS: FeelingsItemType[] = [
  { name: 'Anxious', emoji: '😰', type: EPostFeeling.Anxious },
  { name: 'Angry', emoji: '😠', type: EPostFeeling.Angry },
  { name: 'Resentful', emoji: '😒', type: EPostFeeling.Resentful },
  { name: 'Antisocial', emoji: '🙄', type: EPostFeeling.Antisocial },
  { name: 'Depressed', emoji: '😢', type: EPostFeeling.Depressed },
  { name: 'Ashamed', emoji: '😳', type: EPostFeeling.Ashamed },
  { name: 'Obsessed', emoji: '😵', type: EPostFeeling.Obsessed },
  { name: 'Craving', emoji: '😋', type: EPostFeeling.Craving },
  { name: 'Pain', emoji: '😖', type: EPostFeeling.Pain },
];

export const ALL_POST_FEELINGS: FeelingsItemType[] = [...POSITIVE_FEELINGS, ...NEUTRAL_FEELINGS, ...NEGATIVE_FEELINGS];
