import { ITogglePinnedPostOptions } from './useTogglePinnedPost.interface';

import { bonoboClient } from '../../apollo';
import { useTogglePinnedPostMutation } from '../../bonobo/generated.graphql';

export const useTogglePinnedPost = (options?: ITogglePinnedPostOptions) => {
  const { onCompleted, onError } = options ?? ({} as ITogglePinnedPostOptions);

  const [togglePinnedPost, { loading: isLoading, error }] = useTogglePinnedPostMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data);
    },
    onError,
  });

  return { togglePinnedPost, isLoading, error };
};
