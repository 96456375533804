import { getAccessTokenCookie, getApolloClient, getApolloLink } from '@netfront/common-library';

export const getHowlerApolloClient = () => {
  const token = getAccessTokenCookie();

  return getApolloClient({
    apiName: 'howler',
    apolloLink: getApolloLink({ apiName: 'howler', token, projectId: process.env.REACT_APP_PROJECT_ID }),
  });
};

export const howlerClient = getHowlerApolloClient();
