import React from 'react';

import NextLink from 'next/link';

import { IconHome } from '../Icons';

import { HOME_URL } from './Breadcrumb.constants';
import { BreadcrumbProps } from './Breadcrumb.interfaces';

const Breadcrumb = ({ breadcrumbItems }: BreadcrumbProps) => {
  return breadcrumbItems ? (
    <nav aria-label="Breadcrumbs" className="c-breadcrumbs">
      <ol className="c-breadcrumb__list">
        <li className="c-breadcrumb__item">
          <NextLink href={HOME_URL} legacyBehavior>
            <a>
              <span className="h-hide-visually">Return to feed</span>
              <IconHome additionalClassNames="c-breadcrumb__home-icon" />
            </a>
          </NextLink>
        </li>
        {breadcrumbItems.map(({ label, href }, key) => (
          <React.Fragment key={key}>
            <span className="c-breadcrumb__divider">/</span>
            <li key={`${label}-${key}`} className="c-breadcrumb__item">
              {href ? (
                <NextLink href={href} legacyBehavior>
                  <a>{label}</a>
                </NextLink>
              ) : (
                label
              )}
            </li>
          </React.Fragment>
        ))}
      </ol>
    </nav>
  ) : null;
};

export { Breadcrumb };
