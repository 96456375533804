export const HelpIcon = () => (
  <svg
    fill="none"
    height="32"
    stroke="currentColor"
    strokeWidth="4"
    viewBox="0 0 112.296 86.022"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(2 2.022)">
      <g transform="translate(0 24.544)">
        <path
          className="a"
          d="M630.406,538.159,623.5,550.129l-6.962-12.054a21.745,21.745,0,0,1,1.945-43.4h21.029a21.744,21.744,0,0,1,0,43.487Z"
          transform="translate(-596.731 -494.672)"
        />
      </g>
      <path
        className="a"
        d="M672.989,514.231l5.064-.006,6.314,10.936,7.069-10.93a21.745,21.745,0,0,0,21.745-21.745h0a21.745,21.745,0,0,0-21.745-21.745H670.408a21.754,21.754,0,0,0-20.754,15.237v1.283"
        transform="translate(-604.885 -466.441)"
      />
      <path
        className="a"
        d="M623.114,469.981c3.626,2.56,5.828,13.349,5.828,13.349s-10.9,1.534-14.53-1.027a7.558,7.558,0,1,1,8.7-12.322Z"
        transform="translate(-601.258 -468.431)"
      />
      <path
        className="a"
        d="M645.989,475.745c-1.034,2.911-7.848,6.417-7.848,6.417s-3.08-7.018-2.047-9.929a5.261,5.261,0,1,1,9.9,3.512Z"
        transform="translate(-602.764 -468.431)"
      />
    </g>
  </svg>
);
