import { IDeleteCommentRelateByCommentIdOptions } from './useDeleteCommentRelateByCommentId.interface';

import { bonoboClient } from '../../apollo';
import { useDeleteCommentRelateByCommentIdMutation } from '../../bonobo/generated.graphql';

export const useDeleteCommentRelateByCommentId = (options?: IDeleteCommentRelateByCommentIdOptions) => {
  const { onCompleted, onError } = options ?? ({} as IDeleteCommentRelateByCommentIdOptions);

  const [deleteCommentRelateByCommentId, { loading: isDeleteCommentRelateLoading, error }] = useDeleteCommentRelateByCommentIdMutation({
    client: bonoboClient,
    onCompleted: () => {
      if (!onCompleted) {
        return;
      }
      void (async () => onCompleted())();
    },
    onError,
  });

  return { deleteCommentRelateByCommentId, isDeleteCommentRelateLoading, error };
};
