import { useEffect, useState } from 'react';

import { IDBUserFlowStep, IUseGetUserFlowStepsOnCompletedResponse, useGetUserFlowSteps } from '@netfront/ekardo-content-library';
import { useUser, useGetGroupPermissions, IGetGroupPermissionsOnCompletedResponse, IDBMembership } from '@netfront/gelada-identity-library';
import { add, isPast } from 'date-fns';
import isEmpty from 'lodash.isempty';

import {
  DELAYED_GROUP_ID,
  DELAY_DURATION,
  FOLLOW_UP_DELAYED_PRE_STEP_ID,
  FOLLOW_UP_ONE_STEP_ID,
  FOLLOW_UP_TWO_STEP_ID,
  INSTANT_GROUP_ID,
} from './useGetUserGroup.constants';
import { IUseGetUserGroup, IUserGroup } from './useGetUserGroup.interfaces';

import { useProtectedRoute } from '../../hooks';

const useGetUserGroup = (): IUseGetUserGroup => {
  const { isAuthenticated } = useProtectedRoute();

  const { getUser } = useUser();

  const [memberships, setMemberShips] = useState<IDBMembership[]>([]);

  const handleGetGroupPermissionsCompleted = (data: IGetGroupPermissionsOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const {
      membershipConnection: { edges },
    } = data;

    setMemberShips(edges.map(({ node }) => node));
  };

  const { handleGetGroupPermissions } = useGetGroupPermissions({
    onCompleted: handleGetGroupPermissionsCompleted,
  });

  const [userGroup, setUserGroup] = useState<IUserGroup>();

  const [questionnaireSteps, setQuestionnaireSteps] = useState<IDBUserFlowStep[]>([]);

  const handleGetUserFlowStepsCompleted = ({ userFlowSteps: returnedUserFlowSteps }: IUseGetUserFlowStepsOnCompletedResponse) => {
    setQuestionnaireSteps(returnedUserFlowSteps);
  };

  const { handleGetUserFlowSteps } = useGetUserFlowSteps({
    onCompleted: handleGetUserFlowStepsCompleted,
  });

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    handleGetUserFlowSteps({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      stepType: 'QUESTIONNAIRE',
    });

    handleGetGroupPermissions({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      userId: Number(getUser()?.id),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (userGroup || isEmpty(memberships) || !memberships.length || !questionnaireSteps.length) {
      return;
    }

    const membership = memberships.find(({ groupId }) => groupId === INSTANT_GROUP_ID || groupId === DELAYED_GROUP_ID);

    if (!membership) {
      return;
    }

    const isDelayedGroup = memberships.find(({ groupId }) => groupId === DELAYED_GROUP_ID);

    const dateCreated = new Date(membership.created);
    const minimumAccessDate = isDelayedGroup ? add(dateCreated, DELAY_DURATION) : dateCreated;

    const isAvailable = isPast(minimumAccessDate);

    setUserGroup({
      created: dateCreated,
      groupId: Number(membership.group?.id),
      groupType: isDelayedGroup ? 'delayed' : 'instant',
      isAvailable,
      minimumAccessDate,
      firstFollowUp: {
        accessDate: add(minimumAccessDate, { weeks: 8 }),
        isAvailable: isPast(add(minimumAccessDate, { weeks: 8 })),
        isComplete: Boolean(questionnaireSteps.find(({ id: questionnaireId }) => questionnaireId === FOLLOW_UP_ONE_STEP_ID)?.isComplete),
      },
      secondFollowUp: {
        accessDate: add(minimumAccessDate, { weeks: 20 }),
        isAvailable: isPast(add(minimumAccessDate, { weeks: 20 })),
        isComplete: Boolean(questionnaireSteps.find(({ id: questionnaireId }) => questionnaireId === FOLLOW_UP_TWO_STEP_ID)?.isComplete),
      },
      delayedAccessPreFollowUp: isDelayedGroup
        ? {
            accessDate: minimumAccessDate,
            isAvailable: isPast(add(minimumAccessDate, { weeks: 8 })),
            isComplete: Boolean(
              questionnaireSteps.find(({ id: questionnaireId }) => questionnaireId === FOLLOW_UP_DELAYED_PRE_STEP_ID)?.isComplete,
            ),
          }
        : null,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberships, questionnaireSteps]);

  return {
    userGroup,
  };
};

export { useGetUserGroup };
