import { gql } from '@apollo/client';

export const UpdateComment = gql`
  mutation updateComment($request: UpdateCommentInputType!) {
    comment {
      update(request: $request) {
        id
      }
    }
  }
`;
