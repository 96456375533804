import { useAuthentication, useDomain, useLogout } from '@netfront/gelada-identity-library';
import { Button } from '@netfront/ui-library';
import { useToast } from 'hooks';
import { useRouter } from 'next/router';

import { SOCIAL_HEADER_MENU_ITEMS } from 'components/Social/SocialHeader/SocialHeader.constants';
import { UserDetailsHeader } from 'components/Social/UserDetailsHeader';

import { PrivateMobileMenuProps } from './PrivateMobileMenu.interfaces';

import { MenuDropdown } from '../../MenuDropdown';

const PrivateMobileMenu = ({ avatar, displayName, profileLink }: PrivateMobileMenuProps) => {
  const { isAuthenticated } = useAuthentication();

  const { handleToastError } = useToast();
  const { push } = useRouter();
  const { getDomain, isDomainReady } = useDomain();
  const { handleLogout } = useLogout({
    onCompleted: () => {
      push('/').catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );
    },
  });

  return (
    <MenuDropdown className="relative flex flex-col h-screen overflow-y-auto top-0 xl:none">
      <div className="flex flex-1 flex-col py-8 px-4">
        <div className="mb-8">
          <UserDetailsHeader avatar={avatar} displayName={displayName} href={String(profileLink)} size="medium" />
        </div>

        <nav>
          <ul className="flex flex-col m-0">
            {SOCIAL_HEADER_MENU_ITEMS.map((item) => {
              const buttonClasses = 'flex items-center color-black font-header hover:bg-grey-50 pointer px-6 py-3 rounded';

              const { href, icon, id, label } = item;

              return (
                <li key={id} className="mb-0 ml-0">
                  <a className={buttonClasses} href={href}>
                    {icon && <div className="w-5 h-5 mr-4 flex items-center">{icon()}</div>}
                    {label}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>

        <div className="flex-col items-center flex justify-center mb-8 mt-16">
          {!isAuthenticated ? (
            <div className='flex items-center gap-4'>
              <Button
                linkButton={{ id: 'register', url: '/register' }}
                text='Register'
              />

              <Button
                linkButton={{ id: 'login', url: '/login' }}
                text='Login'
              />
            </div>
          ) : (
            <>
              <Button
                isDisabled={!isDomainReady}
                text='Sign out'
                onClick={() => handleLogout({ domain: getDomain(), path: '/' })}
              />
            </>
          )}
        </div>

        <div className="text-center mb-16"></div>
      </div>
    </MenuDropdown>
  );
};

export { PrivateMobileMenu };
