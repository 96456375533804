import { gql } from '@apollo/client';

import { IDBUserGraphTypeFragment } from './user.fragment';

export const IDBCommentGraphTypeFragment = gql`
  fragment commentGraphType on CommentGraphType {
    id
    message
    authorId
    author {
      ...user
    }
    comments {
      edges {
        node {
          id
          author {
            ...user
          }
          createdDate
          hasRelated
          postId
          relateCount
          commentsCount
          updatedDate
          createdDateTime
          message
        }
      }
    }
    createdDate
    hasRelated
    postId
    relateCount
    commentsCount
    updatedDate
    createdDateTime
  }
  ${IDBUserGraphTypeFragment}
`;
