import { IGetPostsOptions } from './useGetPosts.interface';

import { bonoboClient } from '../../apollo';
import { PostInterfaceConnection, useGetFeedPostsLazyQuery } from '../../bonobo/generated.graphql';

export const useGetPosts = (options?: IGetPostsOptions) => {
  const { onCompleted, onError } = options ?? ({} as IGetPostsOptions);

  const [getFeedPosts, { loading: isLoading, error, fetchMore, refetch }] = useGetFeedPostsLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      const posts = data.post?.getFeedPosts as PostInterfaceConnection;
      onCompleted(posts, posts.totalCount ?? 0);
    },
    onError,
  });

  return { getFeedPosts, isLoading, error, fetchMore, refetch };
};
