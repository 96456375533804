import { useEffect, useState } from 'react';

import { useGoogleAnalytics } from '@netfront/ekardo-content-library';
import { useUser } from '@netfront/gelada-identity-library';
import { ESocialAnalyticEvents } from 'interfaces';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';
import { shouldTrackAnalytics } from 'utils';

import { recurseComments } from './Feed.helpers';
import { EFeedQuery, FeedProps, IPost } from './Feed.interfaces';

import {
  CommentConnection,
  CommentEdge,
  CommentGraphType,
  CreateCommentMutation,
  CreateSubCommentMutation,
  PollGraphType,
  PostGraphType,
  PostInterfaceConnection,
  TogglePinnedPostMutation,
  useCommentRelate,
  useCreateComment,
  useCreatePostReport,
  useCreateSharePost,
  useCreateSubComment,
  useDeleteCommentRelateByCommentId,
  useDeletePost,
  useDeletePostRelate,
  useGetConnectionPosts,
  useGetFeedPost,
  useGetModeratorsPosts,
  useGetPosts,
  usePostRelate,
  useTogglePinnedPost,
  useVoteAPoll,
  VoteAPollMutation,
} from '../../../services';
import { EmptyMessage, IconCommunity, IUpdateRelateParams, LoadMore, Post, PostSkeleton } from '../../Social';

const Feed = ({
  authorId,
  communityId,
  first = 15,
  message,
  postId: postIdProp,
  postSkeletonCount = 2,
  query = EFeedQuery.All,
  shouldShowCommunitiesMessage = false,
  tags,
}: FeedProps) => {
  const { trackEvent } = useGoogleAnalytics();
  const { push } = useRouter();
  const loggedUserId = useUser().getUser()?.id;
  const [posts, setPosts] = useState<IPost[]>([]);
  const [totalPostsCount, setTotalPostsCount] = useState<number>(0);
  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(false);

  const { ref: loadMoreRef, inView: shouldFetchMore } = useInView();

  const { postRelate } = usePostRelate();
  const { deletePostRelate } = useDeletePostRelate();
  const { commentRelate } = useCommentRelate();
  const { deleteCommentRelateByCommentId } = useDeleteCommentRelateByCommentId();

  // Event handlers

  const onPostRelate = ({ action, hasRelated, id: postId, relateType }: IUpdateRelateParams) => {
    switch (action) {
      case 'ADD':
        postRelate({ variables: { postId, type: relateType } });
        break;
      case 'CHANGE':
        postRelate({ variables: { postId, type: relateType } });
        break;
      case 'REMOVE':
        deletePostRelate({ variables: { postId } });
        break;
    }

    if(shouldTrackAnalytics()) {
      trackEvent(ESocialAnalyticEvents.SocialPostRelate, {
        postId,
        relateType,
      })
    };

    setPosts(
      posts.map((post: IPost) =>
        post.id === postId
          ? {
            ...post,
            hasRelated,
            relateCount:
                action !== 'CHANGE' ? (hasRelated ? Number(post.relateCount) + 1 : Number(post.relateCount) - 1) : post.relateCount,
          }
          : post,
      ),
    );
  };

  const onCommentRelate = ({ action, hasRelated, id: commentId, relateType }: IUpdateRelateParams) => {
    switch (action) {
      case 'ADD':
        commentRelate({ variables: { commentId, type: relateType } });
        break;
      case 'CHANGE':
        commentRelate({ variables: { commentId, type: relateType } });
        break;
      case 'REMOVE':
        deleteCommentRelateByCommentId({ variables: { commentId } });
        break;
    }

    if(shouldTrackAnalytics()) {
      trackEvent(ESocialAnalyticEvents.SocialPostCommentRelate, {
        commentId,
        relateType
      })
    };

    const updatedFeed = posts.map((post) => {
      const edges = recurseComments(post.comments, ({ cursor, node }) => {
        if (node?.id === commentId) {
          node.hasRelated = hasRelated;
        }

        return { cursor, node };
      });

      return {
        ...post,
        comments: {
          ...post.comments,
          edges,
        },
      };
    }) as IPost[];

    setPosts(updatedFeed);
  };

  const onEdit = (postId: number) => {
    push(`/social/create-post?edit=${postId}`);
  };

  // Api hook handlers

  const handleGetFeedPostsCompleted = (postConnection: PostInterfaceConnection) => {
    setPosts(postConnection.edges?.map(({ cursor, node }) => ({ cursor, ...(node as PostGraphType) })) as IPost[]);
    setTotalPostsCount(Number(postConnection.totalCount));
  };

  const handleGetConnectionPostsCompleted = (returnedConnectionPosts: PostInterfaceConnection) => {
    setPosts(returnedConnectionPosts.edges?.map(({ cursor, node }) => ({ cursor, ...(node as PostGraphType) })) as IPost[]);
    setTotalPostsCount(Number(returnedConnectionPosts.totalCount));
  };

  const handleGetModeratorsPostsCompleted = (returnedModeratorPosts: PostInterfaceConnection) => {
    setPosts(returnedModeratorPosts.edges?.map(({ cursor, node }) => ({ cursor, ...(node as PostGraphType) })) as IPost[]);
    setTotalPostsCount(Number(returnedModeratorPosts.totalCount));
  };

  const handleCreateCommentCompleted = (comment: CreateCommentMutation) => {
    const newComment = comment.comment?.create;

    const updatedFeed = posts.map((post) => {
      if (post.id === newComment?.postId) {
        const edges = post.comments?.edges as CommentEdge[];

        return {
          ...post,
          comments: {
            edges: [{ node: newComment }, ...edges],
          },
        };
      }

      return post;
    }) as IPost[];

    if(shouldTrackAnalytics()) {
      trackEvent(ESocialAnalyticEvents.SocialPostComment, {
        postId: newComment?.postId,
        commentId: newComment?.id
      })
    }
    
    setPosts(updatedFeed);
  };

  const handleCreateSubCommentCompleted = (comment: CreateSubCommentMutation) => {
    const newComment = comment.comment?.createSubComment as CommentGraphType;

    const updatedFeed = posts.map((post) => {
      const edges = recurseComments(post.comments, ({ cursor, node }) => {
        if (!node) return;
        if (node.id === newComment.parentCommentId) {
          node.comments = {
            ...node.comments,
            edges: [...(node.comments?.edges as CommentEdge[]), { cursor: '', node: newComment }],
          } as CommentConnection;
        }

        return { cursor, node };
      });

      return {
        ...post,
        comments: {
          ...post.comments,
          edges,
        },
      };
    }) as IPost[];

    setPosts(updatedFeed);
  };

  const handleVoteAPollCompleted = (poll: VoteAPollMutation) => {
    const newPoll = poll.poll?.vote as PollGraphType;
    setPosts(posts.map((post) => (post.id === newPoll.postId ? { ...post, poll: newPoll } : post)));

    if(shouldTrackAnalytics()) {
      trackEvent(ESocialAnalyticEvents.SocialPostPollVote, {
        question: newPoll.title,
        voteSelection: newPoll.userVote?.pollItemId
      })
    }
  };

  const handleTogglePinnedPostCompleted = (returnedPost: TogglePinnedPostMutation) => {
    const currentPost = returnedPost.post?.togglePinned;
    setPosts(posts.map((post) => (post.id === currentPost?.id ? ({ ...post, pinned: currentPost.pinned } as IPost) : post)));
  };

  const handleDeletePostCompleted = () => {
    refetchPosts();
  };

  const refetchPosts = () => {
    setIsRefetchLoading(true);

    switch (query) {
      case EFeedQuery.Members:
        refetchConnectionPosts({
          shouldIncludeComments: true,
          tags,
          first,
          message,
        }).then(() => {
          setIsRefetchLoading(false);
          window.scrollTo(0, 0);
        });
        break;
      case EFeedQuery.Moderators:
        refetchModeratorPosts({
          shouldIncludeComments: true,
          tags,
          first,
          message,
        }).then(() => {
          setIsRefetchLoading(false);
          window.scrollTo(0, 0);
        });
        break;
      default:
        refetchAllPosts({
          shouldIncludeComments: true,
          authorId,
          tags,
          first,
          message,
        }).then(() => {
          setIsRefetchLoading(false);
          window.scrollTo(0, 0);
        });
    }
  };

  const handleSharePostCompleted = () => {
    refetchPosts();
  };

  // Api hooks

  const {
    getFeedPosts,
    isLoading: isGetFeedPostsLoading,
    fetchMore: fetchMoreAllPosts,
    refetch: refetchAllPosts,
  } = useGetPosts({
    onCompleted: handleGetFeedPostsCompleted,
  });

  const {
    getConnectionPosts,
    isConnectionsPostsLoading,
    fetchMore: fetchMoreConnectionPosts,
    refetch: refetchConnectionPosts,
  } = useGetConnectionPosts({
    onCompleted: handleGetConnectionPostsCompleted,
  });

  const {
    getModeratorsPosts,
    isModeratorsPostsLoading,
    fetchMore: fetchMoreModeratorPosts,
    refetch: refetchModeratorPosts,
  } = useGetModeratorsPosts({
    onCompleted: handleGetModeratorsPostsCompleted,
  });

  const { createComment, isCreateCommentLoading } = useCreateComment({
    onCompleted: handleCreateCommentCompleted,
  });

  const { createSubComment } = useCreateSubComment({
    onCompleted: handleCreateSubCommentCompleted,
  });

  const { voteAPoll } = useVoteAPoll({
    onCompleted: handleVoteAPollCompleted,
  });

  const { togglePinnedPost } = useTogglePinnedPost({
    onCompleted: handleTogglePinnedPostCompleted,
  });

  const { deletePostMutation: deletePost } = useDeletePost({
    onCompleted: handleDeletePostCompleted,
  });

  const { createSharePostMutation: sharePost } = useCreateSharePost({
    onCompleted: handleSharePostCompleted,
  });

  const { createSharePostMutation: reportPost } = useCreatePostReport({
    onCompleted: ({ report }) => {
      if(shouldTrackAnalytics()) {
        trackEvent(ESocialAnalyticEvents.SocialPostReport, {
          postId: report?.create?.post?.id
        })
      }
    },
  });

  const { handleGetFeedPost, isLoading: isGetPostLoading } = useGetFeedPost({
    onCompleted: ({ post }) => setPosts([...posts, post as unknown as IPost])
  })

  useEffect(() => {
    // if (communityId) {
    //   //   console.log({ communityId });
    //   getFeedPosts({
    //     variables: {
    //       communityId,
    //       shouldIncludeComments: true,
    //       first,
    //     },
    //   });
    //   return;
    // }

    if (postIdProp) {
      handleGetFeedPost({
        postId: postIdProp
      })
      return;
    }

    const queryVariables = {
      shouldIncludeComments: true,
      authorId,
      communityId,
      tags,
      first,
      message,
    };

    switch (query) {
      case EFeedQuery.Members:
        getConnectionPosts({
          variables: queryVariables,
        });
        break;
      case EFeedQuery.Moderators:
        getModeratorsPosts({
          variables: queryVariables,
        });
        break;
      default:
        getFeedPosts({
          variables: queryVariables,
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorId, communityId, postIdProp, message, query, tags]);

  useEffect(() => {
    if (!shouldFetchMore) {
      return;
    }

    if (totalPostsCount === posts.length) {
      return;
    }

    if (postIdProp) return;

    switch (query) {
      case EFeedQuery.Members:
        fetchMoreConnectionPosts({
          variables: {
            after: posts[posts.length - 1].cursor,
          },
        }).then(({ data }) => {
          setPosts([...posts, ...(data.post?.connectionPosts?.edges?.map(({ cursor, node }) => ({ cursor, ...node })) as IPost[])]);
        });
        break;
      case EFeedQuery.Moderators:
        fetchMoreModeratorPosts({
          variables: {
            after: posts[posts.length - 1].cursor,
          },
        }).then(({ data }) => {
          setPosts([...posts, ...(data.post?.moderatorsPosts?.edges?.map(({ cursor, node }) => ({ cursor, ...node })) as IPost[])]);
        });
        break;
      default:
        fetchMoreAllPosts({
          variables: {
            after: posts[posts.length - 1].cursor,
          },
        }).then(({ data }) => {
          setPosts([...posts, ...(data.post?.getFeedPosts?.edges?.map(({ cursor, node }) => ({ cursor, ...node })) as IPost[])]);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchMore, query]);

  const isPostsLoading =
    isGetFeedPostsLoading || isConnectionsPostsLoading || isModeratorsPostsLoading || isRefetchLoading || isGetPostLoading;

  return (
    <div>
      {isPostsLoading && [...Array(postSkeletonCount)].map((_, key) => <PostSkeleton key={key} />)}

      {!isPostsLoading &&
        Boolean(posts.length) &&
        !shouldShowCommunitiesMessage &&
        posts.map(
          ({
            assets,
            author,
            authorCommunityRole,
            comments,
            community,
            createdDateTime,
            feeling,
            hasRelated,
            id,
            message: postMessage,
            pinned: isPinned,
            poll,
            relateCount,
            shareCount,
            originalPost,
          }) => {
            const { edges } = comments ?? ({} as CommentConnection);
            const postComments = edges?.map(({ node }) => node);

            return (
              <Post
                key={id}
                assets={assets}
                avatar={author?.profileImage?.presignedUrl}
                comments={postComments}
                community={community}
                communityRole={authorCommunityRole}
                date={createdDateTime}
                displayName={author?.displayedName}
                feeling={feeling}
                hasRelated={hasRelated}
                id={id}
                isCommentSubmitting={isCreateCommentLoading}
                isOnline={author?.isOnline}
                isOwner={loggedUserId === author?.id}
                isPinned={isPinned}
                message={postMessage}
                poll={poll}
                relateCount={relateCount}
                shareCount={shareCount}
                sharedPost={originalPost}
                userProfileLink={`/social/profile/${author?.id === loggedUserId ? '' : String(author?.key)}`}
                shouldShowPostDetails
                onComment={(postId, commentMessage) =>
                  createComment({
                    variables: {
                      request: {
                        message: commentMessage,
                        postId,
                      },
                    },
                  })
                }
                onCommentRelate={onCommentRelate}
                onCommentReply={(commentId, replyMessage) =>
                  createSubComment({
                    variables: {
                      request: {
                        commentId,
                        message: replyMessage,
                      },
                    },
                  })
                }
                onDelete={(postId) => {
                  deletePost({
                    variables: {
                      postId,
                    },
                  });
                  if(shouldTrackAnalytics()) {
                    trackEvent(ESocialAnalyticEvents.SocialPostDelete, {
                      postId
                    })
                  }
                }}
                onEdit={onEdit}
                onPostRelate={onPostRelate}
                onReport={(postId, type) => {
                  reportPost({
                    variables: {
                      postId,
                      type,
                    },
                  });
                }}
                onSharePost={({
                  message: postShareMessage,
                  postId,
                  postType,
                  shareOption,
                  communities,
                  communityId: sharePostCommunityId,
                }) => {
                  sharePost({
                    variables: {
                      request: {
                        message: postShareMessage,
                        postId,
                        postType,
                        shareOption,
                        communities,
                        communityId: sharePostCommunityId,
                      },
                    },
                  });
                }}
                onTogglePin={(postId) =>
                  togglePinnedPost({
                    variables: {
                      postId,
                    },
                  })
                }
                onVotePoll={({ id: pollId, userVote }) => {
                  voteAPoll({
                    variables: {
                      itemId: Number(userVote?.pollItemId),
                      pollId,
                    },
                  });
                }}
              />
            );
          },
        )}
      {!isPostsLoading && Boolean(!posts.length) && !shouldShowCommunitiesMessage && <EmptyMessage message="No post's found" />}

      {!isPostsLoading && shouldShowCommunitiesMessage && (
        <EmptyMessage
          icon={() => <IconCommunity />}
          message={
            <>
              It looks like you aren’t connected to a community,{' '}
              <NextLink href="/social/communities/public" legacyBehavior>
                <a>click here to connect</a>
              </NextLink>{' '}
              or get in touch with the person that referred you
            </>
          }
        />
      )}

      {!isPostsLoading && Boolean(posts.length) && !postIdProp && !shouldShowCommunitiesMessage && (
        <LoadMore
          ref={loadMoreRef}
          fetchText="Loading more"
          finishedText="No more posts"
          hasReachedTotal={totalPostsCount === posts.length}
        />
      )}
    </div>
  );
};

export { Feed };
