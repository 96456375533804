import { IGetPostsOptions } from '../useGetPosts';

import { bonoboClient } from '../../apollo';
import { PostInterfaceConnection, useGetConnectionPostsLazyQuery } from '../../bonobo/generated.graphql';

export const useGetConnectionPosts = (options?: IGetPostsOptions) => {
  const { onCompleted, onError } = options ?? ({} as IGetPostsOptions);

  const [getConnectionPosts, { loading: isConnectionsPostsLoading, error, fetchMore, refetch }] = useGetConnectionPostsLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      const posts = data.post?.connectionPosts as PostInterfaceConnection;
      onCompleted(posts, posts.totalCount ?? 0);
    },
    onError,
  });

  return { getConnectionPosts, isConnectionsPostsLoading, error, fetchMore, refetch };
};
