import { IUpdatePostOptions } from './useEditPost.interface';

import { bonoboClient } from '../../apollo';
import { useUpdatePostMutation } from '../../bonobo/generated.graphql';

export const useEditPost = (options?: IUpdatePostOptions) => {
  const { onCompleted, onError } = options ?? ({} as IUpdatePostOptions);

  const [editPostMutation, { loading: isEditLoading, error }] = useUpdatePostMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { editPostMutation, isEditLoading, error };
};
