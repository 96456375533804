import { IconCommunity, IconConnection, IconHome, IconMessage } from '../Icons';

import { ISocialHeaderMenuItem } from './SocialHeader.interfaces';

export const SOCIAL_HEADER_MENU_ITEMS: ISocialHeaderMenuItem[] = [
  { id: 'feed', label: 'Feed', href: '/social/feed', icon: (className) => <IconHome additionalClassNames={className} /> },
  {
    id: 'connections',
    label: 'Connections',
    href: '/social/connections',
    icon: (className) => <IconConnection additionalClassNames={className} />,
  },
  {
    id: 'communities',
    label: 'Communities',
    href: '/social/communities',
    icon: (className) => <IconCommunity additionalClassNames={className} />,
  },
  { id: 'messages', label: 'Messages', href: '/social/messages', icon: (className) => <IconMessage additionalClassNames={className} /> },
];
