import { RelateIcon } from '../RelateIcon';

import { ERelateType } from '../../../services';

const PostRelateIconList = () => {
  return (
    <div className="c-post-relate-icon-list">
      {Object.keys(ERelateType).map((type, key) => (
        <RelateIcon key={`relate-icon-${key}`} relateType={ERelateType[type]} />
      ))}
    </div>
  );
};

export { PostRelateIconList };
