import { IMarkANotificationSeenOption } from './useMarkANotificationSeen.interface';

import { howlerClient } from '../../apollo';
import { useMarkANotificationSeenMutation } from '../../howler';

export const useMarkANotificationSeen = (options?: IMarkANotificationSeenOption) => {
  const { onCompleted, onError } = options ?? ({} as IMarkANotificationSeenOption);

  const [markANotificationSeen, { loading: isLoading }] = useMarkANotificationSeenMutation({
    client: howlerClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data);
    },
    onError,
  });

  return {
    markANotificationSeen,
    isLoading,
  };
};
