import { format } from 'date-fns';

import { DelayedGroupMessageProps } from './DelayedGroupMessage.interfaces';

const DelayedGroupMessage = ({ accessDate }: DelayedGroupMessageProps) => {
  return (
    <div className="bg-white rounded-md flex mb-8">
      <div
        aria-hidden={true}
        className="flex justify-center items-center h5 weight-800 rounded-md color-white w-14 h-14 mb-0 bg-pink-400 pulse-pink-400"
      >
        !
      </div>
      <div className="flex items-center flex-1 px-4 color-black">
        <p className="flex-1 mb-0">
          You have been allocated to the delayed access group, and will be able to access the modules on:{' '}
          <span className="weight-600">{format(new Date(accessDate), 'dd/MM/yyyy')}</span>
        </p>
      </div>
    </div>
  );
};

export { DelayedGroupMessage };
