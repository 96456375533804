import { useState } from 'react';

import { getAccessTokenCookie, useToggle } from '@netfront/common-library';
import { Button } from '@netfront/ui-library';
import NextLink from 'next/link';

import { PUBLIC_MENU } from '../Header.constants';
import { IRootMenuItem } from '../Header.interfaces';

import { LogoLink, MenuDropdown, MobileMenuTrigger, PublicMobileMenu } from '../../../components';
import { useGetRegisterUrl, useIsMounted } from '../../../hooks';

const PublicHeader = () => {
  const [isDropDownMenuOpen, setIsDropDownMenuOpen] = useState<boolean>(false);
  const [selectedDropDown, setSelectedDropDown] = useState<IRootMenuItem>();

  const token = getAccessTokenCookie();
  const { registerUrl } = useGetRegisterUrl();

  const { isToggled: isMobileMenuOpen, toggle } = useToggle();
  const { isMounted } = useIsMounted();

  const navLinkClasses = 'flex items-center color-white font-body pointer px-6 py-8 ml-2 mb-0';

  const handleMenuClose = () => {
    setIsDropDownMenuOpen(false);
    setSelectedDropDown(undefined);
  };

  return (
    <header className="index-2 relative flex flex-col">
      <div className="bg-primary index-2 py-2 relative">
        <div className="items-center container flex">
          <LogoLink size="lg" />

          <MobileMenuTrigger isOpen={isMobileMenuOpen} onPress={toggle} />

          <div className="ml-auto none xl:flex">
            <nav>
              <ul className="items-center flex">
                {PUBLIC_MENU.map((item) => {
                  const { href, id, label, privateHref } = item;

                  const itemHref = token && privateHref ? privateHref : href;

                  return (
                    <li key={id} className="mb-0">
                      <NextLink className={navLinkClasses} href={itemHref}>
                        {label}
                      </NextLink>
                    </li>
                  );
                })}
              </ul>
            </nav>

            {isMounted &&
              (token ? (
                <div aria-label="Return to dashboard" className="flex items-center ml-4">
                  <Button
                    linkButton={{ id: 'feed', url: '/social/feed' }}
                    text='Return to feed'
                    variant='secondary'
                  />
                </div>
              ) : (
                <div aria-label="Login and registation" className="flex items-center gap-4 ml-4">
                  <Button
                    linkButton={{ id: 'register', url: registerUrl }}
                    text='Register'
                    variant='secondary'
                  />

                  <Button
                    linkButton={{ id: 'login', url: '/login' }}
                    text='Login'
                    variant='tertiary'
                  />
                </div>
              ))}
          </div>
        </div>
      </div>

      {isMobileMenuOpen && <PublicMobileMenu />}

      {isDropDownMenuOpen && selectedDropDown && (
        <MenuDropdown className="none xl:flex top-full" onOpenChange={handleMenuClose}>
          <div className="container-md py-12">
            <ul className="flex m-0">
              {selectedDropDown.subMenuItems?.map(({ description, href, id, label }) => (
                <li key={id} className="flex flex-col mr-24">
                  <span className="font-body weight-800 h6 mb-6">{label}</span>
                  <NextLink className="color-accent font-body text-underline weight-700" href={href}>
                    {description}
                  </NextLink>
                </li>
              ))}
            </ul>
          </div>
        </MenuDropdown>
      )}
    </header>
  );
};

export { PublicHeader };
