import { useEffect, useState } from 'react';

const useIsMounted = (): { isMounted: boolean } => {
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return { isMounted };
};

export { useIsMounted };
