import { IUpdateUserAboutOptions } from './useUpdateUserAbout.interface';

import { bonoboClient } from '../../apollo';
import { useUpdateUserAboutMutation } from '../../bonobo/generated.graphql';

export const useUpdateUserAbout = (options?: IUpdateUserAboutOptions) => {
  const { onCompleted, onError } = options ?? ({} as IUpdateUserAboutOptions);

  const [updateUserAbout, { loading: isUpdateUserAboutLoading, error }] = useUpdateUserAboutMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { updateUserAbout, isUpdateUserAboutLoading, error };
};
