import { ChangeEvent, useState } from 'react';

import { Button, Dialog, Input, Textarea, ToggleSwitch } from '@netfront/ui-library';

import { IProfileDetails, ProfileDetailsDialogProps } from './ProfileDetailsDialog.interfaces';

export const ProfileDetailsDialog = ({
  about,
  displayedName,
  firstName,
  lastName,
  isOpen,
  isOnline = false,
  isSubmitting = false,
  onClose,
  onConfirm,
  title,
}: ProfileDetailsDialogProps) => {
  const [details, setDetails] = useState<IProfileDetails>({
    about,
    displayedName,
    firstName,
    lastName,
    isOnline,
  });

  const onDetailsInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = event;

    setDetails({
      ...details,
      [name]: value,
    });
  };

  return (
    <Dialog additionalClassNames="c-profile-details-dialog" isOpen={isOpen} title={title ?? 'Edit your profile'} onClose={onClose}>
      <form>
        <ToggleSwitch
          id="isOnline"
          isChecked={Boolean(details.isOnline)}
          labelText="Appear online"
          onChange={({ target: { checked } }) => {
            setDetails({
              ...details,
              isOnline: checked,
            });
          }}
        />
        <Input
          id="displayedName"
          labelText="Display name"
          name="displayedName"
          type="text"
          value={details.displayedName}
          onChange={onDetailsInputChange}
        />
        <Input
          id="firstName"
          labelText="First name"
          name="firstName"
          type="text"
          value={details.firstName}
          onChange={onDetailsInputChange}
        />
        <Input id="lastName" labelText="Last name" name="lastName" type="text" value={details.lastName} onChange={onDetailsInputChange} />
        <Textarea id="about" labelText="About" name="about" value={details.about} onChange={onDetailsInputChange} />
        <Button
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          text="Save"
          onClick={() => {
            onConfirm(details);
          }}
        />
      </form>
    </Dialog>
  );
};
