import { HintBoxProps } from './HintBox.interfaces';

const HintBox = ({ children }: HintBoxProps) => {
  return (
    <div className="relative md:ml-12">
      <div className="bg-white border-3 border-accent p-8 radius radius-tl-none md:mt-0 mt-12">
        <div className="absolute items-center bg-accent border-3 border-accent flex h-12 justify-center left-0 md:radius-left md:radius-right-0 md:-translate-x-100 p-1 p-2 radius-bottom-0 radius-left radius-top top-0 w-12 -translate-y-100">
          <img alt="Helpful hint" loading="lazy" src="/images/icon-hint.svg" />
        </div>
        {children}
      </div>
    </div>
  );
};

export { HintBox };
