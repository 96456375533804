import { ChangeEvent, useEffect, useState } from 'react';

import { PasswordInput } from '@netfront/ui-library';

import { PasswordInputWithValidatorProps, StringPasswordItem } from './PasswordInputWithValidator.interfaces';

import { usePasswordValidation } from '../../hooks';

const PasswordInputWithValidator = ({ onChange: onChangeProp }: PasswordInputWithValidatorProps) => {
  const [firstPassword, setFirstPassword] = useState<string>('');
  const [secondPassword, setSecondPassword] = useState<string>('');

  const { hasMinimumLength, hasNumber, hasSpecialCharacter, hasUpperAndLowerCase, isMatch } = usePasswordValidation({
    firstPassword,
    secondPassword,
  });

  const stringPasswordData: StringPasswordItem[] = [
    {
      label: 'Passwords match',
      inValidText: 'Both password do not match',
      successText: 'Both passwords match',
      isValid: Boolean(firstPassword) && Boolean(secondPassword) && isMatch,
    },
    {
      label: 'Minimum 8 characters',
      inValidText: 'Password does not have the minimum character amount',
      successText: 'Password has minimum character amount',
      isValid: hasMinimumLength,
    },
    {
      label: 'Number (0-9)',
      inValidText: 'Password does not contains a number',
      successText: 'Password contains a number',
      isValid: hasNumber,
    },
    {
      label: 'Lowercase & uppercase',
      inValidText: 'Password does not contain both an uppercase and lowercase character',
      successText: 'Password contains an uppercase and lowercase character',
      isValid: hasUpperAndLowerCase,
    },
    {
      label: 'Special character (!@#$%^&*)',
      inValidText: 'Password does not contain at least one special character',
      successText: 'Password contains a special character',
      isValid: hasSpecialCharacter,
    },
  ];

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { id, value },
    } = event;

    switch (id) {
      case 'password':
        setFirstPassword(value);
        break;
      case 'confirmPassword':
        setSecondPassword(value);
        break;
    }
  };

  useEffect(() => {
    onChangeProp({
      firstPassword,
      secondPassword,
      isPasswordValid: hasMinimumLength && hasNumber && hasSpecialCharacter && hasUpperAndLowerCase && isMatch,
    });
  }, [firstPassword, secondPassword, hasMinimumLength, hasNumber, hasSpecialCharacter, hasUpperAndLowerCase, isMatch, onChangeProp]);

  return (
    <>
      <PasswordInput additionalClassNames="border-primary mb-4" id="password" labelText="Password" name="password" onChange={onChange} />
      <PasswordInput
        additionalClassNames="border-primary"
        id="confirmPassword"
        labelText="Confirm password"
        name="confirmPassword"
        onChange={onChange}
      />

      <ul className="ml-0 mt-4 mb-8">
        {stringPasswordData.map(({ isValid, label, successText }, key) => (
          <li key={key} className={`flex items-center ml-0 ${isValid ? 'color-primary' : ''}`}>
            <span className="sr-only">{isValid ? successText : successText}</span>
            <svg
              aria-hidden="true"
              className="w-4 h-4 mr-2 transition-200"
              height="41"
              id="tick-icon"
              viewBox="0 0 41 41"
              width="41"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20.5" cy="20.5" data-name="Ellipse 891" fill={isValid ? '#00a4b8' : '#c7ccd8'} id="Ellipse_891" r="20.5" />
              <path
                d="M2633.267,588.9l7.442,7.037,10.224-15.154"
                data-name="Path 43408"
                fill="none"
                id="Path_43408"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="5"
                transform="translate(-2621.6 -567.859)"
              />
            </svg>
            <span className="text-sm mb-0">{label}</span>
          </li>
        ))}
      </ul>
    </>
  );
};

export { PasswordInputWithValidator };
