import { DBContentSnippetTypeNameType } from '@netfront/ekardo-content-library';

import { ToolBoxMediaType } from './useToolBoxMedia.types';

const TOOLBOX_MEDIA_TYPE_CONTENT_SNIPPET_TYPE_MAP: Record<ToolBoxMediaType, DBContentSnippetTypeNameType[]> = {
  audio: ['ContentSnippetAudioType'],
  documents: ['ContentSnippetDocumentType'],
  video: ['ContentSnippetEmbedType', 'ContentSnippetVideoType'],
};

const USERFLOW_IDS = [39, 40, 41];
export { TOOLBOX_MEDIA_TYPE_CONTENT_SNIPPET_TYPE_MAP, USERFLOW_IDS };
