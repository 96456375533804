import NextLink from 'next/link';

export const AppStoreLinks = () => (
  <div className="flex items-center">
    <NextLink href="/" legacyBehavior>
      <a
        className="inline-block mr-2"
        href="https://play.google.com/store/apps/details?id=com.breathingspacecommunity.netfront&hl=en_AU&gl=US"
        rel="noreferrer"
        target="_blank"
      >
        <img alt="Google play store" className="h-12 max-w-40" src="/images/breathing-space/google-play-icon.svg" />
      </a>
    </NextLink>
    <NextLink href="/" legacyBehavior>
      <a
        className="inline-block"
        href="https://apps.apple.com/au/app/breathing-space-community/id1106738279"
        rel="noreferrer"
        target="_blank"
      >
        <img alt="Apple app store" className="h-12 max-w-40" src="/images/breathing-space/app-store-icon.svg" />
      </a>
    </NextLink>
  </div>
);
