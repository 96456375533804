import { IGetAllPublicCommunitiesOption } from './useGetAllPublicCommunities.interface';

import { bonoboClient } from '../../apollo';
import { CommunityConnection, useGetAllPublicCommunitiesLazyQuery } from '../../bonobo/generated.graphql';

export const useGetAllPublicCommunities = (options?: IGetAllPublicCommunitiesOption) => {
  const { onCompleted, onError } = options ?? ({} as IGetAllPublicCommunitiesOption);

  const [getAllPublicCommunities, { loading: isLoading, error, refetch }] = useGetAllPublicCommunitiesLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      const publicCommunities = data.community?.allPublic as CommunityConnection;

      onCompleted(publicCommunities);
    },
    onError,
  });

  return { getAllPublicCommunities, isLoading, error, refetch };
};
