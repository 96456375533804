import { UserConnectionsProps } from './UserConnections.interfaces';

const UserConnections = ({ communitiesCount, connectionsCount }: UserConnectionsProps) => {
  return (
    <div aria-label="User connections" className="c-user-connections">
      <span className="c-user-connections__count">{connectionsCount} Connections</span>
      <span className="c-user-connections__count">{communitiesCount} Communities</span>
    </div>
  );
};

export { UserConnections };
