import { IconEmotionProps } from './IconEmotion.interfaces';

const IconEmotion = ({ isActive = false }: IconEmotionProps) => {
  return isActive ? (
    <svg height="35" id="emotion-inactive" viewBox="0 0 35 35" width="35" xmlns="http://www.w3.org/2000/svg">
      <rect data-name="Rectangle 3541" fill="#48756f" height="35" id="Rectangle_3541" rx="8" width="35" />
      <g data-name="Group 48592" id="Group_48592" transform="translate(7.59 8.089)">
        <circle cx="9.911" cy="9.911" data-name="Ellipse 209" fill="#fff" id="Ellipse_209" opacity="0.5" r="9.911" />
        <path
          d="M-620.435-849.509a1.238,1.238,0,0,1,1.239-1.239,1.238,1.238,0,0,1,1.239,1.239,1.239,1.239,0,0,1-1.239,1.239A1.239,1.239,0,0,1-620.435-849.509Zm7.433,0a1.238,1.238,0,0,1,1.239-1.239,1.238,1.238,0,0,1,1.239,1.239,1.239,1.239,0,0,1-1.239,1.239A1.239,1.239,0,0,1-613-849.509Zm1.241,5.948,1.594.956s-3.057,3.007-5.312,3.007a6.19,6.19,0,0,1-5.312-3.007l1.594-.956a4.333,4.333,0,0,0,3.718,2.1A4.333,4.333,0,0,0-611.761-843.562Z"
          data-name="Path 412"
          fill="#48756f"
          id="Path_412"
          transform="translate(625.391 855.704)"
        />
      </g>
    </svg>
  ) : (
    <svg height="35" id="emotion-active" viewBox="0 0 35 35" width="35" xmlns="http://www.w3.org/2000/svg">
      <rect data-name="Rectangle 3541" fill="#6dc7be" height="35" id="Rectangle_3541" rx="8" width="35" />
      <g data-name="Group 48592" id="Group_48592" transform="translate(7.59 8.089)">
        <circle cx="9.911" cy="9.911" data-name="Ellipse 209" fill="#fff" id="Ellipse_209" r="9.911" />
        <path
          d="M-620.435-849.509a1.238,1.238,0,0,1,1.239-1.239,1.238,1.238,0,0,1,1.239,1.239,1.239,1.239,0,0,1-1.239,1.239A1.239,1.239,0,0,1-620.435-849.509Zm7.433,0a1.238,1.238,0,0,1,1.239-1.239,1.238,1.238,0,0,1,1.239,1.239,1.239,1.239,0,0,1-1.239,1.239A1.239,1.239,0,0,1-613-849.509Zm1.241,5.948,1.594.956s-3.057,3.007-5.312,3.007a6.19,6.19,0,0,1-5.312-3.007l1.594-.956a4.333,4.333,0,0,0,3.718,2.1A4.333,4.333,0,0,0-611.761-843.562Z"
          data-name="Path 412"
          fill="#6dc7be"
          id="Path_412"
          transform="translate(625.391 855.704)"
        />
      </g>
    </svg>
  );
};

export { IconEmotion };
