import { ButtonClasses, ButtonSize, ButtonTheme } from './Button.types';

const BASE_STYLES = 'items-center font-body inline-flex justify-center text-center pointer px-4 rounded weight-700';

const BUTTON_FOCUS_CLASSES: Record<ButtonTheme, string> = Object.freeze({
  blue: 'focus:bg-blue-800 focus:border-white focus:color-white focus:ring-blue-800',
  default: 'focus:bg-accent focus:border-white focus:color-white focus:ring-accent',
  red: 'focus:bg-red-900 focus:border-white focus:color-white focus:ring-red-900',
  darkGreen: '',
  green: '',
  blueOutline: 'focus:bg-blue-700 focus:border-white focus:color-white focus:ring-blue-800',
  pink: '',
  greenOutline: 'focus:bg-primary focus:border-white focus:color-white focus:ring-primary',
  whiteOutline: '',
});

const BUTTON_SIZE_CLASSES: Record<ButtonSize, string> = Object.freeze({
  auto: 'h-auto min-w-auto',
  default: 'h-14 min-w-26',
  lg: '',
  md: '',
  sm: '',
  xl: '',
});

const BUTTON_CLASSES: ButtonClasses = Object.freeze({
  blue: `${BASE_STYLES} bg-blue-700 border-2 border-blue-700 color-white hover:bg-blue-800 hover:color-white ${BUTTON_SIZE_CLASSES.default} ${BUTTON_FOCUS_CLASSES.blue}`,
  default: `${BASE_STYLES} bg-primary border-2 border-primary color-white hover:color-white ${BUTTON_SIZE_CLASSES.default} ${BUTTON_FOCUS_CLASSES.default}`,
  red: `${BASE_STYLES} bg-red-800 border-2 border-red-800 color-white hover:bg-red-900 ${BUTTON_FOCUS_CLASSES.red} ${BUTTON_SIZE_CLASSES.default}`,
  green: `${BASE_STYLES} bg-green-400 border-2 border-green-400 color-white ${BUTTON_SIZE_CLASSES.default}`,
  darkGreen: `${BASE_STYLES} bg-green-900 border-2 border-green-900 color-white ${BUTTON_SIZE_CLASSES.default}`,
  blueOutline: `${BASE_STYLES} bg-white border-2 border-blue-700 color-blue-700 hover:bg-blue-700 hover:color-white ${BUTTON_SIZE_CLASSES.default} ${BUTTON_FOCUS_CLASSES.blueOutline}`,
  pink: `${BASE_STYLES} bg-pink-400 border-2 border-pink-400 color-white ${BUTTON_SIZE_CLASSES.default}`,
  greenOutline: `${BASE_STYLES} bg-white border-2 border-primary color-primary hover:bg-primary hover:color-white ${BUTTON_SIZE_CLASSES.default} ${BUTTON_FOCUS_CLASSES.blueOutline}`,
  whiteOutline: `${BASE_STYLES} bg-transparent border-2 border-white color-white hover:bg-white hover:color-white ${BUTTON_SIZE_CLASSES.default}`,
});

export { BASE_STYLES, BUTTON_CLASSES, BUTTON_FOCUS_CLASSES, BUTTON_SIZE_CLASSES };
