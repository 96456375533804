import { ConnectionGraphType, EConnectionStatus } from '../../../../services';

export const getConnectionsByStatus = (connectionStatus: EConnectionStatus, userConnections?: ConnectionGraphType[]) => {
  const connectionList = userConnections ?? ([] as ConnectionGraphType[]);
  return connectionList.filter(({ connectionStatus: status }) => status === connectionStatus);
};

export const getAcceptedConnections = (userConnections?: ConnectionGraphType[]): ConnectionGraphType[] => {
  return getConnectionsByStatus(EConnectionStatus.Accepted, userConnections);
};

export const getIncomingConnectionRequests = (userId: number, userConnections?: ConnectionGraphType[]) => {
  const requestedConnections = getConnectionsByStatus(EConnectionStatus.Requested, userConnections);
  return requestedConnections.filter(({ userId: id }) => id !== userId);
};

export const getOutgoingConnectionRequests = (userId: number, userConnections?: ConnectionGraphType[]) => {
  const requestedConnections = getConnectionsByStatus(EConnectionStatus.Requested, userConnections);
  return requestedConnections.filter(({ userId: id }) => id === userId);
};
