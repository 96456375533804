import { GET_COMMUNITY_POSTS_QUERY } from './useGetCommunityPosts.graphql';
import {
  IHandleGetCommunityPostsParams,
  IGetCommunityPostsQueryGraphQLResponse,
  IUseGetCommunityPosts,
  IUseGetCommunityPostsOptions,
} from './useGetCommunityPosts.interfaces';

import { useBonoboLazyQuery } from '../../../hooks';

const useGetCommunityPosts = (options?: IUseGetCommunityPostsOptions): IUseGetCommunityPosts => {
  const { fetchPolicy, onCompleted, onError, product, query, token } = options ?? ({} as IUseGetCommunityPostsOptions);

  const [executeGetCommunityPosts, { loading: isLoading }] = useBonoboLazyQuery<IGetCommunityPostsQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          post: {
            getCommunityPosts: { edges },
          },
        } = data;

        onCompleted({
          communityPosts: edges,
        });
      },
      onError,
    },
    product,
    query: query ?? GET_COMMUNITY_POSTS_QUERY,
    token,
  });

  const handleGetCommunityPosts = async (params?: IHandleGetCommunityPostsParams) => {
    const { after, first, communityId } = params ?? {};

    await executeGetCommunityPosts({
      variables: {
        after,
        first,
        communityId,
      },
    });
  };

  return {
    handleGetCommunityPosts,
    isLoading,
  };
};

export { useGetCommunityPosts };
