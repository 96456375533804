/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloCache, DefaultContext, OperationVariables } from '@apollo/client';
import { getApolloLink, useApolloMutation, useCookie } from '@netfront/common-library';

import { IUseEkardoMutationInputVariables } from './useBonoboMutation.interfaces';

const useBonoboMutation = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>({
  apolloLink,
  mutation,
  options,
  product,
  token,
}: IUseEkardoMutationInputVariables<TData, TVariables, TContext, TCache>) => {
  const { getAccessTokenCookie, getTemporaryTokenCookie } = useCookie();

  const accessToken = getAccessTokenCookie();
  const temporaryToken = getTemporaryTokenCookie();

  const authenticationLink = getApolloLink({
    apiName: 'bonobo',
    product,
    token: token ?? accessToken ?? temporaryToken,
  });

  return useApolloMutation<TData, TVariables, TContext, TCache>({
    apiName: 'bonobo',
    apolloLink: apolloLink ?? authenticationLink,
    mutation,
    options,
  });
};

export { useBonoboMutation };
