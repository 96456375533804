export const INSTANT_GROUP_ID = 2103;
export const DELAYED_GROUP_ID = 2121;

export const DELAY_DURATION = {
  weeks: 8,
};

export const FOLLOW_UP_ONE_STEP_ID = 224;
export const FOLLOW_UP_TWO_STEP_ID = 225;
export const FOLLOW_UP_DELAYED_PRE_STEP_ID = 231;
