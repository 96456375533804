import { useEffect, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { useGoogleAnalytics } from '@netfront/ekardo-content-library';
import { useUser } from '@netfront/gelada-identity-library';
import { Button, Dialog } from '@netfront/ui-library';
import { useRouter } from 'next/router';
import { shouldTrackAnalytics } from 'utils';

import { DBUser, DBUserConnection, ESocialAnalyticEvents } from '../../../../interfaces';
import { CreateUserConnectionMutation, EConnectionStatus, useCreateUserConnection, useGetUserByKey } from '../../../../services';
import { BaseLayoutPage, EmptyMessage, Feed, IconLock, PageBanner, UserConnections } from '../../../Social';

const UserProfilePage = () => {
  const {
    push,
    query: { userKey },
  } = useRouter();
  const { trackEvent } = useGoogleAnalytics();

  const loggedUserId = useUser().getUser();
  const { isToggled: isConnectDialogOpen, toggle: toggleConnectDialog } = useToggle();

  const [user, setUser] = useState<DBUser>();

  const handleGetUserInformationCompleted = (returnedUser: DBUser) => {
    if (user) {
      return;
    }

    setUser(returnedUser);
  };

  const handleCreateUserConnectionCompleted = (userConnection: CreateUserConnectionMutation) => {
    if(shouldTrackAnalytics()) {
      trackEvent(ESocialAnalyticEvents.SocialConnectionRequest, {
        requestedUserId: loggedUserId
      })
    };
    
    setUser({
      ...user,
      userConnection: userConnection.userConnection?.create as DBUserConnection,
    } as DBUser);

    toggleConnectDialog();
  };

  const { getUserByKey, isLoading: isGetUserByKeyLoading } = useGetUserByKey({
    onCompleted: handleGetUserInformationCompleted,
  });

  const { createUserConnection } = useCreateUserConnection({
    onCompleted: handleCreateUserConnectionCompleted,
  });

  useEffect(() => {
    if (user) {
      return;
    }

    getUserByKey({
      variables: {
        key: String(userKey),
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userKey]);

  useEffect(() => {
    if (!loggedUserId || !userKey) {
      return;
    }

    if (loggedUserId.id === Number(userKey)) {
      push(`/social/profile`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserId, userKey]);

  return (
    <BaseLayoutPage
      breadcrumbItems={[{ label: 'Profile' }]}
      meta={{ seoDescription: `Your profile`, seoTitle: `${user?.displayedName ?? 'User profile'}` }}
      title={`${user?.displayedName ?? 'User profile'}`}
    >
      {user && (
        <>
          <PageBanner
            bannerImage={user.coverImage?.presignedUrl}
            description={user.about}
            profileImage={user.profileImage?.presignedUrl}
            title={user.displayedName}
          >
            <UserConnections communitiesCount={user.activeCommunities.totalCount} connectionsCount={user.connections.totalCount} />

            {!isGetUserByKeyLoading && !user.userConnection && <Button text="Connect" onClick={toggleConnectDialog} />}

            {!isGetUserByKeyLoading && user.userConnection?.connectionStatus === EConnectionStatus.Requested && (
              <Button text="Request sent" isDisabled onClick={toggleConnectDialog} />
            )}
          </PageBanner>

          <div className="c-user-profile-page__container">
            {user.userConnection?.connectionStatus === EConnectionStatus.Accepted && <Feed authorId={Number(user.id)} />}

            {!isGetUserByKeyLoading && user.userConnection?.connectionStatus !== EConnectionStatus.Accepted && (
              <EmptyMessage icon={() => <IconLock />} message={`Connect to view ${String(user.displayedName)}'s activity`} />
            )}
          </div>

          <Dialog
            isOpen={isConnectDialogOpen}
            title={`Connect with ${String(user.displayedName)}`}
            onCancel={toggleConnectDialog}
            onClose={toggleConnectDialog}
            onConfirm={() => {
              createUserConnection({
                variables: {
                  friendId: user.id,
                },
              });
            }}
          >
            <p>Are you sure you want to connect with {user.displayedName}?</p>
          </Dialog>
        </>
      )}
    </BaseLayoutPage>
  );
};

export { UserProfilePage };
