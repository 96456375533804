import { CreateUserAssetOptions } from './useCreateUserAsset.interface';

import { bonoboClient } from '../../apollo';
import { useCreateUserAssetMutation } from '../../bonobo/generated.graphql';

export const useCreateUserAsset = (options?: CreateUserAssetOptions) => {
  const { onCompleted, onError } = options ?? ({} as CreateUserAssetOptions);

  const [createUserAsset, { loading: isLoading, error }] = useCreateUserAssetMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { createUserAsset, isLoading, error };
};
