import { gql } from '@apollo/client';

export const CreatePostAsset = gql`
  mutation createPostAsset(
    $type: EAssetType!
    $fileName: String!
    $contentType: String!
    $alt: String
    $fileSizeInBytes: Int!
    $postId: Int!
    $shouldIncludeAsset: Boolean = false
  ) {
    asset {
      createPostAsset(
        asset: {
          type: $type
          fileName: $fileName
          contentType: $contentType
          alt: $alt
          fileSizeInBytes: $fileSizeInBytes
          postId: $postId
        }
      ) {
        signedUrl
        asset @include(if: $shouldIncludeAsset) {
          assetId
          contentType
          fileName
          fileSizeInBytes
          messId
          postId
          presignedUrl
          s3Key
          uploaded
        }
      }
    }
  }
`;
