import { gql } from '@apollo/client';
import { LOGIN_FRAGMENT } from '@netfront/gelada-identity-library';

const LOGIN_WITH_AUTHENTICATION_TOKEN = gql`
  query login(
    $token: String!
   $shouldIncludeOrganisationLogo: Boolean = false
    $shouldIncludeProjectDomain: Boolean = false
    $shouldIncludeProjectLogo: Boolean = false
    $shouldIncludeProjectSettings: Boolean = false
    $shouldIncludeProjectTheme: Boolean = false
    $shouldIncludeUserAvatar: Boolean = false
    $shouldIncludeUserCredential: Boolean = true
    $shouldIncludeUserCustomFields: Boolean = false
    $shouldIncludeUserMembershipsGroup: Boolean = true
    $shouldIncludeUserMembershipsProject: Boolean = true
    $shouldIncludeUserMembershipsUnit: Boolean = false
    $shouldIncludeUserMembershipsUserType: Boolean = false
    $shouldIncludeUserPictures: Boolean = false
    $shouldIncludeUserMemberships: Boolean = false
  ) {
    loginWithAuthenticationToken(token: $token) {
      ...loginFragment
    }
  }

  ${LOGIN_FRAGMENT}
`;

export { LOGIN_WITH_AUTHENTICATION_TOKEN };
