import { MobileMenuTriggerProps } from './MobileMenuTrigger.interfaces';

import { getValidClassNames } from '../../../utils';
import { CrossIcon, MenuIcon } from '../../Icons';

const MobileMenuTrigger = ({ isOpen, onPress }: MobileMenuTriggerProps) => {
  return (
    <button
      aria-expanded={isOpen}
      className={getValidClassNames({
        'items-center bg-transparent border-none flex justify-center ml-auto pointer xl:none p-0 color-white': true,
      })}
      aria-haspopup
      onClick={onPress}
    >
      <span className="sr-only">{`${isOpen ? 'Close' : 'Open'} side navigation`}</span>
      {isOpen ? <CrossIcon /> : <MenuIcon />}
    </button>
  );
};

export { MobileMenuTrigger };
