import { IRootMenuItem, PUBLIC_MENU } from 'components/Header';

export const FOOTER_MENU_ITEMS: IRootMenuItem[] = [
  {
    id: 1,
    href: '/terms',
    label: 'Terms of use',
  },
  {
    id: 2,
    href: '/code-of-conduct',
    label: 'Code of conduct',
  },
  {
    id: 3,
    href: '/privacy',
    label: 'Privacy policy statement',
  },
  {
    id: 4,
    href: '/contact',
    label: 'Contact us',
  },
];

export const PUBLIC_FOOTER_MENU_ITEMS: IRootMenuItem[] = [
  ...PUBLIC_MENU,
  { href: '/login', id: 'login', label: 'Login' },
  { href: '/register', id: 'register', label: 'Register' },
];

export const LEGAL_FOOTER_MENU_ITEMS: IRootMenuItem[] = [
  { href: '/privacy', id: 'privacy', label: 'Privacy' },
  { href: '/terms', id: 'terms', label: 'Terms' },
  { href: '/code-of-conduct', id: 'code-of-conduct', label: 'Code of conduct' },
];
