import { CreatePostAssetOptions } from './useCreatePostAsset.interface';

import { bonoboClient } from '../../apollo';
import { useCreatePostAssetMutation } from '../../bonobo/generated.graphql';

export const useCreatePostAsset = (options?: CreatePostAssetOptions) => {
  const { onCompleted, onError } = options ?? ({} as CreatePostAssetOptions);

  const [executeCreatePostAsset, { loading: isLoading, error }] = useCreatePostAssetMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { executeCreatePostAsset, isLoading, error };
};
