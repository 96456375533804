import { GetUserCommunitiesQueryResult, IGetCommunitiesByUserOption } from './useGetCommunitiesByUser.interface';

import { bonoboClient } from '../../apollo';
import { useGetCommunitiesByUserLazyQuery } from '../../bonobo/generated.graphql';

export const useGetCommunitiesByUser = (options?: IGetCommunitiesByUserOption) => {
  const { onCompleted, onError } = options ?? ({} as IGetCommunitiesByUserOption);

  const [getUserCommunities, { loading: isLoading, error, refetch }] = useGetCommunitiesByUserLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      const communities = data.community?.userCommunities;
      onCompleted(communities?.edges as GetUserCommunitiesQueryResult[], communities?.totalCount ?? 0);
    },
    onError,
  });

  return { getUserCommunities, isLoading, error, refetch };
};
