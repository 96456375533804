import { useState } from 'react';

export const useReactionToolbar = () => {
  const [isReactionToolbarOpen, setIsReactionToolbarOpen] = useState<boolean>(false);

  const closeReactToolBar = () => setIsReactionToolbarOpen(false);
  const openReactToolBar = () => setIsReactionToolbarOpen(true);

  return {
    isReactionToolbarOpen,
    closeReactToolBar,
    openReactToolBar,
  };
};
