import { Dropdown } from '@netfront/ui-library';
import { getValidClassNames } from 'utils';

import { DropdownMenuProps } from './DropdownMenu.interfaces';

const DropdownMenu = ({ additionalClassNames, dropdownId, isDisplayContent, onDisplayContent, children, trigger }: DropdownMenuProps) => {
  return (
    <Dropdown
      additionalClassNames={getValidClassNames({
        'c-dropdown-menu': true,
        [String(additionalClassNames)]: Boolean(additionalClassNames),
      })}
      dropdownId={dropdownId}
      isDisplayContent={isDisplayContent}
      trigger={trigger}
      onDisplayContent={onDisplayContent}
    >
      <div className="c-dropdown-menu__dropdown">{children}</div>
    </Dropdown>
  );
};

export { DropdownMenu };
