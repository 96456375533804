import { useState } from 'react';

import { Dialog } from '@netfront/ui-library';

import { RadioTabs } from '../RadioTabs';

import { POSITIVE_FEELINGS, NEGATIVE_FEELINGS, NEUTRAL_FEELINGS, ALL_POST_FEELINGS } from './PostFeelingsDialog.constants';
import { EFeelingType, FeelingsItemType, PostFeelingsDialogProps } from './PostFeelingsDialog.interfaces';

import { EPostFeeling } from '../../../services';
import { getValidClassNames } from '../../../utils';

const PostFeelingsDialog = ({ isOpen, onClose, selectedFeeling = EPostFeeling.None, onFeelingChange }: PostFeelingsDialogProps) => {
  const [selectedFeelingType, setSelectedFeelingType] = useState<EFeelingType>(EFeelingType.All);
  const [updatedFeeling, setUpdatedFeeling] = useState<EPostFeeling>(selectedFeeling);

  const feelingsMap: Record<EFeelingType, FeelingsItemType[]> = {
    [EFeelingType.All]: [...ALL_POST_FEELINGS],
    [EFeelingType.Positive]: [...POSITIVE_FEELINGS],
    [EFeelingType.Neutral]: [...NEUTRAL_FEELINGS],
    [EFeelingType.Negative]: [...NEGATIVE_FEELINGS],
  };

  return (
    <Dialog
      additionalClassNames="c-post-feelings-dialog"
      isOpen={isOpen}
      title="How are you feeling?"
      onClose={onClose}
      onConfirm={() => onFeelingChange(updatedFeeling)}
    >
      <RadioTabs
        additionalClassNames="c-post-feelings-dialog__tabs"
        items={[
          { id: 'all', labelText: 'All', value: EFeelingType.All },
          { id: 'positive', labelText: 'Positive', value: EFeelingType.Positive },
          { id: 'neutral', labelText: 'Neutral', value: EFeelingType.Neutral },
          { id: 'negative', labelText: 'Negative', value: EFeelingType.Negative },
        ]}
        name="query"
        selectedValue={selectedFeelingType}
        onChange={({ target: { value } }) => setSelectedFeelingType(value as EFeelingType)}
      />

      <div className="c-post-feelings-dialog__feelings">
        {feelingsMap[selectedFeelingType].map(({ emoji, name, type }, key) => (
          <button
            key={key}
            className={getValidClassNames({
              'c-post-feelings-dialog__item': true,
              'c-post-feelings-dialog__item--selected': type === updatedFeeling,
            })}
            onClick={() => setUpdatedFeeling(type)}
          >
            <span className="c-post-feelings-dialog__emoji">{emoji}</span>
            <span className="c-post-feelings-dialog__name">{name}</span>
          </button>
        ))}
      </div>
    </Dialog>
  );
};

export { PostFeelingsDialog };
