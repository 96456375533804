export const INELIGIBLE_CONTENT_PAGE_IDS = {
  demographics: 124661,
  treatment: 124662,
  supportRefferal: 124663,
  lifelineRefferal: 124664,
};

export const TREATMENT_COMPLETION_DATE_EMAIL_TEMPLATE_ID = 180;

export const BASELINE_STEP_ID = 226;
export const CONSENT_STEP_ID = 227;
