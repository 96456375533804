import { IconCross } from '../Icons';

import { ImagePreviewProps } from './ImagePreview.interfaces';

export const ImagePreview = ({ src, onClick }: ImagePreviewProps) => {
  return (
    <div className="c-image-preview">
      <img alt="Post image preview" className="c-image-preview__image" src={src} />
      <button className="c-image-preview__button" title="Remove image" onClick={onClick}>
        <IconCross additionalClassNames="c-image-preview__icon" />
        <span className="h-hide-visually">Cancel image</span>
      </button>
    </div>
  );
};
