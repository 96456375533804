import { useBonoboMutation } from 'hooks/useBonoboMutation';

import { JOIN_COMMUNITY } from './useJoinCommunityWithKey.graphql';
import {
  IHandleJoinCommunityWithKeyParams,
  IJoinCommunityWithKeyMutationGraphQLResponse,
  IJoinCommunityWithKeyMutationVariables,
  IUseJoinCommunityWithKeyOptions,
  IUseJoinCommunityWithKey,
} from './useJoinCommunityWithKey.interfaces';

/**@todo Move to library */

const useJoinCommunityWithKey = (options?: IUseJoinCommunityWithKeyOptions): IUseJoinCommunityWithKey => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseJoinCommunityWithKeyOptions);

  const [executeJoinCommunityWithKey, { loading: isLoading }] = useBonoboMutation<
    IJoinCommunityWithKeyMutationGraphQLResponse,
    IJoinCommunityWithKeyMutationVariables
  >({
    mutation: mutation ?? JOIN_COMMUNITY,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          communityConnection: { joinCommunityWithKey: isSuccess },
        } = data;

        onCompleted({
          result: isSuccess,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleJoinCommunityWithKey = async ({ key, projectId, accessToken }: IHandleJoinCommunityWithKeyParams) => {
    if (accessToken) {
      await executeJoinCommunityWithKey({
        variables: {
          key,
          projectId,
        },
        context: {
          headers: {
            authorization: accessToken,
          },
        },
      });
    } else {
      await executeJoinCommunityWithKey({
        variables: {
          key,
          projectId,
        },
      });
    }
  };

  return {
    handleJoinCommunityWithKey,
    isLoading,
  };
};

export { useJoinCommunityWithKey };
