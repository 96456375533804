import { IGetUserConnectionWithOtherUserOption } from './useGetUserConnectionWithOtherUser.interface';

import { DBUserConnection } from '../../../interfaces';
import { bonoboClient } from '../../apollo';
import { useGetUserConnectionWithOtherUserLazyQuery } from '../../bonobo';

export const useGetUserConnectionWithOtherUser = (options?: IGetUserConnectionWithOtherUserOption) => {
  const { onCompleted, onError } = options ?? ({} as IGetUserConnectionWithOtherUserOption);

  const [getConnection, { loading: isLoading, refetch }] = useGetUserConnectionWithOtherUserLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data.userConnection?.getUserConnection as DBUserConnection);
    },
    onError,
  });

  return { getConnection, isLoading, refetch };
};
