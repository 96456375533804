import { CircularProgressbar } from 'react-circular-progressbar';

import { ProgressCircleProps } from './ProgressCircle.interface';

import 'react-circular-progressbar/dist/styles.css';

const ProgressCircle = ({ percentageCompleted }: ProgressCircleProps) => {
  return (
    <CircularProgressbar
      styles={{
        root: {
          overflow: 'visible',
          maxWidth: '2.8rem',
          width: '100%',
        },
        path: {
          stroke: '#3a8fa2',
          strokeWidth: 12,
        },
        trail: {
          stroke: Number(percentageCompleted) === 0 ? '#B7D7DB' : '#ADE2E0',
          strokeWidth: 12,
        },
        text: {
          fill: '#1B1B46',
          fontFamily: 'century-gothic',
          fontSize: '1.6rem',
        },
      }}
      text={`${Number(percentageCompleted)}%`}
      value={Number(percentageCompleted)}
    />
  );
};

export { ProgressCircle };
