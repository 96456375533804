import { gql } from '@apollo/client';

import { IDBPostInterfaceGraphTypeFragment, IDBUserGraphTypeFragment } from '../../fragments';

export const CreatePostReport = gql`
  mutation createPostReport($postId: Int!, $type: EReportType!) {
    report {
      create(postId: $postId, type: $type) {
        id
        post {
          ...post
        }
        reporter {
          ...user
        }
      }
    }

    ${IDBPostInterfaceGraphTypeFragment}
    ${IDBUserGraphTypeFragment}

  }
 
`;
