import { gql } from '@apollo/client';

const IDBVotePollGraphTypeFragment = gql`
  fragment userVoteGraph on VoteGraphType {
    pollItemId
    userId
  }
`;

export { IDBVotePollGraphTypeFragment };
