import { createContext, ReactNode, useContext, useEffect } from 'react';

import { getAccessToken, getRefreshToken, useDomain, useLogout, useRefreshToken } from '@netfront/gelada-identity-library';
import decode, { JwtPayload } from 'jwt-decode';
import { useRouter } from 'next/router';

import { useToast } from '../hooks';

// eslint-disable-next-line @typescript-eslint/ban-types
type sessionContextType = {};

const contextDefaultValues: sessionContextType = {};

const SessionContext = createContext<sessionContextType>(contextDefaultValues);

export function useAuth() {
  return useContext(SessionContext);
}
type Props = {
  children: ReactNode;
};

function getExpiry(token: string) {
  if (!token) {
    return null;
  }

  const decodedToken: JwtPayload = decode(token);
  const expiry = Number(decodedToken.exp) * 1000;

  return new Date(expiry);
}

function addMinutes(date: Date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

function isTokenExpiredIn(token, minutes) {
  const expirationDate = getExpiry(token);
  if (!expirationDate || expirationDate < addMinutes(new Date(), minutes)) {
    return true;
  }
  return false;
}

export function SessionProvider({ children }: Props) {
  const router = useRouter();
  const { handleRefreshToken } = useRefreshToken();
  const { handleLogout } = useLogout();
  const { handleToastError, handleToastCustomError } = useToast();
  const { getDomain } = useDomain();

  const { push } = router;

  const handleTokenRefresh = () => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    if (!accessToken) return;

    if (isTokenExpiredIn(accessToken, 3) && !isTokenExpiredIn(refreshToken, 0)) {
      console.warn('Access token expired,  generation of a new pair of token');
      handleRefreshToken();
      return;
    }

    if (isTokenExpiredIn(refreshToken, 0)) {
      console.warn('Refresh token expired. Forced logout.');
      handleToastCustomError({ message: 'You have been inactive for too long. Please login.' });
      handleLogout({ domain: getDomain(), path: '/' });
      push('/').catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );
    }
  };

  useEffect(() => {
    handleTokenRefresh();
  }, [router]);

  const value = {};

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
}
