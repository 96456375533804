import { useState } from 'react';

import { useAuthentication, useDomain, useLogout } from '@netfront/gelada-identity-library';
import { Button } from '@netfront/ui-library';
import cx from 'classnames';

import { PUBLIC_MENU, IRootMenuItem } from '../../Header';
import { CaretDownIcon } from '../../Icons';
import { MenuDropdown } from '../../MenuDropdown';

const PublicMobileMenu = () => {
  const { isAuthenticated } = useAuthentication();

  const { handleLogout } = useLogout();
  const { getDomain, isDomainReady } = useDomain();
  const [selectedDropDown, setSelectedDropDown] = useState<IRootMenuItem | null>(null);

  const handleMenuPress = (item: IRootMenuItem) => {
    setSelectedDropDown(item.id === selectedDropDown?.id ? null : item);
  };

  return (
    <MenuDropdown className="relative flex flex-col h-screen overflow-y-auto top-0 xl:none">
      <div className="flex flex-1 flex-col pb-8">
        <nav className="py-8 px-4">
          <ul className="flex flex-col m-0">
            {PUBLIC_MENU.map((item) => {
              const buttonClasses = 'flex items-center color-black font-header hover:bg-grey-50 pointer px-6 py-3 rounded';

              const { href, icon, id, label, privateHref, subMenuItems = [] } = item;

              const itemHref = isAuthenticated && privateHref ? privateHref : href;

              const isActiveItem = id === selectedDropDown?.id;

              return (
                <li key={id} className="mb-0 ml-0">
                  {subMenuItems.length ? (
                    <>
                      <button
                        aria-expanded={isActiveItem}
                        className={cx('items-center', 'flex', 'w-full', buttonClasses)}
                        aria-haspopup
                        onClick={() => handleMenuPress(item)}
                      >
                        {label}
                        <div
                          className={cx(
                            'items-center',
                            'bg-grey-50',
                            'flex',
                            'justify-center',
                            'ml-auto',
                            'rounded-full',
                            'transition-200',
                            {
                              'rotate-180': isActiveItem,
                            },
                          )}
                        >
                          <CaretDownIcon />
                        </div>
                      </button>
                      {isActiveItem && (
                        <ul className="bg-grey-100 flex flex-col pb-6 pt-10 px-6 m-0">
                          {subMenuItems.map(({ description, href: subMenuHref, id: subMenuId, label: subMenuLabel }) => (
                            <li key={subMenuId} className="flex flex-col mb-12">
                              <span className="font-header h6 mb-6 weight-800">{subMenuLabel}</span>
                              <a className="color-accent font-header text-underline weight-700" href={subMenuHref}>
                                {description}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  ) : (
                    <a className={buttonClasses} href={itemHref}>
                      {icon && <div className="w-5 h-5 mr-4 flex items-center">{icon()}</div>}
                      {label}
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>

        <div className="flex-col items-center flex justify-center mb-8 mt-16">
          {!isAuthenticated ? (
            <div className='flex items-center gap-4'>
              <Button
                linkButton={{ id: 'register', url: '/register' }}
                text='Register'
              />

              <Button
                linkButton={{ id: 'login', url: '/login' }}
                text='Login'
              />
            </div>
          ) : (
            <div className='flex items-center gap-4'>
              <Button
                linkButton={{ id: 'dashboard', url: '/dashboard' }}
                text='Dashboard'
              />

              <Button
                isDisabled={!isDomainReady}
                text='Logout'
                onClick={() => handleLogout({ domain: getDomain(), path: '/' })}
              />
            </div>
          )}
        </div>

        <div className="text-center mb-16"></div>
      </div>
    </MenuDropdown>
  );
};

export { PublicMobileMenu };
