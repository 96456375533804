import { ApolloError } from '@apollo/client';
import { ContactForm, useSendContactForm } from '@netfront/gelada-identity-library';
import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/router';

// import { ContactForm } from 'components/ContactForm/ContactForm';

import { BUTTON_CLASSES, SingleFormPage } from '../../../components';
import { useToast } from '../../../hooks';

const PAGE_TITLE = 'Contact';

const ContactPage = () => {
  const { handleToastError, handleToastSuccess } = useToast();
  const { push } = useRouter();

  const sendContactFormCompleted = async (message: string, email: string, firstname: string, lastname: string) => {
    if (isEmpty(email)) {
      handleToastError({
        error: new ApolloError({ errorMessage: 'Please provide a valid email' }),
      });
      return;
    }

    handleToastSuccess({
      message: 'Thank you for your message! We will be in touch shortly.',
    });

    await handleSendContactForm({
      firstName: firstname,
      lastName: lastname,
      message: message,
      sender: email,
      confirmationEmailPlaceholders: [{ key: 'firstName', value: firstname }],
    });

    push('/').catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  const { handleSendContactForm, isLoading } = useSendContactForm();

  return (
    <SingleFormPage title={PAGE_TITLE}>
      <h1 className="color-primary h5 mb-2 text-uppercase">Get in touch</h1>
      <h2 className="color-black mb-6 text-uppercase">
        GET IN TOUCH WITH <br /> THE TEAM
      </h2>

      <ContactForm
        buttonClassName={`${BUTTON_CLASSES.green} mt-4`}
        isSubmitting={isLoading ?? false}
        onSubmit={async (email, message, firstName, lastName) => {
          await sendContactFormCompleted(message, email, firstName, lastName);
        }}
      />
    </SingleFormPage>
  );
};

export { ContactPage };
