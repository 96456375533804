import { IAdditionalClassNames } from '@netfront/ui-library';

const IconConnection = ({ additionalClassNames }: IAdditionalClassNames) => {
  return (
    <svg
      className={additionalClassNames}
      height="11.495"
      id="icon-connections-inactive"
      viewBox="0 0 18.927 11.495"
      width="18.927"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g clipPath="url(#clip-path)" data-name="Group 1421" id="Group_1421">
        <path
          d="M9.984,4.575A4.393,4.393,0,1,1,7.358,1.658.676.676,0,0,0,7.856.4a5.74,5.74,0,1,0,3.432,3.815.676.676,0,0,0-1.3.36Z"
          data-name="Path 548"
          fill="currentColor"
          fillRule="evenodd"
          id="Path_548"
          transform="translate(0 0)"
        />
        <path
          d="M6.5,6.921a4.4,4.4,0,1,1,4.237,3.222,4.357,4.357,0,0,1-1.758-.366.676.676,0,0,0-.543,1.238A5.742,5.742,0,1,0,5.2,7.28a.676.676,0,1,0,1.3-.36Z"
          data-name="Path 549"
          fill="currentColor"
          fillRule="evenodd"
          id="Path_549"
          transform="translate(2.439 0)"
        />
      </g>
    </svg>
  );
};

export { IconConnection };
