/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */

// log the pageview with their URL
export const PageView = (url: string, userId: number) => {
  if (userId) {
    (window as any).gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS, {
      page_path: url,
      user_id: userId,
    });
  } else {
    (window as any).gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS, {
      page_path: url,
    });
  }
};

// log specific events happening.
export const event = ({ action, params }) => {
  (window as any).gtag('event', action, params);
};
