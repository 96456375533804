import { gql } from '@apollo/client';

export const BaseCommentGraphTypeFragment = gql`
  fragment baseCommentGraphType on CommentGraphType {
    id
    message
    authorId
    author {
      displayedName
      firstName
      lastName
      id
      isAnonymous
    }
    createdDate
    hasRelated
    postId
    relateCount
    commentsCount
    updatedDate
    createdDateTime
  }
`;

export const IDBCommentGraphTypeFragment = gql`
  fragment commentGraphType on CommentGraphType {
    ...baseCommentGraphType
    comments {
      edges {
        node {
          ...baseCommentGraphType
        }
      }
    }
  }

  ${BaseCommentGraphTypeFragment}
`;
