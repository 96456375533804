import { gql } from '@apollo/client';

export const CreateComment = gql`
  mutation createComment($request: CreateCommentInputType!) {
    comment {
      create(request: $request) {
        id
        author {
          displayedName
          firstName
          lastName
          profileImage {
            presignedUrl
          }
        }
        comments {
          edges {
            node {
              id
              author {
                displayedName
                firstName
                lastName
                profileImage {
                  presignedUrl
                }
              }
              createdDateTime
              message
            }
          }
        }
        createdDateTime
        message
        postId
      }
    }
  }
`;
