import { GET_CONNECTIONS_BY_USER_QUERY } from './useGetConnectionsByUser.graphql';
import {
  IGetConnectionsByUserQueryGraphQLResponse,
  IUseGetConnectionsByUser,
  IUseGetConnectionsByUserOptions,
} from './useGetConnectionsByUser.interfaces';

import { useBonoboLazyQuery } from '../../../hooks';

const useGetConnectionsByUser = (options?: IUseGetConnectionsByUserOptions): IUseGetConnectionsByUser => {
  const { fetchPolicy, onCompleted, onError, product, query, token } = options ?? ({} as IUseGetConnectionsByUserOptions);

  const [executeGetUserFlows, { loading: isLoading }] = useBonoboLazyQuery<IGetConnectionsByUserQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          userConnection: { getUserConnections },
        } = data;

        onCompleted({
          userConnections: getUserConnections,
        });
      },
      onError,
    },
    product,
    query: query ?? GET_CONNECTIONS_BY_USER_QUERY,
    token,
  });

  const handleGetConnectionsByUser = async () => {
    await executeGetUserFlows();
  };

  return {
    handleGetConnectionsByUser,
    isLoading,
  };
};

export { useGetConnectionsByUser };
