export enum ESocialAnalyticEvents {
  SocialPostCreate = 'social_post_create',
  SocialPostPollVote = 'social_post_poll_vote',
  SocialPostDelete = 'social_post_delete',
  SocialPostReport = 'social_post_report',
  SocialPostRelate = 'social_post_relate',
  SocialPostComment = 'social_post_comment',
  SocialPostViewHiddenComments = 'social_post_view_hidden_comments',
  SocialPostCommentRelate = 'social_post_comment_relate',
  SocialTopicSubscribe = 'social_topic_subscribe',
  SocialTopicUnSubscribe = 'social_topic_unsubscribe',
  SocialConnectionRequest = 'social_connection_request',
  SocialMessageSent = 'social_message_sent'
};