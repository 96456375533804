import { ICreateConversationsOptions } from './useCreateConversations.interface';

import { bonoboClient } from '../../apollo';
import { useCreateConversationsMutation } from '../../bonobo/generated.graphql';

export const useCreateConversations = (options?: ICreateConversationsOptions) => {
  const { onCompleted, onError } = options ?? ({} as ICreateConversationsOptions);

  const [createConversations, { loading: isLoading, error }] = useCreateConversationsMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { createConversations, isLoading, error };
};
