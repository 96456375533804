import { IDeletePostRelateOptions } from './useDeletePostRelate.interface';

import { bonoboClient } from '../../apollo';
import { useDeleteRelatePostMutation } from '../../bonobo/generated.graphql';

export const useDeletePostRelate = (options?: IDeletePostRelateOptions) => {
  const { onCompleted, onError } = options ?? ({} as IDeletePostRelateOptions);

  const [deletePostRelate, { loading: isDeletePostRelateLoading, error }] = useDeleteRelatePostMutation({
    client: bonoboClient,
    onCompleted: () => {
      if (!onCompleted) {
        return;
      }
      onCompleted();
    },
    onError,
  });

  return { deletePostRelate, isDeletePostRelateLoading, error };
};
