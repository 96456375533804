import { useEffect, useState } from 'react';

import { useJoinCommunityWithKey, useToast } from 'hooks';
import { NextPage } from 'next';
import { useRouter } from 'next/router';

import { SingleFormPage } from '../../components';

export const COMMUNITY_MAPPING = [
  { code: 'breathingspace', key: 'breathingspace' },
  { code: 'ffsp', key: 'ffsp' },
  { code: 'shadeplus', key: 'shadeplus' },
  { code: 'mark-hughes-foundation', key: 'mark-hughes-foundation' },
  { code: 'vshade', key: 'vshade' },
];

export const DEFAULT_COMMUNITY = COMMUNITY_MAPPING.find(({ code }) => code === 'breathingspace');

interface IJoinCommunityProps {
  accessToken: string;
  code: string;
}

const JoinCommunity = (data: IJoinCommunityProps) => {
  const { accessToken, code } = data;
  const { push } = useRouter();

  const [attempt, setAttempt] = useState<number>(0);
  const { handleToastError, handleToastCustomError } = useToast();

  const { handleJoinCommunityWithKey } = useJoinCommunityWithKey({
    onCompleted() {
      push('/activate-account');
    },
    onError(error) {
      if (attempt > 10) {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
        push('/activate-account');
        return;
      }

      setTimeout(() => {
        setAttempt(attempt + 1);
        const community = COMMUNITY_MAPPING.find((r) => r.code === code);
        if (!community) {
          handleToastCustomError({ message: 'Community not found. Contact an administrator.' });
          push('/activate-account');
          return;
        }

        handleJoinCommunityWithKey({
          key: community.key,
          projectId: String(process.env.REACT_APP_PROJECT_ID),
        });
      }, 500);
    },
    token: accessToken,
  });

  useEffect(() => {
    if (accessToken && code) {
      const community = COMMUNITY_MAPPING.find((r) => r.code === code);
      if (!community) {
        handleToastCustomError({ message: 'Community not found. Contact an administrator.' });
        push('/activate-account');
        return;
      }

      setTimeout(() => {
        handleJoinCommunityWithKey({
          key: community.key,
          projectId: String(process.env.REACT_APP_PROJECT_ID),
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

const JoinCommunityNextPage: NextPage = () => {
  const PAGE_TITLE = 'Register for a free account';
  const { query } = useRouter();
  const { accessToken, code } = query;

  const [token, setToken] = useState<string>();
  const [key, setKey] = useState<string>();

  useEffect(() => {
    if (accessToken) setToken(String(accessToken));
    if (code) setKey(String(code));
  }, [accessToken, code]);

  return (
    <SingleFormPage meta={{ seoDescription: 'Create an account today', seoTitle: 'Register' }} title={PAGE_TITLE}>
      <h1 className="color-primary h5 mb-2 text-uppercase">Please wait...</h1>
      <h2 className="color-black mb-6 text-uppercase">
        Activating account
      </h2>


      {token && key && <JoinCommunity accessToken={token} code={key} />}
    </SingleFormPage>
  );
};

// eslint-disable-next-line import/no-default-export
export default JoinCommunityNextPage;
