import { IAdditionalClassNames } from '@netfront/ui-library';

const IconShare = ({ additionalClassNames }: IAdditionalClassNames) => {
  return (
    <svg
      className={additionalClassNames}
      height="18.586"
      id="reply-icon"
      viewBox="0 0 18.586 18.586"
      width="18.586"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.586,9.293A9.293,9.293,0,1,1,9.293,0a9.293,9.293,0,0,1,9.293,9.293" data-name="Path 556" fill="#fff" id="Path_556" />
      <path
        className="c-icon__outline"
        d="M15.753,10.722l-4.171-3.9a.213.213,0,0,0-.166-.071c-.13.006-.3.1-.3.236V8.943a.126.126,0,0,1-.106.121C6.869,9.7,5.122,12.8,4.5,16.025c-.024.127.148.245.228.145A7.538,7.538,0,0,1,11,13a.146.146,0,0,1,.124.142v1.921a.254.254,0,0,0,.429.157l4.2-3.97a.326.326,0,0,0,.1-.248A.4.4,0,0,0,15.753,10.722Z"
        data-name="Icon ionic-ios-share-alt"
        fill="none"
        id="Icon_ionic-ios-share-alt"
        stroke="#48756f"
        strokeWidth="1"
        transform="translate(-0.521 -2.826)"
      />
    </svg>
  );
};

export { IconShare };
