import { gql } from '@apollo/client';

import { IDBPostInterfaceGraphTypeFragment } from '../../fragments';

export const UpdatePost = gql`
  mutation updatePost($request: UpdatePostInputType!) {
    post {
      update(request: $request) {
        ...post
      }
    }
  ${IDBPostInterfaceGraphTypeFragment}
  }
`;
