import { useEffect, useState } from 'react';

import isEmpty from 'lodash.isempty';

import { CommunityConnection, CommunityEdge, CommunityGraphType, useGetAllPublicCommunities } from '../../../../services';
import { BaseLayoutPage, EmptyMessage, ListCard, ListCardSkeleton, SearchInput, useSearchInput } from '../../../Social';

const PublicCommunitiesPage = () => {
  const { isSearchActive, onSearchClear, onSearchSubmit, searchValue } = useSearchInput();

  const [allPublicCommunities, setAllPublicCommunities] = useState<CommunityConnection>({} as CommunityConnection);
  const [isLoadingAllPublicCommunities, setIsLoadingAllPublicCommunities] = useState<boolean>(true);

  const handleGetAllPublicCommunitiesCompleted = (publicCommunities: CommunityConnection) => {
    setAllPublicCommunities(publicCommunities);
    setIsLoadingAllPublicCommunities(false);
  };

  const { getAllPublicCommunities } = useGetAllPublicCommunities({
    onCompleted: handleGetAllPublicCommunitiesCompleted,
  });

  useEffect(() => {
    getAllPublicCommunities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSearchActive) {
      getAllPublicCommunities({
        variables: {
          filter: searchValue,
        },
      });

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchActive, searchValue]);

  const canRenderAllPublicCommunities = !isLoadingAllPublicCommunities && !isEmpty(allPublicCommunities);
  const hasNoPublicCommunities = !isLoadingAllPublicCommunities && isEmpty(allPublicCommunities);

  const allNonConnectedPublicCommunities = allPublicCommunities.edges?.filter(
    ({ node }) => node && !node.userConnection,
  ) as CommunityEdge[];

  return (
    <BaseLayoutPage
      breadcrumbItems={[{ label: 'Communities', href: '/social/communities' }, { label: 'Public' }]}
      meta={{ seoDescription: 'View all your communities', seoTitle: 'Communities' }}
      title="Communities"
    >
      <div className="c-communities-page__container">
        <SearchInput
          additionalClassNames="c-communities-page__search"
          id="search"
          isSearchActive={isSearchActive}
          labelText="Search communities"
          name="search"
          placeholder="Search communities"
          type="text"
          isLabelHidden
          onClear={() => {
            onSearchClear();
            getAllPublicCommunities();
          }}
          onSearch={onSearchSubmit}
        />

        <h1>Public communities {canRenderAllPublicCommunities && `(${allNonConnectedPublicCommunities.length})`}</h1>

        {isLoadingAllPublicCommunities && (
          <>
            <ListCardSkeleton />
            <ListCardSkeleton />
          </>
        )}

        {canRenderAllPublicCommunities &&
          allNonConnectedPublicCommunities.map(({ node }) => {
            const community = node as CommunityGraphType;
            return (
              <ListCard
                key={community.id}
                avatarImage={community.profileImage?.presignedUrl}
                avatarTitle={community.title}
                description={community.description}
                displayName={community.title}
                href={`/social/communities/${String(community.key)}`}
                hasArrow
              />
            );
          })}

        {hasNoPublicCommunities && <EmptyMessage message="No communities available" />}
      </div>
    </BaseLayoutPage>
  );
};

export { PublicCommunitiesPage };
