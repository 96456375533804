import { PostGraphType, PostInterfaceGraphType, PostShareGraphType } from '../../../services';

export interface IFeedPostsContext {
  onPostRelate?: (hasRelated: boolean, postId: number) => void;
  posts: PostInterfaceGraphType[];
}

export interface IApolloCursor {
  cursor: string;
}

export enum EFeedQuery {
  All = 'ALL',
  Members = 'MEMBERS',
  Moderators = 'MODERATORS',
}

export interface IPost extends IApolloCursor, PostGraphType, Pick<PostShareGraphType, 'originalPost'> {}

export interface FeedProps {
  authorId?: number;
  communityId?: number;
  first?: number;
  message?: string;
  postId?: number;
  postSkeletonCount?: number;
  query?: EFeedQuery;
  shouldShowCommunitiesMessage?: boolean;
  tags?: string[];
}
