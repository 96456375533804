import { Animation } from '../Animation';
import { POST_RELATE_ICONS, POST_RELATE_LABELS, RelateIcon } from '../RelateIcon';

import { ReactionToolbarProps } from './ReactionToolbar.interfaces';

import { ERelateType } from '../../../services';

const ReactionToolbar = ({ children, isReactionToolbarOpen, onMouseEnter, onMouseLeave, onRelateClick }: ReactionToolbarProps) => {
  return (
    <div className="c-reaction-toolbar__container" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {isReactionToolbarOpen ? (
        <Animation type="fade-up">
          <div aria-label="Relate toolbar" className="c-reaction-toolbar">
            {Object.keys(POST_RELATE_ICONS).map((relateType, key) => {
              return (
                <button key={key} title={POST_RELATE_LABELS[relateType]} onClick={() => onRelateClick(relateType as ERelateType)}>
                  <RelateIcon relateType={relateType as ERelateType} />
                </button>
              );
            })}
          </div>
        </Animation>
      ) : null}
      {children}
    </div>
  );
};

export { ReactionToolbar };
