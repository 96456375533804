import { IGetNotificationsOptions } from './useGetNotifications.interface';

import { howlerClient } from '../../apollo';
import { NotificationConnection, useGetNotificationsLazyQuery } from '../../howler/generated.graphql';

export const useGetNotifications = (options?: IGetNotificationsOptions) => {
  const { onCompleted, onError } = options ?? ({} as IGetNotificationsOptions);

  const [getNotifications, { loading: isLoading, error, fetchMore, refetch }] = useGetNotificationsLazyQuery({
    client: howlerClient,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      const notifications = data.notification?.getForConnectedUser as NotificationConnection;

      onCompleted(notifications, notifications.totalCount ?? 0);
    },
    onError,
  });

  return { getNotifications, isLoading, error, fetchMore, refetch };
};
