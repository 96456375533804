import { forwardRef } from 'react';

import { Spinner } from '../Spinner';

import { LoadMoreProps } from './LoadMore.interfaces';

const LoadMore = forwardRef<HTMLButtonElement, LoadMoreProps>(
  ({ fetchText = 'Load more', finishedText = 'No more available', hasReachedTotal, onClick }: LoadMoreProps, ref) => {
    return (
      <button ref={ref} className="c-load-more" disabled={hasReachedTotal} onClick={onClick}>
        {!hasReachedTotal && <Spinner />}
        <span className="c-load-more__text">{hasReachedTotal ? finishedText : fetchText}</span>
      </button>
    );
  },
);

LoadMore.displayName = 'LoadMore';

export { LoadMore };
