import { IAdditionalClassNames } from '@netfront/ui-library';

const IconNotification = ({ additionalClassNames }: IAdditionalClassNames) => {
  return (
    <svg className={additionalClassNames} id="icon-notification-inactive" viewBox="0 0 10.139 11.849" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.258,28.336a.411.411,0,0,0-.4.324.8.8,0,0,1-.159.346.6.6,0,0,1-.511.187.609.609,0,0,1-.511-.187.8.8,0,0,1-.159-.346.411.411,0,0,0-.4-.324h0a.414.414,0,0,0-.4.5,1.513,1.513,0,0,0,2.95,0,.415.415,0,0,0-.4-.5Z"
        data-name="Path 550"
        fill="#fff"
        id="Path_550"
        transform="translate(-11.125 -18.169)"
      />
      <path
        d="M16.787,12.568c-.488-.592-1.45-.939-1.45-3.589,0-2.72-1.307-3.813-2.525-4.075-.114-.026-.2-.061-.2-.172V4.648a.747.747,0,0,0-.761-.717h-.019a.747.747,0,0,0-.761.717v.085c0,.108-.082.146-.2.172C9.656,5.169,8.353,6.26,8.353,8.98c0,2.65-.961,2.994-1.45,3.589a.577.577,0,0,0,.5.927h8.885A.577.577,0,0,0,16.787,12.568Zm-1.237.169h-7.4a.127.127,0,0,1-.1-.213,3.509,3.509,0,0,0,.666-.974A6.128,6.128,0,0,0,9.168,8.98a4.191,4.191,0,0,1,.663-2.53,2.047,2.047,0,0,1,1.228-.8,1.138,1.138,0,0,0,.59-.306.266.266,0,0,1,.377-.006,1.178,1.178,0,0,0,.6.312,2.047,2.047,0,0,1,1.228.8,4.191,4.191,0,0,1,.663,2.53,6.128,6.128,0,0,0,.454,2.571,3.552,3.552,0,0,0,.682.988A.12.12,0,0,1,15.55,12.737Z"
        data-name="Path 551"
        fill="#fff"
        id="Path_551"
        transform="translate(-6.775 -3.93)"
      />
    </svg>
  );
};

export { IconNotification };
