import { useEffect, useState } from 'react';

import isEmpty from 'lodash.isempty';
import NextLink from 'next/link';

import { DBCommunity } from '../../../../interfaces';
import {
  CommunityConnection,
  CommunityGraphType,
  ECommunityRole,
  GetUserCommunitiesQueryResult,
  useGetAllPublicCommunities,
  useGetCommunitiesByUser,
} from '../../../../services';
import { formatDate } from '../../../../utils';
import { BaseLayoutPage, EmptyMessage, ListCard, ListCardSkeleton, SearchInput, useSearchInput } from '../../../Social';

const CommunitiesPage = () => {
  const { isSearchActive, onSearchClear, onSearchSubmit, searchValue } = useSearchInput();

  const [userCommunities, setUserCommunities] = useState<DBCommunity[]>([]);
  const [allPublicCommunities, setAllPublicCommunities] = useState<CommunityConnection>({} as CommunityConnection);

  const [isLoadingUserCommunities, setIsLoadingUserCommunities] = useState<boolean>(true);
  const [isLoadingAllPublicCommunities, setIsLoadingAllPublicCommunities] = useState<boolean>(true);

  const getUserCommunitiesCompleted = (communities: GetUserCommunitiesQueryResult[]) => {
    setUserCommunities(communities.map(({ node }) => node));
    setIsLoadingUserCommunities(false);
  };

  const handleGetAllPublicCommunitiesCompleted = (publicCommunities: CommunityConnection) => {
    setAllPublicCommunities(publicCommunities);
    setIsLoadingAllPublicCommunities(false);
  };

  const { getUserCommunities } = useGetCommunitiesByUser({
    onCompleted: getUserCommunitiesCompleted,
  });

  const { getAllPublicCommunities } = useGetAllPublicCommunities({
    onCompleted: handleGetAllPublicCommunitiesCompleted,
  });

  useEffect(() => {
    getUserCommunities({
      variables: {
        shouldIncludeCommunities: true,
        shouldIncludeProfileImage: true,
        shouldIncludeUserConnection: true,
      },
    });

    getAllPublicCommunities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSearchActive) {
      getUserCommunities({
        variables: {
          filter: searchValue,
          shouldIncludeCommunities: true,
          shouldIncludeProfileImage: true,
          shouldIncludeUserConnection: true,
        },
      });

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchActive, searchValue]);

  const canRenderCommunityPosts = !isLoadingUserCommunities && userCommunities.length > 0;
  const hasNoCommunityPosts = !isLoadingUserCommunities && userCommunities.length === 0;

  const canRenderAllPublicCommunities = !isLoadingAllPublicCommunities && !isEmpty(allPublicCommunities);
  const hasNoPublicCommunities = !isLoadingAllPublicCommunities && isEmpty(allPublicCommunities);

  const suggestedCommunities = allPublicCommunities.edges?.filter(({ node }) => node && !node.userConnection).slice(0, 5);

  return (
    <BaseLayoutPage
      breadcrumbItems={[{ label: 'Communities' }]}
      meta={{ seoDescription: 'View all your communities', seoTitle: 'Communities' }}
      title="Communities"
    >
      <div className="c-communities-page__container">
        <SearchInput
          additionalClassNames="c-communities-page__search"
          id="search"
          isSearchActive={isSearchActive}
          labelText="Search communities"
          name="search"
          placeholder="Search communities"
          type="text"
          isLabelHidden
          onClear={() => {
            onSearchClear();
            getUserCommunities({
              variables: {
                shouldIncludeCommunities: true,
                shouldIncludeProfileImage: true,
                shouldIncludeUserConnection: true,
              },
            });
          }}
          onSearch={onSearchSubmit}
        />

        <h1>Connected communities ({userCommunities.length})</h1>

        {isLoadingUserCommunities && (
          <>
            <ListCardSkeleton />
            <ListCardSkeleton />
          </>
        )}

        {canRenderCommunityPosts &&
          userCommunities.map(({ description, id, key, title, profileImage, userConnection }) => {
            const roleTag = userConnection?.role !== ECommunityRole.Member ? 'Moderator' : '';

            return (
              <ListCard
                key={id}
                avatarImage={profileImage?.presignedUrl}
                avatarTitle={title}
                description={
                  userConnection?.createdDate
                    ? `${userConnection.role === ECommunityRole.Owner ? 'Created' : 'Joined'} ${formatDate(
                      new Date(userConnection.createdDate),
                    )}`
                    : description
                }
                displayName={title}
                href={`/social/communities/${key}`}
                tag={roleTag}
                hasArrow
              />
            );
          })}

        {hasNoCommunityPosts && <EmptyMessage message="No connected communities" />}

        <div className="c-communities-page__suggestions">
          <h2>Suggested communities ({suggestedCommunities?.length ?? 0})</h2>
          <NextLink href="/social/communities/public" legacyBehavior>
            <a>Explore public communities</a>
          </NextLink>
        </div>

        {isLoadingAllPublicCommunities && (
          <>
            <ListCardSkeleton />
            <ListCardSkeleton />
          </>
        )}

        {canRenderAllPublicCommunities &&
          suggestedCommunities &&
          suggestedCommunities.map(({ node }) => {
            const community = node as CommunityGraphType;
            return (
              <ListCard
                key={community.id}
                avatarImage={community.profileImage?.presignedUrl}
                avatarTitle={community.title}
                description={
                  community.userConnection?.createdDate
                    ? `Joined ${formatDate(new Date(community.userConnection.createdDate))}`
                    : community.description
                }
                displayName={community.title}
                href={`/social/communities/${String(community.key)}`}
                hasArrow
              />
            );
          })}

        {hasNoPublicCommunities && <EmptyMessage message="No communities available" />}
      </div>
    </BaseLayoutPage>
  );
};

export { CommunitiesPage };
