import { CommentConnection, CommentEdge } from '../../../services';

export const recurseComments = (comments?: CommentConnection, cb?: (node: CommentEdge) => void) => {
  if (!comments) return;

  return comments.edges?.map(({ cursor, node }) => {
    if (!node || !cb) return;

    cb({ cursor, node });

    return {
      cursor,
      node: {
        ...node,
        comments: {
          edges: recurseComments(node.comments, (otherNode) => cb({ cursor, node: otherNode.node })),
        },
      },
    };
  });
};
