import { gql } from '@apollo/client';

import { USER_FRAGMENT } from './user.fragment';

const GROUP_CREATION_REQUEST_FRAGMENT = gql`
  fragment groupCreationRequestFragment on GroupCreationRequestGraphType {
    address
    city
    complementAddress
    creator {
      ...userFragment
    }
    email
    flag
    groupTypeId
    id
    name
    phoneNumber
    postcode: postCode
    projectId
    state
    website
  }

  ${USER_FRAGMENT}
`;

export { GROUP_CREATION_REQUEST_FRAGMENT };
