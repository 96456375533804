import { IGetConversationOptions, ConversationQueryResult } from './useGetConversation.interface';

import { bonoboClient } from '../../apollo';
import { useGetConversationLazyQuery } from '../../bonobo/generated.graphql';

export const useGetConversation = (options?: IGetConversationOptions) => {
  const { onCompleted, onError } = options ?? ({} as IGetConversationOptions);

  const [getConversation, { loading: isLoading, error, fetchMore, refetch }] = useGetConversationLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      const posts = data.message?.getConversation;
      onCompleted(posts?.edges as unknown as ConversationQueryResult[], posts?.totalCount ?? 0);
    },
    onError,
  });

  return { getConversation, isLoading, error, fetchMore, refetch };
};
