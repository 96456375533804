import { gql } from '@apollo/client';

const JOIN_COMMUNITY = gql`
  mutation joinCommunity($projectId: String!, $key: String!) {
    communityConnection {
      joinCommunityWithKey(key: $key, projectId: $projectId)
    }
  }
`;

export { JOIN_COMMUNITY };
