import { IconQuestion } from '../Icons';

import { EmptyMessageProps } from './EmptyMessage.interfaces';

const EmptyMessage = ({ icon, message }: EmptyMessageProps) => {
  return (
    <div className="c-empty-message">
      {icon ? icon() : <IconQuestion />}
      <p>{message}</p>
    </div>
  );
};

export { EmptyMessage };
