import { IPostRelateOptions } from './usePostRelate.interface';

import { bonoboClient } from '../../apollo';
import { usePostRelateMutation } from '../../bonobo/generated.graphql';

export const usePostRelate = (options?: IPostRelateOptions) => {
  const { onCompleted, onError } = options ?? ({} as IPostRelateOptions);

  const [postRelate, { loading: isPostRelateLoading, error }] = usePostRelateMutation({
    client: bonoboClient,
    onCompleted: () => {
      if (!onCompleted) {
        return;
      }
      onCompleted();
    },
    onError,
  });

  return { postRelate, isPostRelateLoading, error };
};
