import { gql } from '@apollo/client';

import { GELADA_ORGANISATION_FRAGMENT } from './geladaOrganisation.fragment';
import { GELADA_PROJECT_FRAGMENT } from './geladaProject.fragment';

const USER_FRAGMENT = gql`
  fragment userFragment on UserGraphType {
    avatar @include(if: $shouldIncludeUserAvatar) {
      accessoriesType
      clotheColor
      clotheType
      colorFabric
      configuration {
        key
        value
      }
      creationDate
      eyeBrowType
      eyeType
      facialHairColor
      facialHairType
      graphic
      hairColor
      hatColor
      id
      mouthType
      skinColor
      topType
    }
    communityName
    created
    credential @include(if: $shouldIncludeUserCredential) {
      email
      phoneNumber
      secondaryEmail
      services
    }
    customFields @include(if: $shouldIncludeUserCustomFields) {
      ... on CustomFieldResponseTextGraphType {
        text
      }
      ... on CustomFieldResponseNumberGraphType {
        number
      }
      ... on CustomFieldResponseDateGraphType {
        dateTime
      }
      ... on CustomFieldResponseMultiResponseGraphType {
        optionId
      }
      customField {
        description
        id
        isRequired
        isSearchable
        name
        projectId
        scope
        status
      }
      customFieldId
      id
      lastUpdate
    }
    firstName: firstname
    id
    lastName: lastname
    memberships {
      assignedUnitDate
      created
      group @include(if: $shouldIncludeUserMembershipsGroup) {
        address {
          city
          country
          line1
          line2
          line3
          postcode: postCode
          state
        }
        code
        contactEmail
        created
        createdByUserId
        description
        groupType {
          id
          name
          percentage
        }
        id
        identifier
        lastUpdate
        name
        phoneNumber
        projectId
        status
        userFlowId
        website
      }
      id
      organisation {
        ...geladaOrganisationFragment
      }
      permission: permision
      project @include(if: $shouldIncludeUserMembershipsProject) {
        ...geladaProjectFragment
      }
      type
      unit @include(if: $shouldIncludeUserMembershipsUnit) {
        groupId
        id
        name
      }
      userType @include(if: $shouldIncludeUserMembershipsUserType) {
        approvalRequired
        id
        name
        permission
        projectId
        status
        userTypeCode
      }
    }
    status
  }

  ${GELADA_ORGANISATION_FRAGMENT}

  ${GELADA_PROJECT_FRAGMENT}
`;

export { USER_FRAGMENT };
