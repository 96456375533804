import { ICreateSharePostOptions } from './useCreateSharePost.interface';

import { bonoboClient } from '../../apollo';
import { useCreateSharePostMutation } from '../../bonobo/generated.graphql';

export const useCreateSharePost = (options?: ICreateSharePostOptions) => {
  const { onCompleted, onError } = options ?? ({} as ICreateSharePostOptions);

  const [createSharePostMutation, { loading: isLoading, error }] = useCreateSharePostMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { createSharePostMutation, isLoading, error };
};
