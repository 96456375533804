import { IconImageProps } from './IconImage.interfaces';

const IconImage = ({ isActive }: IconImageProps) => {
  return isActive ? (
    <svg height="35" id="image-inactive" viewBox="0 0 35 35" width="35" xmlns="http://www.w3.org/2000/svg">
      <rect data-name="Rectangle 3543" fill="#48756f" height="35" id="Rectangle_3543" rx="8" width="35" />
      <g data-name="Icon feather-camera" id="Icon_feather-camera" transform="translate(8.891 10.456)">
        <path
          d="M18.719,17.023a1.565,1.565,0,0,1-1.565,1.565H3.065A1.565,1.565,0,0,1,1.5,17.023V8.413A1.565,1.565,0,0,1,3.065,6.848H6.2L7.761,4.5h4.7l1.565,2.348h3.131a1.565,1.565,0,0,1,1.565,1.565Z"
          data-name="Path 41450"
          fill="none"
          id="Path_41450"
          opacity="0.5"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          transform="translate(-1.5 -4.5)"
        />
        <path
          d="M18.261,16.631A3.131,3.131,0,1,1,15.131,13.5,3.131,3.131,0,0,1,18.261,16.631Z"
          data-name="Path 41451"
          fill="none"
          id="Path_41451"
          opacity="0.5"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          transform="translate(-6.521 -8.804)"
        />
      </g>
    </svg>
  ) : (
    <svg height="35" id="image-active" viewBox="0 0 35 35" width="35" xmlns="http://www.w3.org/2000/svg">
      <rect data-name="Rectangle 3543" fill="#6dc7be" height="35" id="Rectangle_3543" rx="8" width="35" />
      <g data-name="Icon feather-camera" id="Icon_feather-camera" transform="translate(8.891 10.456)">
        <path
          d="M18.719,17.023a1.565,1.565,0,0,1-1.565,1.565H3.065A1.565,1.565,0,0,1,1.5,17.023V8.413A1.565,1.565,0,0,1,3.065,6.848H6.2L7.761,4.5h4.7l1.565,2.348h3.131a1.565,1.565,0,0,1,1.565,1.565Z"
          data-name="Path 41450"
          fill="#fff"
          id="Path_41450"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          transform="translate(-1.5 -4.5)"
        />
        <path
          d="M18.261,16.631A3.131,3.131,0,1,1,15.131,13.5,3.131,3.131,0,0,1,18.261,16.631Z"
          data-name="Path 41451"
          fill="#6dc7be"
          id="Path_41451"
          transform="translate(-6.521 -8.804)"
        />
      </g>
    </svg>
  );
};

export { IconImage };
