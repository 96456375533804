import { usePerson } from '@netfront/gelada-identity-library';

import { UserInitialsProps } from './UserInitials.interfaces';
import styles from './UserInitials.module.css';

const UserInitials = ({ size }: UserInitialsProps) => {
  const { initials } = usePerson();

  return (
    <div className={`${styles['c-user-initials']} ${size ? `${styles[`c-user-initials--${size}`]}` : ''}`}>
      <span className={styles['c-user-initials__text']}>{initials}</span>
    </div>
  );
};

export { UserInitials };
