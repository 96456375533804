import cx from 'classnames';

import { LEGAL_FOOTER_MENU_ITEMS, PUBLIC_FOOTER_MENU_ITEMS } from '../Footer.constants';

import { LogoLink } from '../../../components';

const PublicFooter = () => {
  return (
    <footer className="relative bg-grey-100 mt-auto color-white font-primary">
      <div className="absolute bg-primary w-1/2 h-1-5 top-0 left-0" role="presentation" />

      <div className="bg-primary py-16 md:py-24">
        <div className="container flex flex-col-reverse md:flex-row">
          <div className="w-full md:w-1/2">
            <div className="flex flex-col items-center md:items-start">
              <div className="mb-4">
                <LogoLink size="2xl" />
              </div>

              <p className="mt-4">
                &copy; {new Date().getFullYear()} Breathing space. All rights reserved.
                <br />
                <span>
                  Built by
                  <a className="color-white  ml-1" href="https://netfront.com.au/" target="_blank">
                    Netfront
                  </a>
                </span>
              </p>
            </div>
          </div>

          <div className="flex justify-center mb-8 md:mb-0 md:justify-end md:text-right md:w-1/2 w-full">
            <nav aria-label="Primary footer navigation" className="mb-8 text-left">
              <h4 className="font-header mb-4">Menu</h4>
              <ul className="m-0">
                {PUBLIC_FOOTER_MENU_ITEMS.map(({ href, id, label }, index) => (
                  <li key={`${id}-${label}`} className="color-white font-body ml-0">
                    <a
                      className={cx('block', 'color-white', {
                        'mb-3': index < PUBLIC_FOOTER_MENU_ITEMS.length,
                      })}
                      href={href}
                    >
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            <nav aria-label="Legal navigation" className="mb-8 ml-16 text-left">
              <h4 className="font-header mb-4">Legal</h4>
              <ul className="m-0">
                {LEGAL_FOOTER_MENU_ITEMS.map(({ href, id, label }, index) => (
                  <li key={`${id}-${label}`} className="color-white font-body ml-0">
                    <a
                      className={cx('block', 'color-white', {
                        'mb-3': index < LEGAL_FOOTER_MENU_ITEMS.length,
                      })}
                      href={href}
                    >
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { PublicFooter };
