import { OperationVariables, QueryLazyOptions } from '@apollo/client';
import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_LOGGED_USER_QUERY } from './useGetLoggedEkardoUser.graphql';
import {
  IGetLoggedUserQueryGraphQLResponse,
  IUseGetLoggedUserOptions,
  IUseGetLoggedUser,
  IHandleGetLoggedUserParams,
} from './useGetLoggedEkardoUser.interfaces';

// import { convertDBUser, getUserData, saveAuthenticationData } from '../../core';

const useGetLoggedEkardoUser = (options?: IUseGetLoggedUserOptions): IUseGetLoggedUser => {
  const { onCompleted, onError, product, token } = options ?? ({} as IUseGetLoggedUserOptions);

  const [executeGetLoggedUser, { loading: isLoading }] = useEkardoLazyQuery<IGetLoggedUserQueryGraphQLResponse>({
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const {
          user: { getLoggedUser },
        } = data;

        if (!onCompleted) {
          return;
        }

        onCompleted({
          getLoggedUser,
        });
      },
      onError,
    },
    product,
    query: GET_LOGGED_USER_QUERY,
    token,
  });

  const handleGetLoggedUser = async (params?: IHandleGetLoggedUserParams) => {
    const {
      apolloLink,
      shouldIncludeOrganisationLogo = false,
      shouldIncludeProjectLogo = false,
      shouldIncludeProjectSettings = false,
      shouldIncludeProjectTheme = false,
      shouldIncludeUserAvatar = true,
      shouldIncludeUserCredential = true,
      shouldIncludeUserCustomFields = false,
      shouldIncludeUserMembershipsGroup = false,
      shouldIncludeUserMembershipsProject = false,
      shouldIncludeUserMembershipsUnit = false,
      shouldIncludeUserMembershipsUserType = false,
    } = params ?? ({} as IHandleGetLoggedUserParams);

    const queryOptions: QueryLazyOptions<OperationVariables | undefined> | undefined = apolloLink
      ? {
          context: apolloLink,
        }
      : undefined;

    await executeGetLoggedUser({
      ...queryOptions,
      variables: {
        shouldIncludeOrganisationLogo,
        shouldIncludeProjectLogo,
        shouldIncludeProjectSettings,
        shouldIncludeProjectTheme,
        shouldIncludeUserAvatar,
        shouldIncludeUserCredential,
        shouldIncludeUserCustomFields,
        shouldIncludeUserMembershipsGroup,
        shouldIncludeUserMembershipsProject,
        shouldIncludeUserMembershipsUnit,
        shouldIncludeUserMembershipsUserType,
      },
    });
  };

  return {
    handleGetLoggedUser,
    isLoading,
  };
};

export { useGetLoggedEkardoUser };
