import { DirectoryHeaderProps } from './DirectoryHeader.interfaces';

import { HelpButton } from '../../HelpButton';

const DirectoryHeader = ({ subHeading }: DirectoryHeaderProps) => {
  return (
    <div className="container-md pt-4 pb-4">
      <div className="flex flex-col md:flex-row">
        <div className="mb-4 md:mb-0 md:w-4/5 w-full">
          <h1 className="color-accent h4 mb-2 weight-800">Services</h1>
          <h2 className="color-blue-900 h2 mb-8">{subHeading}</h2>
        </div>

        <div className="items-center flex w-full">
          <div className="bg-grey-100 flex-1 h-0-5 md:flex mx-8 none rounded-full" role="presentation" />
          <HelpButton />
        </div>
      </div>
    </div>
  );
};

export { DirectoryHeader };
