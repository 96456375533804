import { ICreateConversationOptions } from './useCreateConversation.interface';

import { bonoboClient } from '../../apollo';
import { useCreateConversationMutation } from '../../bonobo/generated.graphql';

export const useCreateConversation = (options?: ICreateConversationOptions) => {
  const { onCompleted, onError } = options ?? ({} as ICreateConversationOptions);

  const [createConversation, { loading: isLoading, error }] = useCreateConversationMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { createConversation, isLoading, error };
};
