import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_PAGINATED_GROUP_MEMBERS } from './useGetGroupUsers.graphql';
import {
  IGetGroupUsersQueryGraphQLResponse,
  IGetGroupUsersQueryVariables,
  IHandleGetGroupUsersParams,
  IUseGetGroupUsersOptions,
} from './useGetGroupUsers.interfaces';

const useGetGroupUsers = (options?: IUseGetGroupUsersOptions) => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetGroupUsersOptions);

  const [executeGetPaginatedUsers, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetGroupUsersQueryGraphQLResponse,
    IGetGroupUsersQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          membership: { getPaginatedGroupMembers: groupUsers },
        } = data;

        onCompleted({
          groupUsers,
        });
      },
      onError,
    },
    query: query ?? GET_PAGINATED_GROUP_MEMBERS,
    token,
  });

  const handleGetGroupUsers = async ({ projectId, status }: IHandleGetGroupUsersParams) => {
    await executeGetPaginatedUsers({
      variables: {
        projectId,
        status,
      },
    });
  };

  return {
    handleGetGroupUsers,
    isLoading,
  };
};

export { useGetGroupUsers };
