import { useToggle } from '@netfront/common-library';
import { DBMetadata } from 'interfaces';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ERelateType } from 'services';

import { DropdownMenu } from '../DropdownMenu';
import { Notifications } from '../Notifications';
import { RelateIcon } from '../RelateIcon';

import { NotificationsMenuProps } from './NotificationsMenu.interfaces';

export const NotificationsMenu = ({ notifications = [] }: NotificationsMenuProps) => {
  const { push } = useRouter();

  const { isToggled: isNotificationsMenuOpen, toggle } = useToggle();

  return (
    <DropdownMenu
      additionalClassNames="c-notifications-menu"
      dropdownId="notifications"
      isDisplayContent={isNotificationsMenuOpen}
      trigger={<Notifications count={notifications.length} />}
      onDisplayContent={notifications.length > 0 ? () => toggle() : () => push('/social/notifications')}
    >
      <ul>
        {notifications
          .filter(({ seen }) => !seen)
          .slice(0, 5)
          .map(({ content, id, metadata }) => {
            let relateType;

            if (metadata) {
              const parsedMetaData = JSON.parse(String(metadata)) as DBMetadata;
              relateType = parsedMetaData.relateType && ERelateType[parsedMetaData.relateType];
            }

            return (
              <li key={id}>
                <NextLink href="/social/notifications" legacyBehavior>
                  <a>
                    <div className="c-notifications-menu__status">
                      <span className="h-hide-visually">Unread</span>
                    </div>
                    {relateType && <RelateIcon relateType={relateType} />}
                    <span className="c-notifications-menu__message">{content}</span>
                  </a>
                </NextLink>
              </li>
            );
          })}
        <li>
          <NextLink href="/social/notifications" legacyBehavior>
            <a>See all Notifications ({notifications.length})</a>
          </NextLink>
        </li>
      </ul>
    </DropdownMenu>
  );
};
