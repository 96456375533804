import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { getBEMClassName } from '@netfront/common-library';
import {
  FormFieldContainer,
  FORM_ELEMENT_CSS_IDENTIFIERS,
  ILoginOnCompletedResponse,
  LoginForm,
  saveAuthenticationData,
  useLogin,
  useLogout,
  useDomain,
} from '@netfront/gelada-identity-library';
import { Dialog } from '@netfront/ui-library';
import cx from 'classnames';
import { useRouter } from 'next/router';

import {
  Button,
  BUTTON_CLASSES,
  REGISTRATION_FORM_BLOCK_CSS_IDENTIFIERS,
  USER_NOT_ACTIVATED_MESSAGE,
  SingleFormPage,
} from '../../../components';
import { useGetRegisterUrl, useIsMounted, useToast } from '../../../hooks';

const LoginPage = () => {
  const { button: buttonElementCssId, container: containerElementCssId, register: registerElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const { form: formBlockCssId } = REGISTRATION_FORM_BLOCK_CSS_IDENTIFIERS;

  const [isResendActivationCodeDialogOpen, setIsResendActivationCodeDialogOpen] = useState<boolean>(false);

  const { push, prefetch, query, replace, reload } = useRouter();
  const { handleToastError } = useToast();
  const { registerUrl } = useGetRegisterUrl();
  const { isMounted } = useIsMounted();
  const { getDomain, isDomainReady } = useDomain();

  const { requestedRoute } = query;

  const { handleLogout } = useLogout();

  const navigateToUrlAfterLoginCompleted = requestedRoute ? String(requestedRoute) : '/social/feed';

  const handleLoginCompleted = ({ accessToken, refreshToken, user }: ILoginOnCompletedResponse) => {
    saveAuthenticationData({
      accessToken,
      refreshToken,
      user,
      accessTokenOptionalCookieAttributes: {
        domain: getDomain(),
      },
      refreshTokenOptionalCookieAttributes: {
        domain: getDomain(),
      },
      userOptionalCookieAttributes: {
        domain: getDomain(),
      },
    });

    replace({ pathname: navigateToUrlAfterLoginCompleted }).then(() => reload());
  };

  const handleLoginError = (error?: ApolloError) => {
    if (!error) {
      return;
    }

    const { message } = error;

    if (message === USER_NOT_ACTIVATED_MESSAGE) {
      setIsResendActivationCodeDialogOpen(true);
      return;
    }

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleRegister = () => {
    push(registerUrl).catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  const { handleLogin, isLoading = false } = useLogin({
    onCompleted: handleLoginCompleted,
    onError: handleLoginError,
  });

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }
    prefetch(navigateToUrlAfterLoginCompleted).catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );

    handleLogout({ domain: getDomain(), path: '/' });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  const registerButton = (
    <FormFieldContainer
      css={{
        blockId: formBlockCssId,
        elementId: `${registerElementCssId}-${buttonElementCssId}-${containerElementCssId}`,
      }}
    >
      <Button
        aria-label="Register"
        className={cx(getBEMClassName(formBlockCssId, `${registerElementCssId}-${buttonElementCssId}`), BUTTON_CLASSES.darkGreen)}
        onPress={handleRegister}
      >
        Register
      </Button>
    </FormFieldContainer>
  );

  return (
    <SingleFormPage isPreloaderVisible={isLoading} meta={{ seoDescription: 'Access your account', seoTitle: 'Login' }} title="Login">
      <h1 className="color-primary h5 mb-2 text-uppercase">Login</h1>
      <h2 className="color-black mb-6 text-uppercase">
        Access your <br />
        account
      </h2>

      <LoginForm
        buttonClassName={BUTTON_CLASSES.green}
        forgotPasswordUrl="/forgot-password"
        isSubmitting={isLoading}
        registerButton={registerButton}
        isTogglePasswordVisible
        onLogin={async (login, password) => {
          await handleLogin({
            login,
            password,
            shouldIncludeUserMembershipsGroup: true,
            shouldIncludeUserCustomFields: true,
            shouldIncludeUserCredential: true,
          });
        }}
      />

      {isMounted && (
        <Dialog
          isOpen={isResendActivationCodeDialogOpen}
          title="This account has been deactivated"
          onClose={() => setIsResendActivationCodeDialogOpen(false)}
        >
          <p className="mb-8">This email has been deactivated, please sign up with a different email address</p>

          <Button theme="default" onPress={() => setIsResendActivationCodeDialogOpen(false)}>
            Close
          </Button>
        </Dialog>
      )}
    </SingleFormPage>
  );
};

export { LoginPage };
