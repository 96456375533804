import { ICreatePollOptions } from './useCreatePoll.interface';

import { bonoboClient } from '../../apollo';
import { useCreatePollMutation } from '../../bonobo/generated.graphql';

export const useCreatePoll = (options?: ICreatePollOptions) => {
  const { onCompleted, onError } = options ?? ({} as ICreatePollOptions);

  const [createPoll, { loading: isCreatePollLoading, error }] = useCreatePollMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { createPoll, isCreatePollLoading, error };
};
