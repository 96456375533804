import { IUpdateUserImagesOptions } from './useUpdateUserImages.interface';

import { bonoboClient } from '../../apollo';
import { useUpdateUserImagesMutation } from '../../bonobo/generated.graphql';

export const useUpdateUserImages = (options?: IUpdateUserImagesOptions) => {
  const { onCompleted, onError } = options ?? ({} as IUpdateUserImagesOptions);

  const [updateUserImages, { loading: isUpdateUserLoading, error }] = useUpdateUserImagesMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { updateUserImages, isUpdateUserLoading, error };
};
