import { LogoLinkProps } from './LogoLink.interfaces';

import { getValidClassNames } from '../../utils';

const LogoLink = ({ href = '/', size = 'md' }: LogoLinkProps) => {
  const sizeClassName = `c-logo-link--${size}`;
  return (
    <>
      <a
        className={getValidClassNames({
          'c-logo-link mr-8': true,
          [String(sizeClassName)]: Boolean(size),
        })}
        href={href}
      >
        <span className="sr-only">Go to homepage</span>
        <img alt="Go to homepage" src="/images/breathing-space/logo.svg" />
      </a>
    </>
  );
};

export { LogoLink };
