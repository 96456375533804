import { gql } from '@apollo/client';

const PROJECT_THEME_FRAGMENT = gql`
  fragment projectThemeFragment on ProjectThemeGraphType {
    backGroundColor
    buttonColor
    customCss
    footerColor
    headerColor
    linkColor
    sideColor
  }
`;

export { PROJECT_THEME_FRAGMENT };
