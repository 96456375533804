import { useEffect, useRef, useState } from 'react';

import { DropzoneFileUpload } from '@netfront/ui-library';
import AvatarEditor from 'react-avatar-editor';

import { ImageEditorProps } from './ImageEditor.interfaces';

export const ImageEditor = ({ borderRadius = 200, height = 150, width = 150, onImageUpdate }: ImageEditorProps) => {
  const [previewImage, setPreviewImage] = useState<File>();
  const editorRef = useRef<AvatarEditor>(null);

  useEffect(() => {
    if (!previewImage) return;

    onImageUpdate(previewImage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewImage]);

  return (
    <>
      {previewImage ? (
        <AvatarEditor
          ref={editorRef}
          border={50}
          borderRadius={borderRadius}
          color={[255, 255, 255, 0.6]}
          height={height}
          image={URL.createObjectURL(previewImage)}
          rotate={0}
          scale={1}
          width={width}
        />
      ) : (
        <DropzoneFileUpload
          labelText="Upload image"
          onDrop={(acceptedFiles) => {
            setPreviewImage(acceptedFiles[0]);
          }}
        />
      )}
    </>
  );
};
