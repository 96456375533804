import { gql } from '@apollo/client';

import { PostGraphTypeFragmentDoc } from '../../../graphql/fragments';

export const GET_COMMUNITY_POSTS_QUERY = gql`
  query getCommunityPosts($after: String, $first: Int, $communityId: Int!) {
    post {
      getCommunityPosts(after: $after, first: $first, communityId: $communityId) {
        totalCount
        edges {
          cursor
          node {
            ...postGraphType
          }
        }
      }
    }
  }
  ${PostGraphTypeFragmentDoc}
`;
