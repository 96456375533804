import { usePublicGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { LOGIN_WITH_AUTHENTICATION_TOKEN } from './useLoginWithAuthenticationToken.graphql';
import {
  IHandleLoginWithAuthenticationTokenParams,
  ILoginWithAuthenticationTokenMutationGraphQLResponse,
  ILoginWithAuthenticationTokenMutationVariables,
  IUseLoginWithAuthenticationTokenOptions,
  IUseLoginWithAuthenticationToken,
} from './useLoginWithAuthenticationToken.interfaces';

/**@todo Move to library */

const useLoginWithAuthenticationToken = (options?: IUseLoginWithAuthenticationTokenOptions): IUseLoginWithAuthenticationToken => {
  const { onCompleted, onError, product } = options ?? ({} as IUseLoginWithAuthenticationTokenOptions);

  const [executeLoginWithAuthenticationToken, { loading: isLoading }] = usePublicGeladaLazyQuery<
  ILoginWithAuthenticationTokenMutationGraphQLResponse,
  ILoginWithAuthenticationTokenMutationVariables
  >({
    query: LOGIN_WITH_AUTHENTICATION_TOKEN,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const { loginWithAuthenticationToken } = data;

        onCompleted({
          loginWithAuthenticationToken,
        });
      },
      onError,
    },
    product,
  });

  const handleLoginWithAuthenticationToken = async ({ token }: IHandleLoginWithAuthenticationTokenParams) => {
    await executeLoginWithAuthenticationToken({
      variables: {
        token,
      },
    });
  };

  return {
    handleLoginWithAuthenticationToken,
    isLoading,
  };
};

export { useLoginWithAuthenticationToken };
