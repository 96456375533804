import { useEkardoLazyQuery } from '@netfront/ekardo-content-library';

import { GET_ANSWERS_QUERY } from './useGetAnswers.graphql';
import {
  IHandleGetAnswersParams,
  IGetAnswersQueryGraphQLResponse,
  IUseGetAnswers,
  IUseGetAnswersOptions,
} from './useGetAnswers.interfaces';

const useGetAnswers = (options?: IUseGetAnswersOptions): IUseGetAnswers => {
  const { fetchPolicy, onCompleted, onError, product, query, token } = options ?? ({} as IUseGetAnswersOptions);

  const [executeGetAnswers, { loading: isLoading }] = useEkardoLazyQuery<IGetAnswersQueryGraphQLResponse>({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAnswer: { getAnswers: answers },
        } = data;

        onCompleted({
          answers,
        });
      },
      onError,
    },
    product,
    query: query ?? GET_ANSWERS_QUERY,
    token,
  });

  const handleGetAnswers = async ({ userFlowStepTrackId }: IHandleGetAnswersParams) => {
    await executeGetAnswers({
      variables: {
        userFlowStepTrackId,
      },
    });
  };

  return {
    handleGetAnswers,
    isLoading,
  };
};

export { useGetAnswers };
