import { gql } from '@apollo/client';

import { IDBUserGraphTypeFragment } from '../../../services/graphql';

export const GET_CONNECTIONS_BY_USER_QUERY = gql`
  query {
    userConnection {
      getUserConnections {
        edges {
          node {
            connectionStatus
            createdDate
            createdDate
            friendId
            id
            updatedDate
            user {
              ...user
            }
            userId
          }
        }
      }
    }
  }

  ${IDBUserGraphTypeFragment}
`;
