import { IGetPostsOptions } from '../useGetPosts';

import { bonoboClient } from '../../apollo';
import { PostInterfaceConnection, useGetModeratorsPostsLazyQuery } from '../../bonobo/generated.graphql';

export const useGetModeratorsPosts = (options?: IGetPostsOptions) => {
  const { onCompleted, onError } = options ?? ({} as IGetPostsOptions);

  const [getModeratorsPosts, { loading: isModeratorsPostsLoading, error, fetchMore, refetch }] = useGetModeratorsPostsLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      const posts = data.post?.moderatorsPosts as PostInterfaceConnection;
      onCompleted(posts, posts.totalCount ?? 0);
    },
    onError,
  });

  return { getModeratorsPosts, isModeratorsPostsLoading, error, fetchMore, refetch };
};
