import { useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IForgotPasswordOnCompletedResponse, ForgotPasswordForm, useForgotPassword } from '@netfront/gelada-identity-library';
import { Button } from '@netfront/ui-library';

import { BUTTON_CLASSES, SingleFormPage } from '../../../components';
import { useToast } from '../../../hooks';

const PAGE_TITLE = 'Forgot password';

const ForgotPasswordPage = () => {
  const [isForgotPasswordCompleted, setIsForgotPasswordCompleted] = useState<boolean>(false);

  const { handleToastError } = useToast();

  const handleForgotPasswordCompleted = ({ isCompleted }: IForgotPasswordOnCompletedResponse) => {
    setIsForgotPasswordCompleted(isCompleted);
  };

  const handleForgotPasswordError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleForgotPassword, isLoading = false } = useForgotPassword({
    onCompleted: handleForgotPasswordCompleted,
    onError: handleForgotPasswordError,
  });

  return (
    <SingleFormPage
      isPreloaderVisible={isLoading}
      meta={{ seoDescription: 'Receive a reset link', seoTitle: PAGE_TITLE }}
      title={PAGE_TITLE}
    >
      <h1 className="color-primary h5 mb-2 text-uppercase">{PAGE_TITLE}</h1>
      <h2 className="color-black mb-6 text-uppercase">Receive a reset link</h2>

      {!isForgotPasswordCompleted ? (
        <ForgotPasswordForm
          buttonClassName={BUTTON_CLASSES.default}
          buttonText="Submit"
          isSubmitting={isLoading}
          onForgotPassword={(email) =>
            handleForgotPassword({
              email,
            })
          }
        />
      ) : (
        <>
          <div className="mb-8 md:mb-8">An email has been sent with a reset password link to your nominated email.</div>

          <div className="mb-8 md:mb-8">
            To finalise your password reset, click the link within the email and follow the prompts to complete the reset password process.
          </div>

          <Button
            linkButton={{ id: 'login', url: '/login' }}
            text='Return to login'
          />
        </>
      )}
    </SingleFormPage>
  );
};

export { ForgotPasswordPage };
