import { ChangeEvent } from 'react';

import { IAdditionalClassNames, InputProps } from '@netfront/ui-library';

export enum ESize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  '2xl' = '2xl',
}


export interface IUseSearchInput {
  isSearchActive: boolean;
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSearchClear: () => void;
  onSearchSubmit: (value: string) => void;
  searchValue: string;
}

export interface IUseSearchInputParams {
  defaultIsSearchActive?: boolean;
  defaultSearchValue?: string;
}

export interface SearchFilterProps extends IAdditionalClassNames, Omit<InputProps, 'onChange'> {
  isSearchActive?: boolean;
  onClear: () => void;
  onSearch: (value: string) => void;
  size?: ESize;
}
