import { ICreateCommentOptions } from './useCreateComment.interface';

import { bonoboClient } from '../../apollo';
import { useCreateCommentMutation } from '../../bonobo/generated.graphql';

export const useCreateComment = (options?: ICreateCommentOptions) => {
  const { onCompleted, onError } = options ?? ({} as ICreateCommentOptions);

  const [createComment, { loading: isCreateCommentLoading, error }] = useCreateCommentMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { createComment, isCreateCommentLoading, error };
};
