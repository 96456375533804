import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { INotification, useSocialContext } from '../../../../contexts';
import { useMarkANotificationSeen } from '../../../../services';
import { BaseLayoutPage, Feed } from '../../../Social';

const PostPage = () => {
  const {
    query: { postId, notification },
  } = useRouter();

  const { notifications, updateNotifications } = useSocialContext();

  const { markANotificationSeen } = useMarkANotificationSeen({
    onCompleted: () => {
      updateNotifications(
        notifications.map((message) => (message.guid === notification ? ({ ...message, seen: true } as INotification) : message)),
      );
    },
  });

  useEffect(() => {
    if (!notification) return;

    markANotificationSeen({
      variables: {
        ipAddress: '',
        notificationGuid: String(notification),
        userAgent: '',
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  return (
    <BaseLayoutPage meta={{ seoDescription: 'View all your community posts', seoTitle: 'Feed' }} title="Feed">
      <div className="c-post-page__container">{postId && <Feed postId={Number(postId)} />}</div>
    </BaseLayoutPage>
  );
};

export { PostPage };
