import { gql } from '@apollo/client';

import {
  IDBPostInterfaceGraphTypeFragment,
  IDBPostPollGraphTypeFragment,
  IDBPostShareGraphTypeFragment,
  IDBUserGraphTypeFragment,
} from '../../fragments';

export const GetFeedPost = gql`
  query getPost($postId: Int!) {
    post {
      getPost(postId: $postId) {
        id
        assets {
          postId
          assetId
          contentType
          fileName
          presignedUrl
          s3Key
        }
        author {
          ...user
        }
        commentCount
        createdDate
        createdDateTime
        feeling
        hasRelated
        message
        pinned
        relateCount
        shareCount
        shareOption
        status
        tags
        topic {
          id
          title
          description
          postsCount
        }
        postType: __typename
        updatedDate
        ...poll
        ...postShareGraphType
      }
    }
  }

  ${IDBUserGraphTypeFragment}
  ${IDBPostPollGraphTypeFragment}
  ${IDBPostInterfaceGraphTypeFragment}
  ${IDBPostShareGraphTypeFragment}
`;
