import { ConnectedUserQueryResult, IGetConnectedUsersOption } from './useGetConnectedUsers.interface';

import { bonoboClient } from '../../apollo';
import { useGetConnectedUsersLazyQuery } from '../../bonobo/generated.graphql';

export const useGetConnectedUsers = (options?: IGetConnectedUsersOption) => {
  const { onCompleted, onError } = options ?? ({} as IGetConnectedUsersOption);

  const [getConnectedUsers, { loading: isLoading, error, refetch }] = useGetConnectedUsersLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      const connections = data.user?.connectedUsers;
      onCompleted(connections?.edges as ConnectedUserQueryResult[], connections?.totalCount ?? 0);
    },
    onError,
  });

  return { getConnectedUsers, isLoading, error, refetch };
};
