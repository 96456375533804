import { IVoteAPollOptions } from './useVoteAPoll.interface';

import { bonoboClient } from '../../apollo';
import { useVoteAPollMutation } from '../../bonobo/generated.graphql';

export const useVoteAPoll = (options?: IVoteAPollOptions) => {
  const { onCompleted, onError } = options ?? ({} as IVoteAPollOptions);

  const [voteAPoll, { loading: isVoteLoading, error }] = useVoteAPollMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { voteAPoll, isVoteLoading, error };
};
