import { GetCommunitiesByCategoryQueryResult, IGetCommunitiesByCategoryOption } from './useGetCommunitiesByCategory.interface';

import { bonoboClient } from '../../apollo';
import { useSearchByCategoryLazyQuery } from '../../bonobo/generated.graphql';

export const useGetCommunitiesByCategory = (options?: IGetCommunitiesByCategoryOption) => {
  const { onCompleted, onError } = options ?? ({} as IGetCommunitiesByCategoryOption);

  const [getCommunities, { loading: isLoading, error, refetch }] = useSearchByCategoryLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      const communities = data.community?.searchByCategory;
      onCompleted(communities?.edges as GetCommunitiesByCategoryQueryResult[], communities?.totalCount ?? 0);
    },
    onError,
  });

  return { getCommunities, isLoading, error, refetch };
};
