import { useEffect, useState } from 'react';

import { getTemporaryTokenCookie } from '@netfront/common-library';
import isEmpty from 'lodash.isempty';

import { MAXIMUM_GROUP_DIFFERENCE } from './useRandomisation.constants';
import { IUseRandomisation, IUseRandomisationParams, IUserGroupCount } from './useRandomisation.interfaces';

import { IGetGroupsOnCompletedResponse, useGetGroups } from '../../hooks';

const useRandomisation = (options?: IUseRandomisationParams): IUseRandomisation => {
  const temporaryToken = getTemporaryTokenCookie();

  const { outlier = MAXIMUM_GROUP_DIFFERENCE } = options ?? ({} as IUseRandomisationParams);

  const [randomisation, setRandomisation] = useState<IUseRandomisation>({} as IUseRandomisation);

  const handleGetGroupsCompleted = (data?: IGetGroupsOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const {
      groups: { edges },
    } = data;

    const instantGroup = edges.find(({ node: { id } }) => id === 2103);
    const delayedGroup = edges.find(({ node: { id } }) => id === 2121);

    if (!instantGroup || !delayedGroup) {
      return;
    }

    const counts: IUserGroupCount = {
      delayed: delayedGroup.node.userCount,
      instant: instantGroup.node.userCount,
    };

    const isOutlierExceeded = Math.abs(counts.delayed - counts.instant) > outlier;

    const lowestGroup = edges.reduce((prev, current) => (prev.node.userCount < current.node.userCount ? prev : current));

    const assignment = isOutlierExceeded ? lowestGroup.node : Math.random() >= 0.5 ? instantGroup.node : delayedGroup.node;

    setRandomisation({
      assignment,
      counts,
      isOutlierExceeded,
    });
  };

  const { handleGetGroups } = useGetGroups({
    onCompleted: handleGetGroupsCompleted,
    token: temporaryToken,
  });

  useEffect(() => {
    if (!temporaryToken) {
      return;
    }

    if (!isEmpty(randomisation)) {
      return;
    }

    handleGetGroups({
      projectId: String(process.env.REACT_APP_PROJECT_ID),
      status: 'ACTIVE',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporaryToken]);

  return {
    ...randomisation,
  };
};

export { useRandomisation };
