import { gql } from '@apollo/client';

import { PROJECT_SETTINGS_FRAGMENT } from './projectSettings.fragment';
import { PROJECT_THEME_FRAGMENT } from './projectTheme.fragment';

const GELADA_PROJECT_FRAGMENT = gql`
  fragment geladaProjectFragment on ProjectGraphType {
    description
    features
    guid
    hasMobileApplication
    id
    isCustomBuild
    logo @include(if: $shouldIncludeProjectLogo) {
      assetId
      contentType
      presignedUrl
      s3Key
    }
    name
    organisation {
      key
    }
    organisationId
    services
    settings @include(if: $shouldIncludeProjectSettings) {
      ...projectSettingsFragment
    }
    theme @include(if: $shouldIncludeProjectTheme) {
      ...projectThemeFragment
    }
    url
  }

  ${PROJECT_SETTINGS_FRAGMENT}

  ${PROJECT_THEME_FRAGMENT}
`;

export { GELADA_PROJECT_FRAGMENT };
