import { GetRelatesQueryResult, IGetPostRelatesQueryResultOption } from './useGetPostRelates.interface';

import { bonoboClient } from '../../apollo';
import { useGetPostRelatesLazyQuery } from '../../bonobo/generated.graphql';

export const useGetPostRelates = (options?: IGetPostRelatesQueryResultOption) => {
  const { onCompleted, onError } = options ?? ({} as IGetPostRelatesQueryResultOption);

  const [getPostRelates, { loading: isLoading, error, refetch }] = useGetPostRelatesLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      const postRelate = data.postRelate?.get;
      onCompleted(postRelate?.edges as GetRelatesQueryResult[], postRelate?.totalCount ?? 0);
    },
    onError,
  });

  return { getPostRelates, isLoading, error, refetch };
};
