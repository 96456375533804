import { useHowlerMutation } from '@netfront/gelada-identity-library';

import { SEND_MAILS_MUTATION } from './useSendMails.graphql';
import {
  IHandleSendMailsParams,
  ISendMailsMutationGraphQLResponse,
  ISendMailsMutationVariables,
  IUseSendMailsOptions,
  IUseSendMails,
} from './useSendMails.interfaces';

/**@todo Move to library */

const useSendMails = (options?: IUseSendMailsOptions): IUseSendMails => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseSendMailsOptions);

  const [executeSendMails, { loading: isLoading }] = useHowlerMutation<ISendMailsMutationGraphQLResponse, ISendMailsMutationVariables>({
    mutation: mutation ?? SEND_MAILS_MUTATION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          notification: { sendMails },
        } = data;

        onCompleted({
          sendMails,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleSendMails = async ({ placeholders, receiver, subject, templateId, userId }: IHandleSendMailsParams) => {
    await executeSendMails({
      variables: {
        placeholders,
        receiver,
        subject,
        templateId,
        userId,
      },
    });
  };

  return {
    handleSendMails,
    isLoading,
  };
};

export { useSendMails };
