import { DBMetadataType } from 'interfaces';
import { ERelateType } from 'services';

const relateMessage = {
  FeltLikeThatToo: 'felt like that to with your',
  LaughtOutLoud: 'laughed out loud to your',
  Like: 'liked your',
  Relate: 'related to your',
  Understand: 'understood your',
  ThankYouForSharing: 'thanked you for sharing your',
};

export const getNotificationMessage = (type?: DBMetadataType, relateType?: ERelateType) => {
  switch (type) {
    case 'CommentPost':
      return 'commented on your post';
    case 'RelateComment':
      return `${String(relateMessage[relateType as ERelateType])} comment`;
    case 'RelatePost':
      return `${String(relateMessage[relateType as ERelateType])} post`;
    case 'UserConnectionRequest':
      return 'sent you a connection request';
    default:
      return '';
  }
};

export const getNotificationLink = (type?: DBMetadataType, postId?: number, guid?: string) => {
  const notificationParam = `?notification=${String(guid)}`;
  const postRedirect = `/social/post/${Number(postId)}${notificationParam}`;
  
  switch (type) {
    case 'CommentPost':
      return postRedirect;
    case 'SensitiveContent':
      return postRedirect;
    case 'SubComment':
      return postRedirect;
    case 'RelateComment':
      return postRedirect;
    case 'RelatePost':
      return postRedirect;
    case 'UserConnectionRequest':
      return `/social/connections${notificationParam}`;
    default:
      return '/social/feed';
  }
};
