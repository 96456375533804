import React from 'react';

import { SingleFormPageProps } from './SingleFormPage.interfaces';

import { Page } from '../../../components';
import { AppBanner } from '../../../components/Social';

const SingleFormPage = ({ containerSize = 'xs', children, ...rest }: SingleFormPageProps) => {
  return (
    <Page {...rest}>
      <section className="bg-gradient-blue pb-48 pt-24">
        <div className={`container container-${containerSize}`}>{children}</div>
      </section>

      <AppBanner />
    </Page>
  );
};

export { SingleFormPage };
