import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { UPDATE_USER_FORUM_NAME_MUTATION } from './useUpdateUserForumName.graphql';
import {
  IUpdateUserForumNameMutationGraphQLResponse,
  IUpdateUserForumNameMutationVariables,
  IHandleUpdateUserForumNameParams,
  IUseUpdateUserForumNameOptions,
  IUseUpdateUserForumName,
} from './useUpdateUserForumName.interfaces';

const useUpdateUserForumName = (options?: IUseUpdateUserForumNameOptions): IUseUpdateUserForumName => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseUpdateUserForumNameOptions);

  const [executeUpdateUserForumName, { loading: isLoading }] = useLoggedGeladaMutation<
    IUpdateUserForumNameMutationGraphQLResponse,
    IUpdateUserForumNameMutationVariables
  >({
    mutation: mutation ?? UPDATE_USER_FORUM_NAME_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          user: { updateUserForumName: isUpdateUserForumName },
        } = data;

        onCompleted({
          isUpdateUserForumName,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleUpdateUserForumName = async ({ forumName }: IHandleUpdateUserForumNameParams) => {
    await executeUpdateUserForumName({
      variables: {
        forumName,
      },
    });
  };

  return {
    handleUpdateUserForumName,
    isLoading,
  };
};

export { useUpdateUserForumName };
