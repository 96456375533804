import { gql } from '@apollo/client';

import { IDBVotePollGraphTypeFragment } from './pollVote.fragment';

const IDBPostPollGraphTypeFragment = gql`
  fragment poll on PostGraphType {
    poll {
      end
      id
      start
      end
      items {
        id
        title
        votes {
          pollItemId
          userId
        }
        votesCount
      }
      postId
      title
      userVote {
        ...userVoteGraph
      }
    }
  }

  ${IDBVotePollGraphTypeFragment}
`;

export { IDBPostPollGraphTypeFragment };
