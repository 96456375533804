import { Avatar } from '@netfront/ui-library';

import { OnlineStatus } from '../OnlineStatus';

import { UserAvatarProps } from './UserAvatar.interfaces';

const UserAvatar = ({ avatar, displayName, isOnline, size = 'small' }: UserAvatarProps) => {
  return (
    <>
      {isOnline && <OnlineStatus size="sm" />}
      <Avatar {...(avatar && { image: <img alt={displayName} src={avatar} /> })} size={size} title={displayName} />
    </>
  );
};

export { UserAvatar };
