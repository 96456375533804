import { gql } from '@apollo/client';

const SEND_MAILS_MUTATION = gql`
  mutation sendMails(
    $receiver: String!
    $placeholders: [PlaceholdersInputType]!
    $subject: String!
    $templateId: Int!
    $userId: Int
  ) {
    notification {
      sendMails(notificationGroup: {
        notifications: {
          receiver: $receiver,
          userId: $userId
          placeholders: $placeholders
        }
        subject: $subject,
        templateId: $templateId
        projectId: "${process.env.REACT_APP_PROJECT_ID}"
      }) {
        id
      }
    }
  }
`;

export { SEND_MAILS_MUTATION };
