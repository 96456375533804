import { ChangeEvent, useEffect, useState } from 'react';

import { Button } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { GetUserCommunitiesQueryResult, useGetCommunitiesByUser } from '../../../../services';
import { BaseLayoutPage, Feed, EFeedQuery, SearchInput, useSearchInput, RadioTabs } from '../../../Social';

const FeedPage = () => {
  const { push } = useRouter();
  const { isSearchActive, onSearchClear, onSearchSubmit, searchValue } = useSearchInput();
  const [queryType, setQueryType] = useState<EFeedQuery>(EFeedQuery.All);
  const [hasConnectedCommunities, setHasConnectedCommunities] = useState<boolean>(false);

  const {
    query: { hashtag },
  } = useRouter();
  const onQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setQueryType(value as EFeedQuery);
  };

  const handleGetUserCommunitiesCompleted = (communities: GetUserCommunitiesQueryResult[], totalCount: number) => {
    setHasConnectedCommunities(Boolean(totalCount));
  };

  const { getUserCommunities } = useGetCommunitiesByUser({
    onCompleted: handleGetUserCommunitiesCompleted,
  });

  useEffect(() => {
    getUserCommunities();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseLayoutPage meta={{ seoDescription: 'View all your community posts', seoTitle: 'Community posts' }} title="Community posts">
      <div className="c-feed-page__container">
        <div className="c-feed-page__search">
          <SearchInput
            id="search"
            isSearchActive={isSearchActive}
            labelText="Search feed"
            name="search"
            placeholder="Search feed"
            type="text"
            isLabelHidden
            onClear={onSearchClear}
            onSearch={onSearchSubmit}
          />
          <RadioTabs
            additionalClassNames="c-query-options"
            items={[
              { id: EFeedQuery.All.toLowerCase(), labelText: EFeedQuery.All.toLowerCase(), value: EFeedQuery.All },
              { id: EFeedQuery.Members.toLowerCase(), labelText: EFeedQuery.Members.toLowerCase(), value: EFeedQuery.Members },
              { id: EFeedQuery.Moderators.toLowerCase(), labelText: EFeedQuery.Moderators.toLowerCase(), value: EFeedQuery.Moderators },
            ]}
            name="query"
            selectedValue={queryType}
            onChange={onQueryChange}
          />

          <Button
            iconId="id_plus_icon"
            isDisabled={!hasConnectedCommunities}
            text="Post"
            onClick={() => {
              push('/social/create-post');
            }}
          />
        </div>

        <Feed
          message={isSearchActive ? searchValue : ''}
          query={queryType}
          shouldShowCommunitiesMessage={!hasConnectedCommunities}
          tags={hashtag ? [`#${String(hashtag)}`] : []}
        />
      </div>
    </BaseLayoutPage>
  );
};

export { FeedPage };
