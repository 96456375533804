import { gql } from '@apollo/client';

const PROJECT_SETTINGS_FRAGMENT = gql`
  fragment projectSettingsFragment on ProjectSettingsGraphType {
    accessTokenExpiry
    hasHyperlinksInPost
    hasModuleProgression
    hasScheduler
    impersonateUserLink
    refreshTokenExpiry
    hasGroupRequest
  }
`;

export { PROJECT_SETTINGS_FRAGMENT };
