import { IReactChildren } from '@netfront/common-library';
import NextLink from 'next/link';

import { UserAvatar } from '../UserAvatar';

import { UserDetailsHeaderProps } from './UserDetailsHeader.interfaces';

export const UserDetailsHeader = ({ avatar, description, displayName, href, isOnline = false, size }: UserDetailsHeaderProps) => {
  const Component = ({ children }: IReactChildren) =>
    href ? (
      <NextLink href={href} legacyBehavior>
        <a aria-label="User details" className="c-user-details-header">
          {children}
        </a>
      </NextLink>
    ) : (
      <div aria-label="User details" className="c-user-details-header">
        {children}
      </div>
    );

  return (
    <Component>
      <div className="c-user-details-header__avatar">
        <UserAvatar avatar={avatar} displayName={displayName} isOnline={isOnline} size={size} />
      </div>
      <div className="c-user-details-header__details">
        <span className="c-user-details-header__display-name">{displayName}</span>
        {description && <span className="c-user-details-header__description">{description}</span>}
      </div>
    </Component>
  );
};
