export * from './createComment.graphql';
export * from './createPost.graphql';
export * from './createPostAsset.graphql';
export * from './deleteAsset.graphql';
export * from './deleteRelatePost.graphql';
export * from './deleteCommentRelate.graphql';
export * from './deleteComment.graphql';
export * from './postRelate.graphql';
export * from './commentRelate.graphql';
export * from './updateComment.graphql';
export * from './updatePost.graphql';
export * from './createPostReport.graphql';
