import { gql } from '@apollo/client';

const IDBPostPollGraphTypeFragment = gql`
  fragment poll on PostGraphType {
    poll {
      id
      items {
        id
        title
        votes {
          pollItemId
          userId
        }
        votesCount
      }
      postId
      title
      userVote {
        pollItemId
      }
    }
  }
`;

export { IDBPostPollGraphTypeFragment };
