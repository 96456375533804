import { CommunityMembersQueryResult, IGetAcceptedCommunityMembersOptions } from './useGetCommunityUsers.interface';

import { bonoboClient } from '../../apollo';
import { useGetCommunityUsersLazyQuery } from '../../bonobo';

export const useGetCommunityUsers = (options?: IGetAcceptedCommunityMembersOptions) => {
  const { onCompleted, onError } = options ?? ({} as IGetAcceptedCommunityMembersOptions);

  const [getCommunityUsers, { loading: isLoading, error, fetchMore, refetch }] = useGetCommunityUsersLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      const result = data.communityConnection?.users;
      onCompleted(result?.edges as CommunityMembersQueryResult[], result?.totalCount ?? 0);
    },
    onError,
  });

  return { getCommunityUsers, isLoading, error, fetchMore, refetch };
};
