import { useEkardoMutation } from '@netfront/ekardo-content-library';

import { SET_USER_ANSWER_MULTI_LINE_TEXT_MUTATION } from './useSetUserAnswerMultiLineText.graphql';
import {
  IHandleSetUserAnswerMultiLineTextParams,
  ISetUserAnswerMultiLineTextMutationGraphQLResponse,
  ISetUserAnswerMultiLineTextMutationVariables,
  IUseSetUserAnswerMultiLineText,
  IUseSetUserAnswerMultiLineTextOptions,
} from './useSetUserAnswerMultiLineText.interfaces';

const useSetUserAnswerMultiLineText = (options?: IUseSetUserAnswerMultiLineTextOptions): IUseSetUserAnswerMultiLineText => {
  const { mutation, onCompleted, onError, product, token } = options ?? ({} as IUseSetUserAnswerMultiLineTextOptions);

  const [executeSetUserAnswerMultiLineText, { loading: isLoading }] = useEkardoMutation<
    ISetUserAnswerMultiLineTextMutationGraphQLResponse,
    ISetUserAnswerMultiLineTextMutationVariables
  >({
    mutation: mutation ?? SET_USER_ANSWER_MULTI_LINE_TEXT_MUTATION,
    options: {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          questionAnswer: { setUserAnswer },
        } = data;

        onCompleted({
          setUserAnswer,
        });
      },
      onError,
    },
    product,
    token,
  });

  const handleSetUserAnswerMultiLineText = async ({
    contentPageId,
    contentSnippetFormId,
    contentSnippetQuestionId,
    status,
    text,
    userFlowStepTrackId,
  }: IHandleSetUserAnswerMultiLineTextParams) => {
    await executeSetUserAnswerMultiLineText({
      variables: {
        contentPageId,
        contentSnippetFormId,
        contentSnippetQuestionId,
        status,
        text,
        userFlowStepTrackId,
      },
    });
  };

  return {
    handleSetUserAnswerMultiLineText,
    isLoading,
  };
};

export { useSetUserAnswerMultiLineText };
