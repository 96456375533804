import { CreateCommunityAssetOptions } from './useCreateCommunityAsset.interface';

import { bonoboClient } from '../../apollo';
import { useCreateCommunityAssetMutation } from '../../bonobo/generated.graphql';

export const useCreateCommunityAsset = (options?: CreateCommunityAssetOptions) => {
  const { onCompleted, onError } = options ?? ({} as CreateCommunityAssetOptions);

  const [createCommunityAsset, { loading: isLoading, error }] = useCreateCommunityAssetMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { createCommunityAsset, isLoading, error };
};
