import { gql } from '@apollo/client';

const ANSWER_CUSTOM_FIELDS_MUTATION = gql`
  mutation answerCustomFields($userId: Int, $projectId: String!, $responses: [CustomFieldResponseInputType!]!) {
    customFieldResponse {
      answerCustomFields(userId: $userId, projectId: $projectId, reponses: $responses)
    }
  }
`;

export { ANSWER_CUSTOM_FIELDS_MUTATION };
