import { useEffect, useState } from 'react';

import { getAccessToken } from '@netfront/gelada-identity-library';

const useGetRegisterUrl = (): { registerUrl: string } => {
  const token = getAccessToken();
  const [registerUrl, setRegisterUrl] = useState<string>('/register');

  useEffect(() => {
    if (token) {
      setRegisterUrl('/dashboard');
    }
  }, [token]);

  return {
    registerUrl,
  };
};

export { useGetRegisterUrl };
