import { IAdditionalClassNames } from '@netfront/ui-library';

const IconRelateThankYouForSharing = ({ additionalClassNames }: IAdditionalClassNames) => {
  return (
    <img
      alt="Thank you for sharing"
      className={additionalClassNames}
      src="/images/breathing-space/relates/icon-thank-you-for-sharing.png"
    />
  );
};

export { IconRelateThankYouForSharing };
