import { IGetConversationsOptions, ConversationsQueryResult } from './useGetConversations.interface';

import { bonoboClient } from '../../apollo';
import { useGetConversationsLazyQuery } from '../../bonobo/generated.graphql';

export const useGetConversations = (options?: IGetConversationsOptions) => {
  const { onCompleted, onError } = options ?? ({} as IGetConversationsOptions);

  const [getConversations, { loading: isLoading, error, fetchMore, refetch }] = useGetConversationsLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      const posts = data.message?.getConversationList;
      onCompleted(posts?.edges as unknown as ConversationsQueryResult[], posts?.totalCount ?? 0);
    },
    onError,
  });

  return { getConversations, isLoading, error, fetchMore, refetch };
};
