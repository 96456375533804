import { AppStoreLinks } from '../AppStoreLinks';

export const AppBanner = () => (
  <section className="bg-blue-100 relative">
    <div className="container container-sm">
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 relative">
          <img alt="Breathing space app" className="absolute top-0 -mt-26" src="/images/breathing-space/mockup-app.png" />
        </div>

        <div className="w-full md:w-1/2 relative py-24">
          <span className="h5 weight-600 text-uppercase">Download</span>
          <h2 className="text-uppercase mb-6">Get the app today</h2>
          <AppStoreLinks />
        </div>
      </div>
    </div>
  </section>
);
