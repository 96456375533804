import { IAdditionalClassNames } from '@netfront/ui-library';

const IconMessage = ({ additionalClassNames }: IAdditionalClassNames) => {
  return (
    <svg className={additionalClassNames} id="icon-messages-inactive" viewBox="0 0 11.007 8.469" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.8,0H1.2A1.2,1.2,0,0,0,0,1.2V7.265a1.2,1.2,0,0,0,1.2,1.2H9.8a1.2,1.2,0,0,0,1.2-1.2V1.2A1.2,1.2,0,0,0,9.8,0M9.731.659,5.847,3.738a.553.553,0,0,1-.687,0L1.276.659Zm.618,6.606a.545.545,0,0,1-.545.544H1.2a.545.545,0,0,1-.544-.544V1.2a.538.538,0,0,1,.033-.165L4.75,4.254a1.207,1.207,0,0,0,1.507,0l4.058-3.217a.523.523,0,0,1,.034.166Z"
        data-name="Path 539"
        id="Path_539"
        stroke="currentColor"
      />
    </svg>
  );
};

export { IconMessage };
