import { IDeletePostOptions } from './useDeletePost.interface';

import { bonoboClient } from '../../apollo';
import { useDeletePostMutation } from '../../bonobo/generated.graphql';

export const useDeletePost = (options?: IDeletePostOptions) => {
  const { onCompleted, onError, postId } = options ?? ({} as IDeletePostOptions);

  const [deletePostMutation, { loading: isDeleteLoading, error }] = useDeletePostMutation({
    client: bonoboClient,
    onCompleted: () => {
      if (!onCompleted) {
        return;
      }
      onCompleted(postId as number);
    },
    onError,
  });

  return { deletePostMutation, isDeleteLoading, error };
};
