import { IAdditionalClassNames } from '@netfront/ui-library';

const IconComment = ({ additionalClassNames }: IAdditionalClassNames) => {
  return (
    <svg
      className={additionalClassNames}
      height="18.586"
      id="icon-comment-inactive"
      viewBox="0 0 18.586 18.586"
      width="18.586"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.586,9.293A9.293,9.293,0,1,1,9.293,0a9.293,9.293,0,0,1,9.293,9.293" data-name="Path 556" fill="#fff" id="Path_556" />
      <path
        className="c-icon__outline"
        d="M4.872,2.25C2.181,2.25,0,4.022,0,6.208A3.513,3.513,0,0,0,1.085,8.7,4.841,4.841,0,0,1,.042,10.518a.151.151,0,0,0-.029.166.149.149,0,0,0,.139.091A4.352,4.352,0,0,0,2.828,9.8a5.8,5.8,0,0,0,2.044.369c2.691,0,4.872-1.772,4.872-3.958S7.562,2.25,4.872,2.25Z"
        data-name="Icon awesome-comment"
        fill="none"
        id="Icon_awesome-comment"
        stroke="#48756f"
        strokeWidth="1"
        transform="translate(4.5 2.785)"
      />
    </svg>
  );
};

export { IconComment };
