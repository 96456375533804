import { IGetUserOption } from './useGetUser.interface';

import { DBUser } from '../../../interfaces';
import { bonoboClient } from '../../apollo';
import { useGetUserLazyQuery } from '../../bonobo/generated.graphql';

export const useGetUser = (options?: IGetUserOption) => {
  const { onCompleted, onError } = options ?? ({} as IGetUserOption);

  const [getUser, { loading: isLoading, error, refetch }] = useGetUserLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'standby',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data.user?.getUser as DBUser);
    },
    onError,
  });

  return { getUser, isLoading, error, refetch };
};
