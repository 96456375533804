import { useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { useGoogleAnalytics } from '@netfront/ekardo-content-library';
import { Button, Dialog, RadioButtonGroup } from '@netfront/ui-library';
import { ESocialAnalyticEvents } from 'interfaces';
import NextLink from 'next/link';

import { Comment } from '../Comment';
import { CommentBox } from '../CommentBox';
import { DropdownMenu } from '../DropdownMenu';
import { IconComment, IconEllipsis, IconLike, IconPin, IconShare } from '../Icons';
import { Poll } from '../Poll';
import { PostButton } from '../PostButton';
import { PostFeeling } from '../PostFeeling';
import { PostRelateIconList } from '../PostRelateIconList';
import { PostRelatesDialog } from '../PostRelatesDialog';
import { PostShareDialog } from '../PostShareDialog';
import { ReactionToolbar, useReactionToolbar } from '../ReactionToolbar';
import { SharedPost } from '../SharedPost';
import { UserDetailsHeader } from '../UserDetailsHeader';

import { getMessageMarkup } from './Post.helpers';
import { IUpdateRelateParams, PostProps } from './Post.interfaces';

import { REPORT_LABELS } from '.';
import { CommentConnection, CommentGraphType, ECommunityRole, ERelateType, EReportType } from '../../../services';
import { formatDate, formatTime, shouldTrackAnalytics } from '../../../utils';

const Post = ({
  assets,
  avatar,
  comments,
  community,
  communityRole,
  displayName,
  date,
  feeling,
  hasRelated: hasRelatedProp = false,
  message,
  id,
  isCommentSubmitting,
  isPinned,
  isOnline = false,
  isOwner = false,
  poll,
  relateCount,
  onPostRelate,
  onPostRelatesClick: onPostRelatesClickProp,
  onComment,
  onCommentRelate,
  onCommentReply,
  onVotePoll,
  userProfileLink,
  onDelete,
  onEdit,
  onTogglePin,
  onReport,
  onSharePost,
  shareCount = 0,
  sharedPost,
  shouldHidePostOptions = false,
  shouldShowPostDetails = false,
}: PostProps) => {
  const { trackEvent } = useGoogleAnalytics();
  const { isReactionToolbarOpen, closeReactToolBar, openReactToolBar } = useReactionToolbar();
  const { isToggled: isDeletePostDialogOpen, toggle: toggleDeletePostDialog } = useToggle();
  const { isToggled: isReportPostDialogOpen, toggle: toggleReportPostDialog } = useToggle();

  const [comment, setComment] = useState<string>('');
  const [isCommentsOpen, setIsCommentsOpen] = useState<boolean>(false);
  const [isPostRelatesDialogOpen, setIsPostRelatesDialogOpen] = useState<boolean>(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState<boolean>(false);

  const [hasRelated, setHasRelated] = useState<boolean>(hasRelatedProp);
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [reportType, setReportType] = useState<EReportType>(EReportType.Harassment);

  const onDisplayOptions = (isDisplay: boolean) => setIsOptionsOpen(isDisplay);

  const toggleCommentsVisibility = () => {
    if(shouldTrackAnalytics()) {
      trackEvent(ESocialAnalyticEvents.SocialPostViewHiddenComments, {
        postId: id
      })
    };

    setIsCommentsOpen(!isCommentsOpen)
  };

  const openPostRelatesDialog = () => setIsPostRelatesDialogOpen(true);
  const closePostRelatesDialog = () => setIsPostRelatesDialogOpen(false);

  const openPostShareDialog = () => setIsShareDialogOpen(true);
  const closePostShareDialog = () => setIsShareDialogOpen(false);

  const onPostRelatesClick = () => {
    openPostRelatesDialog();

    if (onPostRelatesClickProp) {
      onPostRelatesClickProp(id);
    }
  };

  const onCommentChange = (value: string) => {
    setComment(value);
  };

  const onCommentSubmit = () => {
    if (onComment) {
      onComment(id, comment);
    }

    setComment('');
  };

  const onRelateClick = ({ action, hasRelated: hasRelatedToPost, id: postId, relateType }: IUpdateRelateParams) => {
    if (onPostRelate) {
      onPostRelate({ action, hasRelated: hasRelatedToPost, id: postId, relateType });
    }

    setHasRelated(hasRelatedToPost);
  };

  const messageHtml = getMessageMarkup(String(message));

  return (
    <div className="c-post">
      <div className="c-post-user">
        <UserDetailsHeader
          avatar={avatar}
          description={date && `${formatDate(date)} ${formatTime(date)}`}
          displayName={displayName}
          href={userProfileLink}
          isOnline={isOnline}
        />

        {community && (
          <NextLink className="c-post__community" href={`/social/communities/${String(community.key)}`} >
            <span>
                - shared in <strong> {community.title}</strong>
            </span>

            {communityRole === ECommunityRole.Owner ||  communityRole === ECommunityRole.Moderator ? <span className="c-post__badge">Moderator</span> : null}
          </NextLink>
        )}

        <div aria-label="Post options" className="c-post-user__settings">
          {isPinned && (
            <div aria-label="Pinned post" title="Pinned post">
              <IconPin additionalClassNames="c-post__pin" />
            </div>
          )}

          {!shouldHidePostOptions && (
            <DropdownMenu
              additionalClassNames="c-post-options"
              dropdownId="options"
              isDisplayContent={isOptionsOpen}
              trigger={
                <>
                  <span className="h-hide-visually">Post options</span>
                  <IconEllipsis />
                </>
              }
              onDisplayContent={onDisplayOptions}
            >
              {!isOwner && (
                <ul>
                  {communityRole === ECommunityRole.Owner && (
                    <li>
                      <button
                        onClick={() => {
                          if (onEdit) {
                            onEdit(id);
                            setIsOptionsOpen(false);
                          }
                        }}
                      >
                        Edit
                      </button>
                    </li>
                  )}
                   
                  {communityRole === ECommunityRole.Owner ||
                    (communityRole === ECommunityRole.Moderator && (
                      <>
                        <li>
                          <button
                            onClick={() => {
                              if (onDelete) {
                                onDelete(id);
                                setIsOptionsOpen(false);
                              }
                            }}
                          >
                            Delete
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() => {
                              if (onTogglePin) {
                                onTogglePin(id, !isPinned);
                                setIsOptionsOpen(false);
                              }
                            }}
                          >
                            {isPinned ? 'Unpin' : 'Pin'}
                          </button>
                        </li>
                      </>
                    ))}
                  <li>
                    <button
                      onClick={() => {
                        if (onReport) {
                          onReport(id, EReportType.Spam);
                          setIsOptionsOpen(false);
                        }
                      }}
                    >
                      Report
                    </button>
                  </li>
                </ul>
              )}

              {isOwner && (
                <ul>
                  <li>
                    <button
                      onClick={() => {
                        if (onEdit) {
                          onEdit(id);
                          setIsOptionsOpen(false);
                        }
                      }}
                    >
                      Edit
                    </button>
                  </li>
                  <li>
                    <button onClick={toggleDeletePostDialog}>Delete</button>
                  </li>
                  {communityRole !== ECommunityRole.Member && (
                    <li>
                      <button
                        onClick={() => {
                          if (onTogglePin) {
                            onTogglePin(id, !isPinned);
                            setIsOptionsOpen(false);
                          }
                        }}
                      >
                        {isPinned ? 'Unpin' : 'Pin'}
                      </button>
                    </li>
                  )}
                  <li>
                    <button onClick={toggleReportPostDialog}>Report</button>
                  </li>
                </ul>
              )}
            </DropdownMenu>
          )}
        </div>
      </div>

      <PostFeeling feeling={feeling} />

      {message && (
        <div
          className="c-post-message"
          dangerouslySetInnerHTML={{
            __html: messageHtml,
          }}
        />
      )}

      {assets &&
        assets.length > 0 &&
        assets.map(({ assetId, fileName, presignedUrl }) => (
          <img key={assetId} alt={fileName} className="c-post-image" src={presignedUrl} />
        ))}

      {poll && <Poll {...poll} isOwner={isOwner} onVotePoll={onVotePoll} />}

      {sharedPost && (
        <SharedPost
          post={{
            assets: sharedPost.assets,
            avatar: sharedPost.author?.profileImage?.presignedUrl,
            id: sharedPost.id,
            date: sharedPost.createdDateTime,
            displayName: sharedPost.author?.displayedName,
            message: sharedPost.message,
            isOnline: sharedPost.author?.isOnline,
            poll: sharedPost.poll,
            onVotePoll: onVotePoll,
            userProfileLink: `/social/profile/${String(sharedPost.author?.key)}`,
          }}
        />
      )}

      {shouldShowPostDetails && (
        <div className="c-post-details">
          <div className="c-post-details__item">
            <ReactionToolbar
              isReactionToolbarOpen={isReactionToolbarOpen}
              onMouseEnter={openReactToolBar}
              onMouseLeave={closeReactToolBar}
              onRelateClick={(relateType) => {
                onRelateClick({
                  action: hasRelated ? 'CHANGE' : 'ADD',
                  hasRelated: true,
                  id,
                  relateType,
                });

                closeReactToolBar();
              }}
            >
              <PostButton
                count={relateCount}
                icon={() => <IconLike additionalClassNames="c-post-button__icon" isActive={hasRelated} />}
                supportiveText={`${hasRelated ? 'Unlike' : 'Like'} post`}
                onClick={() => {
                  onRelateClick({
                    action: hasRelated ? 'REMOVE' : 'ADD',
                    hasRelated: !hasRelated,
                    id,
                    relateType: ERelateType.Like,
                  });
                }}
              />
            </ReactionToolbar>
          </div>
          <div className="c-post-details__item">
            <PostButton
              count={comments && comments.length}
              icon={() => <IconComment additionalClassNames="c-post-button__icon" />}
              supportiveText="Comment on post"
              onClick={() => {
                toggleCommentsVisibility();
              }}
            />
          </div>

          <div className="c-post-details__item">
            <PostButton
              count={shareCount}
              icon={() => <IconShare additionalClassNames="c-post-button__icon" />}
              isDisabled={Boolean(sharedPost)}
              supportiveText="Share post"
              onClick={() => openPostShareDialog()}
            />
            {isShareDialogOpen && (
              <PostShareDialog
                isOpen={isShareDialogOpen}
                post={{ assets, avatar, id, date, displayName, message, poll, userProfileLink }}
                title="Share post"
                onClose={closePostShareDialog}
                onShare={(params) => {
                  if (onSharePost) {
                    onSharePost(params);
                    closePostShareDialog();
                  }
                }}
              />
            )}
          </div>
        </div>
      )}
      <div className="c-post-footer">
        <div className="h-flex">
          {Boolean(relateCount) && (
            <>
              <button className="c-post-relates" title="See post relates" onClick={onPostRelatesClick}>
                <PostRelateIconList />
                <span>{relateCount}</span>
              </button>
              {isPostRelatesDialogOpen && (
                <PostRelatesDialog isOpen={isPostRelatesDialogOpen} postId={id} onClose={closePostRelatesDialog} />
              )}
            </>
          )}

          {comments && comments.length > 0 && (
            <button className="c-post-footer__comment-button" onClick={toggleCommentsVisibility}>
              {`${isCommentsOpen ? 'Hide' : 'Show'} comments (${comments.length})`}
            </button>
          )}
        </div>

        {isCommentsOpen && (
          <>
            <form className="c-post-reply">
              <CommentBox
                id="comment"
                isDisabled={isCommentSubmitting}
                labelText="Comment"
                name="comment"
                placeholder={`Reply to ${String(displayName)}...`}
                value={comment}
                isLabelHidden
                onChange={onCommentChange}
              />

              <Button
                isDisabled={Boolean(!comment) || isCommentSubmitting}
                isLoading={isCommentSubmitting}
                size="xs"
                text="Reply"
                type="submit"
                onClick={onCommentSubmit}
              />
            </form>

            {comments && (
              <div className="c-post-comments">
                {comments.map(
                  ({
                    author,
                    createdDateTime,
                    comments: postComment,
                    hasRelated: hasCommentRelated,
                    id: commentId,
                    message: commentMessage,
                  }: CommentGraphType) => {
                    const { edges } = postComment ?? ({} as CommentConnection);
                    const replies = edges?.map(({ node }) => node);

                    return (
                      <Comment
                        key={commentId}
                        avatar={author?.profileImage?.presignedUrl}
                        comments={replies}
                        date={createdDateTime}
                        displayName={String(author?.displayedName)}
                        hasRelated={hasCommentRelated}
                        id={commentId}
                        message={commentMessage}
                        onCommentRelate={onCommentRelate}
                        onCommentReply={onCommentReply}
                      />
                    );
                  },
                )}
              </div>
            )}
          </>
        )}
      </div>

      {isDeletePostDialogOpen && (
        <Dialog
          title="Delete post"
          isOpen
          onCancel={toggleDeletePostDialog}
          onClose={toggleDeletePostDialog}
          onConfirm={() => {
            if (onDelete) {
              onDelete(id);
              toggleDeletePostDialog();
              setIsOptionsOpen(false);
            }
          }}
        >
          Are you sure you want to delete this post?
        </Dialog>
      )}

      {isReportPostDialogOpen && (
        <Dialog
          title="Report post"
          isOpen
          onCancel={toggleReportPostDialog}
          onClose={toggleReportPostDialog}
          onConfirm={() => {
            if (onReport) {
              onReport(id, reportType);
              toggleReportPostDialog();
              setIsOptionsOpen(false);
            }
          }}
        >
          Are you sure you want to report this post?
          <RadioButtonGroup
            items={Object.keys(REPORT_LABELS).map((type, key) => ({
              id: `report-${String(key)}`,
              labelText: REPORT_LABELS[type],
              value: type,
            }))}
            name="reportType"
            selectedValue={reportType}
            onChange={({ target: { value } }) => setReportType(value as EReportType)}
          />
        </Dialog>
      )}
    </div>
  );
};

export { Post };
