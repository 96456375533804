import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { GET_PAGINATED_GROUPS } from './useGetGroups.graphql';
import {
  IGetGroupsQueryGraphQLResponse,
  IGetGroupsQueryVariables,
  IHandleGetGroupsParams,
  IUseGetGroupsOptions,
} from './useGetGroups.interfaces';

const useGetGroups = (options?: IUseGetGroupsOptions) => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetGroupsOptions);

  const [executeGetPaginatedGroups, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetGroupsQueryGraphQLResponse,
    IGetGroupsQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          group: { getPaginatedGroups: groups },
        } = data;

        onCompleted({
          groups,
        });
      },
      onError,
    },
    query: query ?? GET_PAGINATED_GROUPS,
    token,
  });

  const handleGetGroups = async ({ projectId, status }: IHandleGetGroupsParams) => {
    await executeGetPaginatedGroups({
      variables: {
        projectId,
        status,
      },
    });
  };

  return {
    handleGetGroups,
    isLoading,
  };
};

export { useGetGroups };
