import { IAcceptOrDeclineUserConnectionOptions } from './useAcceptOrDeclineUserConnection.interface';

import { bonoboClient } from '../../apollo';
import { useAcceptOrDeclineUserConnectionMutation } from '../../bonobo/generated.graphql';

export const useAcceptOrDeclineUserConnection = (options?: IAcceptOrDeclineUserConnectionOptions) => {
  const { onCompleted, onError } = options ?? ({} as IAcceptOrDeclineUserConnectionOptions);

  const [acceptOrDeclineUserConnection, { loading: isResponseToConnectionLoading, error }] = useAcceptOrDeclineUserConnectionMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data);
    },
    onError,
  });

  return { acceptOrDeclineUserConnection, isResponseToConnectionLoading, error };
};
