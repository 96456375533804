import { useEffect, useState } from 'react';

import { Dialog } from '@netfront/ui-library';
import { isPast } from 'date-fns';

import { PollProps } from './Poll.interfaces';

import { VoteGraphType } from '../../../services';
import { getValidClassNames } from '../../../utils';

const Poll = ({ end, id, postId, title, isOwner = false, items, userVote, onVotePoll }: PollProps) => {
  const [totalVotes, setTotalVotes] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<number | undefined>(userVote?.pollItemId);
  const [isPollDialogOpen, setIsPollDialogOpen] = useState<boolean>(false);

  const isVotingClosed = isPast(new Date(String(end)));

  const openPollDialog = () => setIsPollDialogOpen(true);

  const closePollDialog = () => setIsPollDialogOpen(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { id: inputId },
    } = event;

    setSelectedId(Number(inputId));

    openPollDialog();
  };

  const onVoteRequest = () => {
    if (!selectedId) {
      return;
    }

    if (onVotePoll) {
      onVotePoll({ id, items, postId, title, userVote: { ...userVote, pollItemId: selectedId } as VoteGraphType });
      closePollDialog();
    }
  };

  useEffect(() => {
    if (!items) {
      return;
    }

    setTotalVotes(items.map(({ votesCount }) => votesCount).reduce((prev, next) => prev + next));
  }, [items]);

  return (
    <>
      <div
        className={getValidClassNames({
          'c-poll': true,
          'c-poll--voted': Boolean(userVote),
          'c-poll--disabled': isVotingClosed,
        })}
      >
        <fieldset>
          <legend className="c-poll__legend">{title}</legend>

          {end && (
            <p className="c-poll__end-date">
              Voting {`${isPast(new Date(String(end))) ? 'closed' : 'ends'}`}: {new Date(end).toDateString()}
            </p>
          )}

          {items?.map(({ id: optionId, title: optionTitle, votesCount }) => {
            const percentage = userVote || isOwner ? `${votesCount ? (votesCount / totalVotes) * 100 : 0}` : '0';

            const shouldShowVotePercentage = selectedId ?? isOwner;

            return (
              <div key={optionId} className="c-poll-option__container">
                <div
                  className={getValidClassNames({
                    'c-poll-option': true,
                    'c-poll-option--voted': optionId === userVote?.pollItemId,
                  })}
                >
                  <input
                    checked={optionId === userVote?.pollItemId}
                    className="c-poll-option__input"
                    disabled={isVotingClosed || Boolean(userVote)}
                    id={String(optionId)}
                    name={`poll-${id}`}
                    type="radio"
                    onChange={onVotePoll && handleChange}
                  />
                  <div
                    className={getValidClassNames({
                      'c-poll-option__scale': true,
                      'c-poll-option__scale--selected': optionId === userVote?.pollItemId,
                    })}
                    style={{ width: `${percentage}%` }}
                  ></div>
                  <label className="c-poll-option__label" htmlFor={String(optionId)}>
                    {optionTitle}
                  </label>
                </div>
                {shouldShowVotePercentage && (
                  <span
                    className={getValidClassNames({
                      'c-poll-option__percentage': true,
                      'c-poll-option__percentage--voted': optionId === userVote?.pollItemId,
                    })}
                  >{`${parseInt(percentage)}%`}</span>
                )}
              </div>
            );
          })}
        </fieldset>

        <span className="c-poll__votes">{`${totalVotes} ${totalVotes === 1 ? 'vote' : 'votes'}`}</span>
      </div>
      <Dialog
        isOpen={isPollDialogOpen}
        title="Poll confirmation"
        onCancel={closePollDialog}
        onClose={closePollDialog}
        onConfirm={onVoteRequest}
      >
        <p>Confirm your selection for {items?.find(({ id: itemId }) => itemId === Number(selectedId))?.title}</p>
      </Dialog>
    </>
  );
};

export { Poll };
