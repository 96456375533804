import { IAdditionalClassNames } from '@netfront/ui-library';

const IconTick = ({ additionalClassNames }: IAdditionalClassNames) => {
  return (
    <svg
      className={additionalClassNames}
      fill="none"
      stroke="currentColor"
      strokeWidth={1.5}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export { IconTick };
