import { Button } from '@netfront/ui-library';

import { SingleFormPage } from '../../../components';

const PAGE_TITLE = 'Activate account';

const ActivateAccountPage = () => {
  return (
    <SingleFormPage title={PAGE_TITLE}>
      <h1 className="color-primary h5 mb-2 text-uppercase">Almost there</h1>
      <h2 className="color-black mb-6 text-uppercase">Verify your account</h2>

      <p>We&#39;ve sent you an email with a link to verify your account</p>

      <p>Already verified your account?</p>
      <Button linkButton={{ id: 'login', url: '/login' }} text="Login" />
    </SingleFormPage>
  );
};

export { ActivateAccountPage };
