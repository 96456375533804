import { IGetCommunityOption } from './useGetCommunity.interface';

import { DBCommunity } from '../../../interfaces';
import { bonoboClient } from '../../apollo';
import { useGetCommunityLazyQuery } from '../../bonobo/generated.graphql';

export const useGetCommunity = (options?: IGetCommunityOption) => {
  const { onCompleted, onError } = options ?? ({} as IGetCommunityOption);

  const [getCommunity, { loading: isLoading, error, refetch }] = useGetCommunityLazyQuery({
    client: bonoboClient,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }

      onCompleted(data.community?.get as DBCommunity);
    },
    onError,
  });

  return { getCommunity, isLoading, error, refetch };
};
