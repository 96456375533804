import { useEffect, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { Checkbox, Dialog, Select, Textarea } from '@netfront/ui-library';
import { useSocialContext } from 'contexts';
import { useRouter } from 'next/router';

import { DBCommunity } from '../../../../interfaces';
import {
  CommunityConnection,
  CommunityGraphType,
  CommunityMembersQueryResult,
  ECommunityRole,
  EConnectionStatus,
  GetUserCommunitiesQueryResult,
  useCreateConversations,
  useGetCommunitiesByUser,
  useGetCommunityUsers,
  useGetConnectedUsers,
  UserGraphType,
} from '../../../../services';
import { BaseLayoutPage, EmptyMessage, ListCard, ListCardSkeleton, SearchInput, useSearchInput } from '../../../Social';

const NewMessagesPage = () => {
  const { push } = useRouter();
  const { user: loggedUser } = useSocialContext();
  const { isSearchActive, onSearchClear, onSearchSubmit, searchValue } = useSearchInput();
  const { isToggled: isBulkMessageDialogOpen, toggle: toggleIsBulkMessageDialogOpen } = useToggle();

  const [connections, setConnections] = useState<UserGraphType[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [isSelectUsersChecked, setIsSelectUsersChecked] = useState<boolean>(false);
  const [isSelectAllUsersChecked, setIsSelectAllUsersChecked] = useState<boolean>(false);
  const [moderatorCommunities, setModeratorCommunities] = useState<DBCommunity[]>([]);
  const [bulkMessage, setBulkMessage] = useState<string>('');
  const [communityFilterValue, setCommunityFilterValue] = useState<string | number>('all');

  const getMatchingCommunities = (loggedUserActiveCommunities?: CommunityConnection, otherUserActiveCommunities?: CommunityConnection) => {
    if (loggedUserActiveCommunities?.edges?.length === 1) {
      return;
    }

    const matches = loggedUserActiveCommunities?.edges
      ?.map(({ node }) => node as CommunityGraphType)
      .filter(({ id }) => otherUserActiveCommunities?.edges?.map(({ node }) => node?.id).includes(id));

    if (!matches || matches.length === 0) {
      return;
    }

    return matches.length > 1 ? `${matches[0].title} + more` : matches[0].title;
  };

  const getUserCommunitiesCompleted = (communities: GetUserCommunitiesQueryResult[]) => {
    setModeratorCommunities(
      communities
        .map(({ node }) => node)
        .filter(({ userConnection }) => userConnection?.role === ECommunityRole.Owner || userConnection?.role === ECommunityRole.Moderator),
    );
  };

  const handleCreateConversationsCompleted = () => {
    setSelectedUsers([]);
    toggleIsBulkMessageDialogOpen();
    push('/social/messages');
  };

  const handleGetCommunityUsersCompleted = (members: CommunityMembersQueryResult[]) => {
    setConnections(members.map(({ node }) => node.user as UserGraphType).filter(({ id }) => id !== loggedUser?.id));

    setIsSelectAllUsersChecked(false);
  };

  const { getUserCommunities } = useGetCommunitiesByUser({
    onCompleted: getUserCommunitiesCompleted,
  });

  const { createConversations } = useCreateConversations({
    onCompleted: handleCreateConversationsCompleted,
  });

  const { getCommunityUsers, isLoading: isCommunityUsersLoading = false } = useGetCommunityUsers({
    onCompleted: handleGetCommunityUsersCompleted,
  });

  const { getConnectedUsers, isLoading: isGetConnectedUsersLoading } = useGetConnectedUsers({
    onCompleted: (allConnections) => setConnections(allConnections.map(({ node }) => node)),
  });

  useEffect(() => {
    if (connections.length) {
      return;
    }

    getUserCommunities({
      variables: {
        shouldIncludeCommunities: true,
        shouldIncludeUserConnection: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getConnectedUsers({
      variables: {
        includeConnections: true,
        includeSharedCommunity: moderatorCommunities.length > 0,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moderatorCommunities]);

  const hasAcceptedConnections = !isGetConnectedUsersLoading && !isCommunityUsersLoading && connections.length > 0;
  const hasNoConnections = !isGetConnectedUsersLoading && !isCommunityUsersLoading && connections.length === 0;

  const acceptedConnectionsWithFilter = connections.filter((connection) =>
    searchValue && isSearchActive ? String(connection.displayedName).includes(searchValue) : connection,
  );

  const shouldShowSkeleton = isGetConnectedUsersLoading || isCommunityUsersLoading;

  return (
    <BaseLayoutPage
      breadcrumbItems={[{ href: '/social/messages', label: 'Messages' }, { label: 'New' }]}
      meta={{ seoDescription: `Create new conversation`, seoTitle: `New messsage` }}
      title="New messsage"
    >
      <div className="c-new-messages-page__container">
        <div className="c-new-messages-page__search">
          <SearchInput
            id="search"
            isSearchActive={isSearchActive}
            labelText="Search messages"
            name="search"
            placeholder="Search your connections to send a message"
            type="text"
            isLabelHidden
            onClear={onSearchClear}
            onSearch={onSearchSubmit}
          />
        </div>

        <section className="c-connections-page__section">
          {moderatorCommunities.length > 0 && (
            <div className="c-new-messages-page__actions">
              <div>
                <h2>Connections</h2>

                <div className="c-new-messages-page__checkboxes">
                  <Checkbox
                    id="selectUsers"
                    isChecked={isSelectUsersChecked}
                    labelText={`${isSelectUsersChecked && selectedUsers.length ? `${selectedUsers.length} selected` : 'Select users'}`}
                    name="selectUsers"
                    value="selectUsers"
                    onChange={({ target: { checked } }) => {
                      setIsSelectUsersChecked(checked);
                      setSelectedUsers([]);
                    }}
                  />
                  <Checkbox
                    id="allUsers"
                    isChecked={isSelectAllUsersChecked}
                    labelText={`Select all ${acceptedConnectionsWithFilter.length}`}
                    name="allUsers"
                    value="allUsers"
                    onChange={({ target: { checked } }) => {
                      setIsSelectAllUsersChecked(checked);
                      setIsSelectUsersChecked(checked);
                      setSelectedUsers(checked ? acceptedConnectionsWithFilter.map(({ id }) => id) : []);
                    }}
                  />
                </div>
              </div>
              <Select
                id="actions"
                isDisabled={selectedUsers.length === 0}
                labelDefaultText="Bulk actions"
                labelText="Bulk actions"
                name="actions"
                options={[{ id: 1, name: 'Send message', value: 'message' }]}
                isLabelHidden
                onChange={({ target: { value } }) => {
                  if (value === 'message') {
                    toggleIsBulkMessageDialogOpen();
                  }
                }}
              />
              <Dialog
                isOpen={isBulkMessageDialogOpen}
                title="Send bulk message"
                onClose={toggleIsBulkMessageDialogOpen}
                onConfirm={() => {
                  createConversations({
                    variables: {
                      message: bulkMessage,
                      receivers: selectedUsers,
                    },
                  });
                }}
              >
                <Textarea
                  id="bulkMessage"
                  labelText="Message"
                  name="bulkMessage"
                  value={bulkMessage}
                  isLabelHidden
                  onChange={({ target: { value } }) => setBulkMessage(value)}
                />
              </Dialog>

              {moderatorCommunities.length > 0 && (
                <div className="c-new-messages-page__filter">
                  <Select
                    id="community"
                    labelText="Filter community"
                    name="community"
                    options={[
                      { id: 0, name: 'All', value: 'all' },
                      ...moderatorCommunities.map(({ id, title }) => ({ id, name: title, value: id })),
                    ]}
                    value={communityFilterValue}
                    onChange={({ target: { value } }) => {
                      setCommunityFilterValue(value);

                      if (value === 'all') {
                        getConnectedUsers({
                          variables: {
                            includeConnections: true,
                            includeSharedCommunity: moderatorCommunities.length > 0,
                          },
                        });
                        return;
                      }

                      getCommunityUsers({
                        variables: {
                          communityId: Number(value),
                          shouldIncludeUser: true,
                          status: EConnectionStatus.Accepted,
                        },
                      });
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {shouldShowSkeleton && (
            <>
              <ListCardSkeleton />
              <ListCardSkeleton />
            </>
          )}

          {hasAcceptedConnections &&
            acceptedConnectionsWithFilter.map((user) => {
              return (
                <ListCard
                  key={user.id}
                  avatarImage={user.profileImage?.presignedUrl}
                  avatarTitle={user.displayedName}
                  {...(isSelectUsersChecked && {
                    checkBox: {
                      id: `message-user-${String(user.id)}`,
                      labelText: 'Select user',
                      name: 'select',
                      isChecked: selectedUsers.includes(Number(user.id)),
                      onChange: ({ target: { checked, value } }) => {
                        if (!checked) {
                          setIsSelectAllUsersChecked(false);
                        }
                        setSelectedUsers(
                          checked ? [...selectedUsers, Number(value)] : selectedUsers.filter((userId) => userId !== Number(value)),
                        );
                      },
                      value: String(user.id),
                      isLabelHidden: true,
                    },
                  })}
                  description="Send message"
                  displayName={user.displayedName}
                  hasArrow
                  {...(!isSelectUsersChecked && { href: `/social/messages/${String(user.key)}` })}
                  tag={getMatchingCommunities(loggedUser?.activeCommunities, user.activeCommunities)}
                />
              );
            })}

          {hasNoConnections && <EmptyMessage message="No connections found" />}
        </section>
      </div>
    </BaseLayoutPage>
  );
};

export { NewMessagesPage };
