import { gql } from '@apollo/client';

const GELADA_ORGANISATION_FRAGMENT = gql`
  fragment geladaOrganisationFragment on OrganisationGraphType {
    description
    id
    key
    logo @include(if: $shouldIncludeOrganisationLogo) {
      assetId
      contentType
      presignedUrl
      s3Key
    }
    name
    tag
    url
  }
`;

export { GELADA_ORGANISATION_FRAGMENT };
