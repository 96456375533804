import { IUpdatePollOptions } from './useUpdatePoll.interface';

import { bonoboClient } from '../../apollo';
import { useUpdatePollMutation } from '../../bonobo/generated.graphql';

export const useUpdatePoll = (options?: IUpdatePollOptions) => {
  const { onCompleted, onError } = options ?? ({} as IUpdatePollOptions);

  const [updatePoll, { loading: isUpdatePollLoading, error }] = useUpdatePollMutation({
    client: bonoboClient,
    onCompleted: (data) => {
      if (!onCompleted) {
        return;
      }
      onCompleted(data);
    },
    onError,
  });

  return { updatePoll, isUpdatePollLoading, error };
};
