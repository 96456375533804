import { useToggle } from '@netfront/common-library';

import { DropdownMenu } from '../DropdownMenu';

import { EMOJI_LIST } from './EmojiSelector.constants';
import { EmojiSelectorProps } from './EmojiSelector.interfaces';

export const EmojiSelector = ({ onSelect }: EmojiSelectorProps) => {
  const { isToggled: isMenuOpen, toggle } = useToggle();

  return (
    <>
      <DropdownMenu
        additionalClassNames="c-emoji-selector"
        dropdownId="notifications"
        isDisplayContent={isMenuOpen}
        trigger={
          <>
            😀<span className="h-hide-visually">Select emoji</span>
          </>
        }
        onDisplayContent={toggle}
      >
        <ul className="c-emoji-selector__options">
          {EMOJI_LIST.map((emoji, key) => (
            <li key={`emoji-${key}`} className="c-emoji-selector__emoji">
              <button
                onClick={(event) => {
                  event.preventDefault();
                  onSelect(event.currentTarget.innerText);
                  toggle();
                }}
              >
                {emoji}
              </button>
            </li>
          ))}
        </ul>
      </DropdownMenu>
    </>
  );
};
