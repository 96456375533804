import { FOOTER_MENU_ITEMS } from '../Footer.constants';

import { LogoLink } from '../../LogoLink';

const PrivateFooter = () => {
  return (
    <footer className="bg-primary mt-auto no-print py-2">
      <div className="container-xl flex flex-col md:items-center xl:flex-row items-center text-center">
        <div className="flex items-center">
          <LogoLink size="md" />
        </div>

        <nav className="flex flex-1 md:justify-end py-6 xl:justify-center">
          <ul className="flex flex-col md:flex-row m-0">
            {FOOTER_MENU_ITEMS.map(({ href, id, label }) => (
              <li key={id} className="color-white ml-0 mb-0">
                <a key={id} className="block color-white mb-6 md:mb-0 md:mx-8" href={href}>
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <div className="mb-0">
          <span className="color-white">
            Built by{' '}
            <a className="color-white" href="https://netfront.com.au/" target="_blank">
              Netfront
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export { PrivateFooter };
