import { Skeleton } from '@netfront/ui-library';

const CommentSkeleton = () => {
  return (
    <div className="h-flex">
      <div className="c-comment__avatar">
        <Skeleton borderRadius="50%" height="var(--g-spacing-2x-large)" width="var(--g-spacing-2x-large)" />
      </div>

      <div className="c-comment__content">
        <span className="c-comment__display-name">
          <Skeleton borderRadius="6px" height=".75rem" width="5rem" />
        </span>

        <div className="c-comment__message">
          <p>
            <Skeleton borderRadius="6px" height="var(--g-spacing)" width="40%" />
          </p>
        </div>
      </div>
    </div>
  );
};

export { CommentSkeleton };
