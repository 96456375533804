import { PostButtonProps } from './PostButton.interfaces';

const PostButton = ({ children, count = 0, icon, isDisabled, onClick, onMouseEnter, supportiveText }: PostButtonProps) => {
  return (
    <button className="c-post-button" disabled={isDisabled} title={supportiveText} onClick={onClick} onMouseEnter={onMouseEnter}>
      {icon()}
      <span className="h-hide-visually">{supportiveText}</span>
      <span className="c-post-button__count">{count}</span>
      {children}
    </button>
  );
};

export { PostButton };
