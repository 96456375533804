import React, { useState } from 'react';

import { getBEMClassName } from '@netfront/common-library';
import { FormField, FormFieldContainer, FORM_ELEMENT_CSS_IDENTIFIERS, FORM_FIELDS } from '@netfront/gelada-identity-library';
import { Label } from '@netfront/ui-library';
import cx from 'classnames';
import { Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import * as yup from 'yup';

import { Button } from '../Button';

import { REGISTRATION_FORM_BLOCK_CSS_IDENTIFIERS, REGISTRATION_FORM_FIELDS } from './RegistrationForm.constants';
import { IRegistrationForm } from './RegistrationForm.interfaces';

import { PasswordInputWithValidator } from '../../components';
import { useToast } from '../../hooks';

const RegistrationForm = ({
  buttonClassName,
  buttonText = 'Register',
  forgotPasswordUrl,
  isSubmitting,
  loginUrl,
  onRegister,
}: IRegistrationForm) => {
  const [password, setPassword] = useState<string>('');
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const {
    button: buttonElementCssId,
    buttonsLinks: buttonsLinksElementCssId,
    checkbox: checkboxElementCssId,
    container: containerElementCssId,
    forgotPassword: forgotPasswordElementCssId,
    form: formElementCssId,
    label: labelElementCssId,
    leftContainer: leftContainerElementCssId,
    link: linkCssId,
    login: loginElementCssId,
    name: nameElementCssId,
    register: registerElementCssId,
    rightContainer: rightContainerElementCssId,
  } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const { email: emailField, firstName: firstNameField, lastName: lastNameField } = FORM_FIELDS;

  const { id: emailId, label: emailLabel } = emailField;
  const { id: firstNameId, label: firstNameLabel } = firstNameField;
  const { id: lastNameId, label: lastNameLabel } = lastNameField;

  const { hasReadAndAgreedToPrivacyStatementAndTermsAndConditions: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsField } =
    REGISTRATION_FORM_FIELDS;

  const {
    id: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsId,
    label: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsLabel,
  } = hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsField;

  const { form: formBlockCssId } = REGISTRATION_FORM_BLOCK_CSS_IDENTIFIERS;

  const { push } = useRouter();
  const { handleToastError } = useToast();

  const handleLogin = () => {
    if (!loginUrl) {
      return;
    }

    push(loginUrl).catch((error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
    );
  };

  return (
    <>
      <Formik
        initialValues={{
          confirmPassword: '',
          email: '',
          firstName: '',
          hasReadAndAgreedToPrivacyStatementAndTermsAndConditions: false,
          lastName: '',
          password: '',
          displayName: '',
        }}
        validationSchema={yup.object().shape({
          email: yup.string().label(emailLabel).email(emailField.validationErrorMessage).required(),
          displayName: yup.string().label('Display name').required(),
          firstName: yup.string().label(firstNameLabel).required(),
          hasReadAndAgreedToPrivacyStatementAndTermsAndConditions: yup
            .bool()
            .oneOf([true], `${hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsLabel} is required`),
          lastName: yup.string().label(lastNameLabel).required(),
        })}
        onSubmit={({ email, firstName, lastName, displayName }) => {
          if (!isPasswordValid) {
            return;
          }

          onRegister(email, firstName, lastName, password, displayName);
        }}
      >
        {({ errors, touched, values }) => {
          const {
            email: emailError,
            firstName: firstNameError,
            hasReadAndAgreedToPrivacyStatementAndTermsAndConditions: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsError,
            lastName: lastNameError,
            displayName: displayNameError,
          } = errors;

          const {
            email: isEmailTouched,
            firstName: isFirstNameTouched,
            hasReadAndAgreedToPrivacyStatementAndTermsAndConditions: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsTouched,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            lastName: isLastNameTouched,
            displayName: isDisplayNameTouched,
          } = touched;

          const {
            email: emailValue,
            firstName: firstNameValue,
            hasReadAndAgreedToPrivacyStatementAndTermsAndConditions: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsValue,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            lastName: lastNameValue,
            displayName: displayNameValue,
          } = values;

          return (
            <Form className={getBEMClassName(formBlockCssId, formElementCssId)}>
              <FormField
                css={{
                  blockId: formBlockCssId,
                  elementId: 'displayName',
                }}
                field={{
                  id: 'displayName',
                  name: 'displayName',
                  labelComponent: (
                    <Label
                      additionalClassNames={getBEMClassName(formBlockCssId, `displayName-${labelElementCssId}`)}
                      forId={'displayName'}
                      labelText={'Display name'}
                    />
                  ),
                  value: displayNameValue,
                }}
                formik={{
                  errors: displayNameError,
                  touched: isDisplayNameTouched,
                }}
              />

              <FormFieldContainer
                css={{
                  blockId: formBlockCssId,
                  elementId: `${nameElementCssId}-${containerElementCssId}`,
                }}
              >
                <FormField
                  css={{
                    blockId: formBlockCssId,
                    elementId: firstNameId,
                  }}
                  field={{
                    id: firstNameId,
                    name: 'firstName',
                    labelComponent: (
                      <Label
                        additionalClassNames={getBEMClassName(formBlockCssId, `${firstNameId}-${labelElementCssId}`)}
                        forId={firstNameId}
                        labelText={firstNameLabel}
                      />
                    ),
                    value: firstNameValue,
                  }}
                  formik={{
                    errors: firstNameError,
                    touched: isFirstNameTouched,
                  }}
                />
                <FormField
                  css={{
                    blockId: formBlockCssId,
                    elementId: lastNameId,
                  }}
                  field={{
                    id: lastNameId,
                    name: 'lastName',
                    labelComponent: (
                      <Label
                        additionalClassNames={getBEMClassName(formBlockCssId, `${lastNameId}-${labelElementCssId}`)}
                        forId={lastNameId}
                        labelText={lastNameLabel}
                      />
                    ),
                    value: lastNameValue,
                  }}
                  formik={{
                    errors: lastNameError,
                    touched: isLastNameTouched,
                  }}
                />
              </FormFieldContainer>

              <small className="block mb-4">* Only your chosen display name will be visible to other users</small>

              <FormField
                css={{
                  blockId: formBlockCssId,
                  elementId: emailId,
                }}
                field={{
                  id: emailId,
                  name: 'email',
                  labelComponent: (
                    <Label
                      additionalClassNames={getBEMClassName(formBlockCssId, `${emailId}-${labelElementCssId}`)}
                      forId={emailId}
                      labelText={emailLabel}
                    />
                  ),
                  type: 'email',
                  value: emailValue,
                }}
                formik={{
                  errors: emailError,
                  touched: isEmailTouched,
                }}
              />

              <PasswordInputWithValidator
                onChange={({ firstPassword, isPasswordValid: isPasswordValidValue }) => {
                  setPassword(firstPassword);
                  setIsPasswordValid(isPasswordValidValue);
                }}
              />

              <FormField
                css={{
                  blockId: formBlockCssId,
                  elementId: `${hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsId}-${checkboxElementCssId}`,
                }}
                field={{
                  checked: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsValue,
                  id: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsId,
                  name: 'hasReadAndAgreedToPrivacyStatementAndTermsAndConditions',
                  labelComponent: (
                    <>
                      I have read and agreed to the{' '}
                      <a className={cx('color-accent', 'text-underline')} href="/privacy" target="_blank">
                        Privacy Statement
                      </a>{' '}
                      and{' '}
                      <a className={cx('color-accent', 'text-underline')} href="/terms" target="_blank">
                        Terms and Conditions
                      </a>
                    </>
                  ),
                  type: 'checkbox',
                }}
                formik={{
                  errors: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsError,
                  touched: hasReadAndAgreedToPrivacyStatementAndTermsAndConditionsTouched,
                }}
              />

              <FormFieldContainer
                css={{
                  blockId: formBlockCssId,
                  elementId: `${buttonsLinksElementCssId}-${containerElementCssId}`,
                }}
              >
                <FormFieldContainer
                  css={{
                    blockId: formBlockCssId,
                    elementId: `${leftContainerElementCssId}-${buttonsLinksElementCssId}`,
                  }}
                >
                  {loginUrl ? (
                    <>
                      <FormFieldContainer
                        css={{
                          blockId: formBlockCssId,
                          elementId: `already-have-an-account-text-${containerElementCssId}`,
                        }}
                      >
                        Already have an account?
                      </FormFieldContainer>
                      <FormFieldContainer
                        css={{
                          blockId: formBlockCssId,
                          elementId: `${loginElementCssId}-${buttonElementCssId}-${containerElementCssId}`,
                        }}
                      >
                        <Button
                          aria-label="Login"
                          className={cx(getBEMClassName(formBlockCssId, `${loginElementCssId}-${buttonElementCssId}`))}
                          theme="greenOutline"
                          onPress={handleLogin}
                        >
                          Login
                        </Button>
                      </FormFieldContainer>
                    </>
                  ) : null}
                </FormFieldContainer>
                <FormFieldContainer
                  css={{
                    blockId: formBlockCssId,
                    elementId: `${rightContainerElementCssId}-${buttonsLinksElementCssId}`,
                  }}
                >
                  {forgotPasswordUrl ? (
                    <FormFieldContainer
                      css={{
                        blockId: formBlockCssId,
                        elementId: `${forgotPasswordElementCssId}-${linkCssId}-${containerElementCssId}`,
                      }}
                    >
                      <a
                        className={getBEMClassName(formBlockCssId, `${forgotPasswordElementCssId}-${linkCssId}`)}
                        href={forgotPasswordUrl}
                      >
                        Forgot password
                      </a>
                    </FormFieldContainer>
                  ) : null}
                  <FormFieldContainer
                    css={{
                      blockId: formBlockCssId,
                      elementId: `${registerElementCssId}-${buttonElementCssId}-${containerElementCssId}`,
                    }}
                  >
                    <Button
                      className={cx(getBEMClassName(formBlockCssId, `${registerElementCssId}-${buttonElementCssId}`), buttonClassName)}
                      isDisabled={isSubmitting}
                      theme="green"
                      type="submit"
                    >
                      {buttonText}
                    </Button>
                  </FormFieldContainer>
                </FormFieldContainer>
              </FormFieldContainer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export { RegistrationForm };
