/**
 *
 * @returns Presentational markup for affiliate logos
 */

import { AFFILIATE_LIST } from './Affiliates.constants';

const Affiliates = () => {
  return (
    <section className="bg-gradient-blue py-24">
      <div className="container container-2xs">
        <div className="flex flex-wrap md:flex-row -mx-8">
          {AFFILIATE_LIST.map(({ alt, id, src }) => (
            <div key={id} className="items-center flex justify-center px-8 mb-8 w-1/3" title={alt}>
              <img alt={alt} loading="lazy" src={src} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { Affiliates };
